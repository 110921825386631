import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "../assets/imgs/side-bar-logo/logo@3x.png";
import LockImage from "../assets/imgs/lock-image.png";
import { InputLabel, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Button, Spin } from "antd";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const emailRef = useRef("");
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(null);

  const handleResetPassword = async () => {
    try {
      if (email) {
        setLoading(true);
        let response = await resetPassword(email);
        setLoading(false);
        Swal.fire({
          title: "Reset Email Link Sent!",
          text: "Your password reset link has been sent successfully to your registered email address.",
          timer: 2000,
          icon: "success",
        }).then(() => navigate("/login"));
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/user-not-found") {
        Swal.fire({
          title: "Error!",
          text: "No user found with your email address",
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: error?.message,
          icon: "error",
        });
      }
    }
  };
  return (
    <Spin spinning={loading}>
      <div className="forgot-password px-4 w-full sm:w-1/3 mx-auto">
        <div className="mt-[6rem]">
          <img
            src={LogoWhite}
            alt="Mariza Logo"
            height={60}
            width={200}
            className="d-inline-block"
          />
        </div>
        <img
          src={LockImage}
          alt="Lock Image"
          className="d-inline-block my-5"
          width={150}
          height={150}
        />
        <h1 className="ff-playfiar fw-bold">Reset your password</h1>
        <p className="mt-2 ff-roboto">
          Please enter your registered email address. We'll send you a password
          reset link.
        </p>
        <div class="w-full mt-3">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-email"
          >
            Email
          </label>
          <input
            class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-email"
            type="email"
            name="email"
            variant="outlined"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="text-center">
          <button
            className="new-product-button flex-1 mr-2 bg-black text-white p-3 my-5 w-full"
            onClick={handleResetPassword}
          >
            SEND RESET LINK
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default ForgotPassword;
