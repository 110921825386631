import { useEffect, useState } from "react";
import FilterIcon from "../assets/imgs/filter-icon.svg";
import CloseIcon from "../assets/imgs/close-icon.svg";
import DropdownArrowIcon from "../assets/imgs/dropdown-arrow-icon.svg";
import { Drawer, InputLabel, MenuItem, Select } from "@mui/material";
// import SearchProducts from "../components/SearchProducts";
import NewProductCard from "../components/NewProductCard";
// import ProductCard from "../components/ProductCard";
// import SpecialOffer from "../components/SpecialOffer";
import { getFilteredData } from "../utils";
import { Link, useLocation } from "react-router-dom";
import { Spin } from "antd";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";
import { useAuth } from "../contexts/AuthContext";
import { useBrandsStore, useCategoriesStore, useUserStore } from "../app/store";

const DiscountedProductsList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const { currentUser } = useAuth();
  const location = useLocation();
  const { categories } = useCategoriesStore();
  const { brands } = useBrandsStore();
  const { user } = useUserStore();
  const [discountedProducts, setDiscountedProducts] = useState([]);
  const [filterByOptions, setFilterByOptions] = useState({
    sortBy: "",
    sortOrder: "",
    filterByBrand: "",
    filterByCategory: "",
    filterBySize: "",
  });
  const [initialData, setInitialData] = useState([]);
  useEffect(() => {
    // window.scrollTo(0, 0);
    getDiscountedProducts();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("discounted-products")) {
      // window.scrollTo(0, 0);
    }
  }, []);

  const getDiscountedProducts = async () => {
    try {
      setLoading(true);
      let data = await getFilteredData("products", "is_discounted", "==", true);
      setLoading(false);
      if (data) {
        setDiscountedProducts(data);
        setInitialData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterProducts = async () => {
    try {
      const isValid = Object.keys(filterByOptions).every(
        (k) => filterByOptions[k]
      );
      if (isValid === false) {
        setDiscountedProducts(initialData);
      }
      let tempProducts = [];
      if (discountedProducts?.length === 0) {
        tempProducts = [...initialData];
      }
      tempProducts = [...initialData];
      if (tempProducts?.length === 0) {
        setDiscountedProducts(initialData);
      }
      setIsDrawerOpen(false);
      setLoading(true);
      if (filterByOptions?.sortBy) {
        if (filterByOptions?.sortBy === "name") {
          tempProducts = tempProducts?.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setLoading(false);
        } else if (filterByOptions?.sortBy === "price_low_to_high") {
          tempProducts.sort(
            (a, b) =>
              Number(a.unstitchedDiscountPrice) -
              Number(b.unstitchedDiscountPrice)
          );
          // tempProducts = tempProducts?.sort((a, b) =>
          //   a.price.localeCompare(b.unstitchedDiscountPrice),
          // )
        } else if (filterByOptions?.sortBy === "price_high_to_low") {
          tempProducts.sort(
            (a, b) =>
              Number(a.unstitchedDiscountPrice) -
              Number(b.unstitchedDiscountPrice)
          );
          tempProducts = tempProducts?.reverse();
          // tempProducts = tempProducts?.sort((a, b) =>
          //   a.price.localeCompare(b.price),
          // )
          // tempProducts = tempProducts?.reverse()
        }
      }
      if (filterByOptions?.filterByCategory) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.category === filterByOptions.filterByCategory
        );
        setLoading(false);
        setDiscountedProducts(data);
        tempProducts = [...data];
      }
      if (filterByOptions?.filterByBrand) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.brand === filterByOptions.filterByBrand
        );
        tempProducts = [...data];
        setLoading(false);
        setDiscountedProducts(data);
      }
      if (filterByOptions?.filterBySize) {
        let data = [];
        if (
          !filterByOptions?.filterByCategory &&
          !filterByOptions?.filterByBrand
        ) {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        } else {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        }
        // let data = tempProducts?.filter((item) =>
        //   item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase()),
        // )
        if (data) {
          tempProducts = [...data];
        }
      }
      setLoading(false);
      setDiscountedProducts([...tempProducts]);
      if (filterByOptions?.sortOrder === "descending") {
        setDiscountedProducts([...tempProducts.reverse()]);
      } else setDiscountedProducts([...tempProducts]);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const likeProduct = (item, index) => {
    const cProductItems = [...discountedProducts];
    cProductItems[index] = item;
    setDiscountedProducts([...cProductItems]);
  };

  return (
    <Spin spinning={loading}>
      <div className="products-list position-relative footer-container mt-[6rem]">
        <div className="mt-3 mb-2 px-3 d-flex justify-content-between align-items-center">
          <h1 className="total-products-label">
            Showing {discountedProducts?.length} products
          </h1>
          <div
            className="filter d-flex px-4 py-1 gap-1 cursor-pointer"
            onClick={() => setIsDrawerOpen(true)}
          >
            <img src={FilterIcon} alt="" width={14} height={12} />
            <span>Filter</span>
          </div>
        </div>
        {loading == false && discountedProducts?.length === 0 && (
          <div className="text-center mt-4">
            <img src={NoProductsFoundPlaceHolder} />
          </div>
        )}
        <div className="row m-0 g-3 px-2 mb-5">
          {discountedProducts &&
            discountedProducts?.map((product, index) => (
              <div className="col-6 col-lg-3 col-xxl-2" key={product?.id}>
                <Link to={`/product-details/${product?.id}`}>
                  {/* <NewProductCard isDiscounted={true} product={product}  /> */}
                  <NewProductCard
                    product={product}
                    uid={currentUser?.uid || ""}
                    likeProduct={likeProduct}
                    index={index}
                    categories={categories}
                    userDetail={user}
                    // fetchProducts={getAllProducts}
                    // fetchUserDetails={fetchUserDetails}
                  />
                </Link>
              </div>
            ))}
        </div>
        <div className="mt-3"></div>
        {/* <Footer /> */}
        <Drawer
          anchor={"bottom"}
          open={isDrawerOpen}
          className="mx-3 md:hidden"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="filter-drawer">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h1 className="filters-label">Filters</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                className="cursor-pointer"
                width={32}
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="row m-0">
              <div className="col-12 d-flex justify-content-between p-0 gap-4">
                <div className="w-100">
                  <InputLabel id="select-standard-label">Sort by</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "black",
                      fontSize: "16px",
                    }}
                    inputProps={{ style: { border: "1px solid red" } }}
                    labelId="select-standard-label"
                    id="select-standard"
                    variant="standard"
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        sortBy: e.target.value,
                      }))
                    }
                    value={filterByOptions?.sortBy}
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"price_low_to_high"}>
                      Price Low to High
                    </MenuItem>
                    <MenuItem value={"price_high_to_low"}>
                      Price High to Low
                    </MenuItem>
                  </Select>
                </div>
                {filterByOptions?.sortBy !== "price_low_to_high" &&
                  filterByOptions?.sortBy !== "price_high_to_low" && (
                    <div className="w-100">
                      <InputLabel id="select-standard-label">
                        Sort Order
                      </InputLabel>
                      <Select
                        labelId="select-standard-label"
                        id="select-standard"
                        sx={{ width: "100%" }}
                        variant="standard"
                        IconComponent={() => (
                          <img src={DropdownArrowIcon} alt="" />
                        )}
                        onChange={(e) =>
                          setFilterByOptions((prev) => ({
                            ...prev,
                            sortOrder: e.target.value,
                          }))
                        }
                        value={filterByOptions?.sortOrder}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"ascending"}>Ascending</MenuItem>
                        <MenuItem value={"descending"}>Descending</MenuItem>
                      </Select>
                    </div>
                  )}
              </div>
              <div className="col-12 p-0 mt-4">
                <InputLabel id="select-standard-label">
                  Filter by Brand
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByBrand: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByBrand}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {brands?.map((brand) => (
                    <MenuItem
                      value={brand?.id}
                      key={brand?.id}
                      className="text-capitalize"
                    >
                      {brand?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-12 p-0 mt-4">
                <InputLabel id="select-standard-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByCategory: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories?.map((category) => (
                    <MenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* <div className="col-12 p-0 mt-4">
                <InputLabel id="select-standard-label">
                  Filter by Size
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterBySize: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterBySize}
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"xs"}>XS</MenuItem>
                  <MenuItem value={"s"}>S</MenuItem>
                  <MenuItem value={"m"}>M</MenuItem>
                  <MenuItem value={"l"}>L</MenuItem>
                  <MenuItem value={"xl"}>XL</MenuItem>
                  <MenuItem value={"xxl"}>XXL</MenuItem>
                  <MenuItem value={"xxxl"}>XXXL</MenuItem>
                </Select>
              </div> */}
            </div>
            <div className="d-flex justify-center sm:justify-between align-items-center mt-5 mb-4 flex-wrap gap-2">
              <button
                className="bg-gray-filled-btn"
                style={{ width: "160px", height: "40px", fontSize: "15px" }}
                onClick={() => {
                  setIsDrawerOpen(false);
                  setFilterByOptions({});
                  getDiscountedProducts();
                }}
              >
                CLEAR ALL
              </button>
              <button
                className="bg-primary-filled-btn"
                style={{ height: "40px", fontSize: "15px" }}
                onClick={filterProducts}
              >
                APPLY FILTERS
              </button>
            </div>
          </div>
        </Drawer>
        <Drawer
          anchor={"left"}
          open={isDrawerOpen}
          className="mx-3 hidden md:block"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="filter-drawer">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h1 className="filters-label">Filters</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                className="cursor-pointer"
                width={32}
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="row m-0">
              <div className="col-12 d-flex justify-content-between p-0 gap-4">
                <div className="w-100">
                  <InputLabel id="select-standard-label">Sort by</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "black",
                      fontSize: "16px",
                    }}
                    inputProps={{ style: { border: "1px solid red" } }}
                    labelId="select-standard-label"
                    id="select-standard"
                    variant="standard"
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        sortBy: e.target.value,
                      }))
                    }
                    value={filterByOptions?.sortBy}
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"price_low_to_high"}>
                      Price Low to High
                    </MenuItem>
                    <MenuItem value={"price_high_to_low"}>
                      Price High to Low
                    </MenuItem>
                  </Select>
                </div>
                {filterByOptions?.sortBy !== "price_low_to_high" &&
                  filterByOptions?.sortBy !== "price_high_to_low" && (
                    <div className="w-100">
                      <InputLabel id="select-standard-label">
                        Sort Order
                      </InputLabel>
                      <Select
                        labelId="select-standard-label"
                        id="select-standard"
                        sx={{ width: "100%" }}
                        variant="standard"
                        IconComponent={() => (
                          <img src={DropdownArrowIcon} alt="" />
                        )}
                        onChange={(e) =>
                          setFilterByOptions((prev) => ({
                            ...prev,
                            sortOrder: e.target.value,
                          }))
                        }
                        value={filterByOptions?.sortOrder}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"ascending"}>Ascending</MenuItem>
                        <MenuItem value={"descending"}>Descending</MenuItem>
                      </Select>
                    </div>
                  )}
              </div>
              <div className="col-12 p-0 mt-4">
                <InputLabel id="select-standard-label">
                  Filter by Brand
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByBrand: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByBrand}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {brands?.map((brand) => (
                    <MenuItem
                      value={brand?.id}
                      key={brand?.id}
                      className="text-capitalize"
                    >
                      {brand?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-12 p-0 mt-4">
                <InputLabel id="select-standard-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByCategory: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories?.map((category) => (
                    <MenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* <div className="col-12 p-0 mt-4">
                <InputLabel id="select-standard-label">
                  Filter by Size
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterBySize: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterBySize}
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"xs"}>XS</MenuItem>
                  <MenuItem value={"s"}>S</MenuItem>
                  <MenuItem value={"m"}>M</MenuItem>
                  <MenuItem value={"l"}>L</MenuItem>
                  <MenuItem value={"xl"}>XL</MenuItem>
                  <MenuItem value={"xxl"}>XXL</MenuItem>
                  <MenuItem value={"xxxl"}>XXXL</MenuItem>
                </Select>
              </div> */}
            </div>
            <div className="d-flex justify-center sm:justify-between align-items-center mt-5 mb-4 flex-wrap gap-2">
              <button
                className="bg-gray-filled-btn"
                style={{ width: "160px", height: "40px", fontSize: "15px" }}
                onClick={() => {
                  setIsDrawerOpen(false);
                  setFilterByOptions({});
                  getDiscountedProducts();
                }}
              >
                CLEAR ALL
              </button>
              <button
                className="bg-primary-filled-btn"
                style={{ height: "40px", fontSize: "15px" }}
                onClick={filterProducts}
              >
                APPLY FILTERS
              </button>
            </div>
          </div>
        </Drawer>
      </div>
    </Spin>
  );
};

export default DiscountedProductsList;
