import React from 'react';

const AboutUs = () => {
  return (
    <div className='mt-[8rem] ml-[20px] mr-[20px] container mb-[48px]'>
      <p className='cart-header'>About Us</p>
      <div className='mt-[48px] text-start'>
        <p className='article-text'>
          Being a mother of 3 daughters, it was always a struggle to source good
          quality, designer Pakistani outfits for myself and my girls. Be it for
          Eid, formal events, family weddings or just everyday day casual wear.
          Due to the limited options available in the UK market, sourcing
          outfits felt like a constant struggle which I did not want to
          experience year on year, and that's when I created my brand- Mariza
          Official.
        </p>
        <p className='mt-3 article-text'>
          Our mission is to bring luxury Pakistani designer clothing right to
          your doorstep. Working with only the best karigars (artisan) in
          Pakistan, we deliver high quality, beautifully stitched pieces.
        </p>

        <p className='mt-3 article-text'>
          As a brand we strive to provide the best customer service because at
          Mariza Official, we care! From providing quick and efficient support,
          to ensuring your completely satisfied with your order; customer
          satisfaction lies at the forefront of our brand and is something we
          pride ourselves in.{' '}
        </p>
        <p className='mt-3 article-text'>
          Through stocking the most recent collections, this is the place to
          shop for your luxury Asian designer wear! So, whether the occasion is
          a wedding, or an informal gathering; put your trust in Mariza Official
          to ensure you dress to impress!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
