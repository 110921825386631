import { useEffect, useState } from "react";
import { getAllDataFromCollection } from "../../utils";
import { Carousel, Image, Spin } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";

import BannerImage from "../../assets/imgs/bannerImage.jpeg";
import { carouselData } from "../../constants/carousel-data";
import { Mousewheel, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import "./styles.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

SwiperCore.use([Mousewheel, Pagination]);

const HomeCarousel = () => {
  const [bannerImages, setBannerImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(null);
  const { width } = useWindowDimensions();
  useEffect(() => {
    console.log("width :: ", width);
    getBannerImages();
  }, []);

  const handleImageClick = (event) => {
    const index = parseInt(event.target.dataset.index);
    setCurrentIndex(index);
  };

  const getBannerImages = async () => {
    try {
      setLoading(true);
      let data = await getAllDataFromCollection("banners");
      setLoading(false);
      if (data?.length) {
        let tempImages = [];
        data?.forEach((item) => {
          tempImages.push({
            original: item.image,
            thumbnail: item?.image,
            description: item?.name,
          });
          setImages(tempImages);
          item["original"] = item?.image;
          item["thumbnail"] = item?.image;
          item["description"] = item?.name;
        });
        setBannerImages(data);
        console.log("data is foundd ");
      } else {
        console.log("data is nottt foundd ");
        data = carouselData;
        let tempImages = [];
        data?.forEach((item) => {
          tempImages.push({
            original: item.image,
            thumbnail: item?.image,
            description: item?.name,
          });
          setImages(tempImages);
          item["original"] = item?.image;
          item["thumbnail"] = item?.image;
          item["description"] = item?.name;
        });
        setBannerImages(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ marginTop: "4rem" }}>
        <Swiper
          pagination={true}
          modules={[Pagination, Mousewheel]}
          className="pb-3 mySwiper"
          slidesPerView={"auto"}
          loop={true}
          spaceBetween={width < 700 ? 0 : 20}
          direction="horizontal"
          mousewheel={{ forceToAxis: true }}
        >
          {bannerImages &&
            bannerImages?.map((item) => (
              <SwiperSlide key={item?.id} className="w-auto ml-2 ">
                <img
                  src={item.image}
                  style={{ objectFit: "contain", borderRadius: "24px" }}
                  preview={false}
                  className="rounded-xl h-[300px] sm:h-[400px] md:h-[500px]"
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </Spin>
  );
};

export default HomeCarousel;
