import React from 'react';

const RefundPolicy = () => {
  return (
    <div className='mt-[8rem] ml-[20px] mr-[20px] container mb-[48px]'>
      <p className='cart-header'>Refund & Exchange Policy</p>
      <div className='mt-[48px] text-start'>
        <p className='article-text'>
          We ensure all our products are stitched to the highest quality, and
          are thoroughly checked before they are dispatched. We do not offer
          exchanges or refunds. For Pre-Orders and Customised outfits, please
          check your sizing/measurements and product information before
          purchasing to avoid errors.
        </p>
        <ul className='list-disc'>
          <li className=' list-ci'>
            <p className='mt-3 article-text'>
              If you find a product to be faulty prior to wearing, then please
              contact us within 24 hours of receiving the order and do not wear
              the outfit. Loose accessories (beads, threads, pearls, buttons) do
              not qualify as faulty items.
            </p>
          </li>
          <li className=' list-ci'>
            <p className='mt-3 article-text'>
              During our stitching and cutting process, coloured chalk is used
              sometimes. This is not a defect/fault and is easily removed whilst
              washing.{' '}
            </p>
          </li>
        </ul>

        <p className='mt-3 article-text'>
          Due to the delicate nature of the outfits we recommend dry cleaning,
          or hand washing. We will not take any responsibility for outfits which
          have not been handled with care.
        </p>
        <p className='mt-3 article-text'>
          If you have any queries or require any assistance please feel free to
          contact us on any of our platforms.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
