import {
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DropdownArrowIcon from '../assets/imgs/dropdown-arrow-icon.svg'
import VisaCardIcon from '../assets/imgs/visa-card-icon.png'
import MasterCardIcon from '../assets/imgs/master-card-icon.png'
import InputMask from 'react-input-mask'
import { Country } from 'country-state-city'
import { Spin } from 'antd'
import { getFilteredData, updateDoc } from '../utils'
import { useAuth } from '../contexts/AuthContext'
import Swal from 'sweetalert2'
// import e from 'express'

const CardPayment = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [cardDetails, setCardDetails] = useState({
    cardDetailsEmail: '',
    cardNumber: '',
    expiry: '',
    cvc: '',
    country: '',
    zip_code: '',
    countryCode: '',
  })
  useEffect(() => {
    fetchUserDetails()
  }, [])

  const fetchUserDetails = async () => {
    try {
      setLoading(true)
      let data = await getFilteredData(
        'users',
        'email',
        '==',
        currentUser?.email,
      )
      setLoading(false)
      let user = data[0]
      setCardDetails((prev) => ({
        ...prev,
        cardNumber: user?.cardNumber,
        cardDetailsEmail: user?.cardDetailsEmail,
        country: user?.country,
        countryCode: user?.countryCode,
        cvc: user?.cvc,
        expiry: user?.expiry,
        zip_code: user?.zip_code,
      }))
    } catch (error) {
      setLoading(false)
    }
  }
  const normalizeCardNumber = (value = '') => {
    if (isNaN(value?.replace(/\s/g, ''))) {
      const val = value?.slice(0, value?.length - 1)
      return val
    } else {
      const formattedValue =
        value
          ?.replace(/\s/g, '')
          .match(/.{1,4}/g)
          ?.join(' ')
          ?.substring(0, 19) || ''
      return formattedValue
    }
  }
  const normalizeCVCCardNumber = (value = '') => {
    if (isNaN(value?.replace(/\s/g, ''))) {
      const val = value?.slice(0, value?.length - 1)
      return val
    } else {
      const formattedValue = value?.replace(/\s/g, '')?.substring(0, 3) || ''
      return formattedValue
    }
  }

  const normalizeZipCode = (value = '') => {
    if (isNaN(value?.replace(/\s/g, ''))) {
      const val = value?.slice(0, value?.length - 1)
      return val
    } else {
      const formattedValue = value?.replace(/\s/g, '')?.substring(0, 5) || ''
      return formattedValue
    }
  }

  const normalizeCardExpiryDate = (value = '') => {
    if (isNaN(value?.replace('/', ''))) {
      const val = value?.slice(0, value?.length - 1)
      return val
    } else {
      if (value?.length >= 5) return value?.slice(0, 5)
      const formattedValue =
        value
          ?.replace(/\s/g, '')
          ?.match(/.{1,2}/g)
          ?.join('/')
          ?.substring(0, 5) || ''
      if (formattedValue) return formattedValue
      else return ''
    }
  }
  const [expiry, setExpiry] = useState('')

  const handlChange = (e) => {
    setCardDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const isValidExpiry = isValidExpiryDate(cardDetails?.expiry)
      if (isValidExpiry === false) {
        Swal.fire({
          title: 'Error!',
          text:
            'Expiry date is not valid. Please enter a valid date in MM/YY format!',
          icon: 'error',
          timer: 2000,
        })
        return
      }
      setLoading(true)
      if (currentUser?.uid) {
        let user = await getFilteredData(
          'users',
          'email',
          '==',
          currentUser?.email,
        )
        await updateDoc('users', user[0]?.id, cardDetails)
      } else {
        localStorage.setItem('card-details', JSON.stringify(cardDetails))
      }
      Swal.fire({
        title: 'Success',
        text: 'Card Details have been updated.',
        icon: 'success',
        // timer: 2000,
      }).then(() => {
        if (id) {
          navigate('/profile-settings')
        } else {
          navigate('/shipping-address')
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const isValidExpiryDate = (dateString) => {
    // check if the input string is of the form "MM/YY"
    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(dateString)) {
      return false
    }

    // parse the month and year from the input string
    var parts = dateString.split('/')
    var month = parseInt(parts[0], 10)
    var year = parseInt(parts[1], 10)

    // check if the month and year are valid
    var now = new Date()
    if (year < now.getFullYear() - 2000 || year > now.getFullYear() + 20) {
      return false
    }
    if (month < 1 || month > 12) {
      return false
    }

    // the expiry date is valid
    return true
  }

  return (
    <Spin spinning={loading}>
      <div className="card-payment px-4 h-100">
        <div className="position-fixed cart-header w-100 py-4 d-flex gap-4 align-items-center bg-white top-0">
          <i
            className="fa fa-chevron-left cursor-pointer float-start"
            style={{ fontSize: '1.2rem' }}
            onClick={() => navigate(-1)}
          ></i>
          <h1>{id ? 'Edit Card Payment' : 'Card Payment'}</h1>
        </div>
        <div className="" style={{ marginTop: '5rem' }}>
          <form onSubmit={handleSubmit}>
            <div className="text-left mt-3">
              <InputLabel id="label">Email</InputLabel>
              <TextField
                fullWidth
                name="cardDetailsEmail"
                type={'email'}
                variant="standard"
                placeholder="janedoe@email.com"
                required
                value={cardDetails?.cardDetailsEmail}
                onChange={handlChange}
              />
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Card Number</InputLabel>
              <TextField
                fullWidth
                name="cardNumber"
                type={'text'}
                variant="standard"
                required
                value={cardDetails?.cardNumber}
                placeholder="1234 1234 1234 1234"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        className="d-flex align-items-center gap-1"
                        style={{ marginRight: '1.5rem' }}
                      >
                        <img
                          src={VisaCardIcon}
                          alt="Visa Card Icon"
                          width={45}
                        />
                        <img
                          src={MasterCardIcon}
                          alt="Master Card Icon"
                          width={27}
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const { value } = e.target
                  e.target.value = normalizeCardNumber(value)
                  setCardDetails((prev) => ({
                    ...prev,
                    cardNumber: e.target.value,
                  }))
                }}
              ></TextField>
            </div>
            <div className="d-flex justify-content-between align-items-center gap-3 mt-3">
              <div className="text-left">
                <InputLabel id="label">Expiry</InputLabel>
                {/* <p>{expiry}</p> */}
                <TextField
                  fullWidth
                  name="expiry"
                  type={'text'}
                  variant="standard"
                  placeholder="e.g. 11/26"
                  required
                  value={cardDetails?.expiry}
                  onChange={(e) => {
                    // const { value } = e.target
                    // e.target.value = normalizeCardExpiryDate(value)
                    setCardDetails((prev) => ({
                      ...prev,
                      expiry: e.target.value,
                    }))
                  }}
                />
              </div>
              <div className="text-left">
                <InputLabel id="label">CVC</InputLabel>
                <TextField
                  fullWidth
                  name="cvc"
                  type={'text'}
                  variant="standard"
                  placeholder="156"
                  required
                  value={cardDetails?.cvc}
                  onChange={(e) => {
                    const { value } = e.target
                    e.target.value = normalizeCVCCardNumber(value)
                    setCardDetails((prev) => ({
                      ...prev,
                      cvc: e.target.value,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="w-100 mt-3 text-left">
              <InputLabel id="select-standard-label">
                Country / Region
              </InputLabel>
              <Select
                sx={{
                  width: '100%',
                  color: 'black',
                  fontSize: '16px',
                }}
                inputProps={{ style: { border: '1px solid red' } }}
                labelId="select-standard-label"
                id="select-standard"
                required
                variant="standard"
                value={cardDetails?.countryCode}
                IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                onChange={(e) => {
                  const country = Country.getAllCountries().find(
                    (con) => con.isoCode === e.target.value,
                  )
                  setCardDetails((prev) => ({
                    ...prev,
                    country: country.name,
                    countryCode: e.target.value,
                  }))
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Country.getAllCountries().map((country) => (
                  <MenuItem value={country?.isoCode} key={country.isoCode}>
                    <p
                      className="d-flex gap-1
                  "
                    >
                      {country.flag}
                      <span>{country.name}</span>
                    </p>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Post Code</InputLabel>
              <TextField
                fullWidth
                name="zip_code"
                type={'text'}
                variant="standard"
                required
                placeholder="12345"
                value={cardDetails?.zip_code}
                onChange={(e) => {
                  const { value } = e.target
                  e.target.value = normalizeZipCode(value)
                  setCardDetails((prev) => ({
                    ...prev,
                    zip_code: e.target.value,
                  }))
                }}
              />
            </div>
            {id && (
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Link to={'/profile-settings'}>
                  <button
                    className="bg-gray-filled-btn"
                    style={{ width: '120px', border: '1px solid #005288' }}
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  className="bg-primary-filled-btn"
                  style={{ width: '180px' }}
                  htmltype="submit"
                >
                  Update
                </button>
              </div>
            )}
            {!id && (
              <div className="checkout-footer bg-white py-4">
                {/* <Link
                  to={'/shipping-address'}
                  className="text-black text-decoration-none"
                > */}
                <button
                  className="bg-primary-filled-btn"
                  style={{ width: '335px', height: '40px' }}
                  htmltype="submit"
                >
                  Next (2/3)
                </button>
                {/* </Link> */}
              </div>
            )}
          </form>
        </div>
      </div>
    </Spin>
  )
}

export default CardPayment
