// import { db, firebase } from './firebase.config'
import firebase from "firebase";

export async function userSignUp(email, password, firstname, lastname) {
  let userData = null;
  await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      let newUser = user?.user;
      firebase.auth().onAuthStateChanged((newUser) => {
        if (user) {
          //   setCurrentUser(user.uid)
          firebase.database().ref("user").child(user).set({
            user: user,
            firstname: firstname,
            lastname: lastname,
          });
        }
      });
    })
    .catch((error) => {
      alert(error.message);
    });
  return userData;
}

export async function saveData(collection, doc, jsonObject) {
  await firebase
    .firestore()
    .collection(collection)
    .doc(doc)
    .set(jsonObject, { merge: true })
    .catch(function (error) {
      console.error("Error writing document: ", error);
      return false;
    })
    .then(() => {
      return true;
    });
}

export const updateDoc = async (collection, doc, data) => {
  try {
    let updated = false;
    await firebase.firestore().collection(collection).doc(doc).update(data);
    // .catch(function (error) {
    //   console.error("Error writing document: ", error)
    //   updated = false
    // })
    // .then(() => {
    //   updated = true
    // })
    return true;
  } catch (error) {
    console.error("Error writing document: ", error);
    return false;
  }
};

export async function signInWithEmail(email, password) {
  let success = false;
  await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      success = true;
    })
    .catch((error) => {
      success = false;
    });
  return success;
}

export async function getAllDataFromCollection(collection = "") {
  try {
    let response = await firebase.firestore().collection(collection).get();
    let data = response.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getFilteredData(collection = "", ...rest) {
  try {
    let data;
    if (rest?.length === 3) {
      let response = await firebase
        .firestore()
        .collection(collection)
        .where(rest[0], rest[1], rest[2])
        .get();
      data = response.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } else if (rest?.length === 6) {
      let response = await firebase
        .firestore()
        .collection(collection)
        .where(rest[0], rest[1], rest[2])
        .where(rest[3], rest[4], rest[5])
        .get();
      data = response.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    }
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getDataByOrder(collection = "", orderBy = "") {
  try {
    let response = await firebase
      .firestore()
      .collection(collection)
      .orderBy(orderBy)
      .get();
    let data = response.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getFilteredSortByData(
  collection = "",
  orderBy = "",
  ...rest
) {
  try {
    let response = await firebase
      .firestore()
      .collection(collection)
      .where(rest[0], rest[1], rest[2])
      .orderBy(orderBy, "desc")
      .get();
    let data = response.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchSingleDocument(collection = "", id = "") {
  try {
    let response = await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get();
    let data = response.data();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function filterSingleDocument(
  collection = "",
  field = "",
  op = "",
  value = ""
) {
  try {
    let response = await firebase
      .firestore()
      .collection(collection)
      .where(field, op, value)
      .get();
    let data = response.data();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function addDoc(collection, data) {
  try {
    let result = await firebase.firestore().collection(collection).add(data);
    if (result.id) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getCurrentUser() {
  var user = firebase.auth().currentUser;
  if (user != null) {
    return user;
  }
}
