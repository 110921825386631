import React, { useEffect, useState } from "react";
import SearchProducts from "../components/SearchProducts";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import { getAllDataFromCollection } from "../utils";
import { Spin } from "antd";
import CategoryProductCard from "../components/CategoryProductCard";
import { useCategoriesStore } from "../app/store";

const CategoriesList = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const { categories } = useCategoriesStore();

  useEffect(() => {
    getCategoriesList();
  }, []);

  const getCategoriesList = async () => {
    try {
      setLoading(true);
      // let data = await getAllDataFromCollection("categories");
      let data = [...categories];
      setLoading(false);
      if (data) {
        console.log({ data }, "categories list");
        setCategoriesList(data);
        setInitialData(data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const searchProducts = async (searchText = "") => {
    if (searchText) {
      const filteredProducts = initialData?.filter((brand) =>
        brand?.name?.toLowerCase()?.includes(searchText?.toLowerCase()?.trim())
      );
      setCategoriesList(filteredProducts);
    } else {
      setCategoriesList([...initialData]);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="brands-list position-relative footer-container">
        <SearchProducts
          search={search}
          setSearch={setSearch}
          searchProducts={searchProducts}
        />
        <div className="row m-0 px-2 g-3 mb-5">
          {categoriesList &&
            categoriesList?.map((category) => (
              <div className="col-12 col-md-6 col-lg-3" key={category?.id}>
                <Link
                  to={`/products/${category?.id}`}
                  className="text-decoration-none"
                >
                  <CategoryProductCard category={category} />
                </Link>
              </div>
            ))}
        </div>
        <div className="mt-3"></div>
        <Footer />
      </div>
    </Spin>
  );
};

export default CategoriesList;
