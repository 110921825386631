import BannerImage from '../assets/imgs/bannerImage.jpeg'

const carouselData = [
    {
        image: BannerImage,
        name: 'Banner Image'
    },
    {
        image: BannerImage,
        name: 'Banner Image'
    },
    {
        image: BannerImage,
        name: 'Banner Image'
    },
    {
        image: BannerImage,
        name: 'Banner Image'
    },
]

export {
    carouselData
}