import React from 'react';

const DeliveryPolicy = () => {
  return (
    <div className='px-4 mt-[8rem] ml-[20px] mr-[20px] container mb-[48px]'>
      <p className='cart-header'>Delivery Policy</p>
      <div className='mt-[48px] text-start'>
        <p className='article-text'>
          Your articles will be delivered to you by Royal Mail, Hermes/Evri or
          Parcel Force. Please note, expected delivery time does not include
          processing time, i.e, the time it takes for your outfit(s) to arrive
          from Pakistan. This normally takes 3-4 weeks. In the event of any
          unexpected delays we will keep you updated. Please note tracking
          details will be shared as soon as your order has been dispatched.{' '}
        </p>
        <p className='mt-3 article-text'>
          If your order requires urgent delivery then please get in touch
          through any of our platforms. There are various express delivery
          options available to ensure your parcel can get to you within your
          required time frame, we will be happy to explore these options with
          you.
        </p>

        <h1 className='mt-4 article-heading'>Shipping costs:</h1>
        <p className='mt-2 article-text'>
          This will be calculated at check out and will be based on the total
          weight of your order.{' '}
        </p>
        <p className='mt-2 article-heading'>UK delivery:</p>
        <p className='mt-2 article-text'>
          Standard delivery up to 2kg (3-5 working days) = £5
        </p>
        <p className='mt-2 article-text'>
          Guaranteed next day delivery = £12.50{' '}
        </p>
        {/* <p className='mt-2 article-text'></p>
        <p className='mt-2 article-text'></p>
        <p className='mt-2 article-text'></p> */}
        <p className='mt-2 article-heading'>International delivery:</p>
        <p className='mt-2 article-text'>
          <span className='font-bold'> USA tracked:</span> <br />
          Up to 2kg = £28.50
        </p>
        <p className='mt-2 article-text'>
          <span className='font-bold'>Canada tracked: </span> <br />
          Up to 2kg = £27.50
        </p>
        <p className='mt-2 article-text'>
          <span className='font-bold'> Australia tracked: </span> <br />
          Up to 2kg = £32.50
        </p>
        <p className='mt-2 article-text'>
          <span className='font-bold'> Europe tracked: </span> <br />
          Up to 2kg = £16.50
        </p>
        <h1 className='mt-4 article-heading'>Shipping times:</h1>
        <p className='mt-2 article-text'>
          Please note that although we work with the highly reliable couriers,
          at times (which are out of our control) there is a possibility of
          delays. This could be due to adverse weather conditions, bank holidays
          & festive seasons. On occasions like these, please allow extra time
          for your parcel to arrive.{' '}
        </p>
        <p className=' article-text'> </p>
        <p className='mt-2 font-bold article-text'>
          <span className='font-bold'> The festive periods include:</span>
        </p>
        <p className='mt-1 font-bold article-text'>Eid</p>
        <p className='font-bold article-text'>Bank holidays</p>
        <p className='font-bold article-text'>Christmas</p>
        <p className='font-bold article-text'>New Year </p>
        <p className='mt-3 article-text'>
          Although we try our best, unfortunately we do not have any control
          over these conditions and cannot be held responsible for any delays
          caused by the shipping companies.
        </p>
        <p className='mt-3 article-text'>
          We do not accept responsibility for items that are lost or damaged in
          the post, parcels which have been delivered to an incorrect address or
          any unexpected delays from the courier service.
        </p>
      </div>
    </div>
  );
};

export default DeliveryPolicy;
