const sizeGrid = [
    {
        heading: 'Sizes',
        values: ['XS', 'S', 'M', 'L', 'XL']
    },
    {
        heading: 'Shoulder',
        values: ['13.5', '14', '14.5', '15', '15.5']
    },
    {
        heading: 'Chest',
        values: ['17', '18', '20', '22', '24']
    },
    {
        heading: 'Waist',
        values: ['15', '15.5', '17', '19.5', '22']
    },
    {
        heading: 'Hip',
        values: ['18.5', '19.5', '21', '23.5', '25.5']
    },
    {
        heading: 'Armhole',
        values: ['8', '8.5', '9.5', '10.5', '11.5']
    },
    {
        heading: 'Sleeves Length',
        values: ['21', '21', '22', '22', '23']
    },
    {
        heading: 'Trouser Length',
        values: ['37', '38', '39', '40', '41']
    },
    {
        heading: 'Shirt Length',
        values: ['40-40', '40-40', '40-40', '40-40', '40-40']
    }
];

export {
    sizeGrid
}

