import React, { useRef } from 'react'
import BackArrow from '../assets/imgs/arrow-back-icon.png'
import SearchIcon from '../assets/imgs/search-icon.svg'
import { useNavigate } from 'react-router-dom'
import { Input, useAutocomplete } from "@mui/material";
import { styled } from "@mui/system";

const Listbox = styled("ul")(({ theme }) => ({
  width: 200,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  maxHeight: 200,
  border: "1px solid rgba(0,0,0,.25)",
  "& li.Mui-focused": {
    backgroundColor: "#4a8df6",
    color: "white",
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: "#2977f5",
    color: "white",
  },
}));

const SearchProducts = ({ search, setSearch, searchProducts, products }) => {
  const navigate = useNavigate();
  const searchInputValue = useRef("");
  const handleSearchProducts = () => {
    searchProducts(searchInputValue?.current?.value);
  };

  return (
    <div className="search-products-search pb-3 pt-4 px-4 d-flex justify-content-between align-items-center bg-white">
      <div className="d-inline-block">
        <i
          className="fa fa-chevron-left cursor-pointer"
          onClick={() => navigate(-1)}
          style={{ fontSize: "1.2rem" }}
        ></i>
      </div>
      <input
        type="text"
        placeholder="Search..."
        className="search-input w-75"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearchProducts()
          }
        }}
        ref={searchInputValue}
        // onChange={(e) => setSearch(e.target.value)}
      />
      <div className="d-inline-block">
        <img
          src={SearchIcon}
          className="cursor-pointer"
          alt=""
          height={22}
          width={22}
          onClick={handleSearchProducts}
        />
      </div>
    </div>
  )
};

export default SearchProducts
