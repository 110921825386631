import HomeIcon from "../assets/imgs/home-icon.jpeg";
import WishlistIcon from "../assets/imgs/favorite-icon.jpeg";
import CartIcon from "../assets/imgs/cart-icon.jpeg";
import ProfileIcon from "../assets/imgs/profile-icon.jpeg";


const navOptions = [
  {
    title: "Home",
    icon: HomeIcon,
    link: "/",
  },
  {
    title: "Wishlist",
    icon: WishlistIcon,
    link: "/wish-list",
  },
  {
    title: "Cart",
    icon: CartIcon,
    link: "/cart",
  },
  {
    title: "Profile",
    icon: ProfileIcon,
    link: "/profile-settings",
  },
];

export { navOptions };
