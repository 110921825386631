import moment from "moment";
import React from "react";
import ChevronRightIcon from "../assets/imgs/chevron-right-icon.svg";

const OrderCard = ({ order, orderNo }) => {
  console.log("order :: ", order);
  return (
    <div className="p-4 order-card d-flex justify-content-between align-items-center">
      <div className="text-left">
        <p className="cart-title">Order#{orderNo}</p>
        <p className="mt-3 new-product-price">
          {order?.products?.length > 1 ? "Products" : "Product"}:{" "}
          <span className="ml-2">{order?.products?.length} </span>
        </p>
        <p className="mt-1 new-product-price">
          Date:
          <span className="ml-2">
            {moment(order?.orderDate).format("MMMM DD, YYYY hh:mm a")}
          </span>
        </p>
        <p className="mt-1 new-product-price">
          Delivery Address:
          <span className="ml-2">
            {order?.shipping_details?.address_line_1},{" "}
            {order?.shipping_details?.city}, {order?.shipping_details?.state}
          </span>
        </p>
        <p className="mt-1 new-product-price">
          Status:
          <span className="ml-2 capitalize">{order?.orderStatus || "-"}</span>
        </p>
        <p className="flex justify-between mt-4 radio-text">
          Total Amount:
          <span className="">₤{order?.session?.amount_total / 100 || "-"}</span>
        </p>
      </div>
    </div>
  );
};

export default OrderCard;
