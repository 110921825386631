import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrderCard from "../components/OrderCard";
import { useAuth } from "../contexts/AuthContext";
import { getAllDataFromCollection, getFilteredSortByData } from "../utils";
import { Spin } from "antd";

const PlacedOrder = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [previousOrders, setPreviousOrders] = useState([]);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    fetchUserOrders();
  }, []);

  const fetchUserOrders = async () => {
    try {
      setLoading(true);
      let data = await getFilteredSortByData(
        "orders",
        "orderDate",
        "email",
        "==",
        currentUser?.email
      );
      console.log("previous orders :: ", { previousOrders });
      data = data.filter((order) => order.orderStatus !== "completed");
      let filteredOrders = [];
      data.forEach((order, index, arr) => {
        if (
          !filteredOrders.find((item) => item.session.id == order.session.id)
        ) {
          filteredOrders.push(order);
        }
      });
      setPreviousOrders(filteredOrders);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="order-history lg:ml-[120px] lg:mr-[120px] mt-[8rem] ml-[20px] mr-[20px] mb-[48px]">
        <p className="flex items-center cart-header text-start">
          <div className="hidden max-sm:inline">
            <div
              className="mr-4 cursor-pointer back-button-container"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_3215_4408)">
                  <path
                    d="M15.8332 9.16672H6.5249L10.5916 5.10006C10.9166 4.77506 10.9166 4.24172 10.5916 3.91672C10.2666 3.59172 9.74157 3.59172 9.41657 3.91672L3.9249 9.40839C3.5999 9.73339 3.5999 10.2584 3.9249 10.5834L9.41657 16.0751C9.74157 16.4001 10.2666 16.4001 10.5916 16.0751C10.9166 15.7501 10.9166 15.2251 10.5916 14.9001L6.5249 10.8334H15.8332C16.2916 10.8334 16.6666 10.4584 16.6666 10.0001C16.6666 9.54172 16.2916 9.16672 15.8332 9.16672Z"
                    fill="#333333"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3215_4408">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          Placed Orders
        </p>

        <div style={{ marginTop: "16px" }}>
          {previousOrders?.length === 0 && loading === false && (
            <h1 className="ff-roboto fw-bold">No previous orders!</h1>
          )}
          <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-2 max-sm:grid-cols-1">
            {previousOrders?.map((order, index) => (
              <div className="mt-3" key={order?.id}>
                <Link
                  to={`/previous-order/${order?.id}`}
                  className="mt-3 text-decoration-none"
                >
                  <OrderCard
                    order={order}
                    orderNo={previousOrders?.length - index}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PlacedOrder;
