const usefulLinks = [
    {
        title: 'Size Chart',
        link: '/'
    },
    {
        title: 'About Us',
        link: '/about-us'
    },
    {
        title: 'Contact Us',
        link: '/contact-us'
    },
    {
        title: 'Delivery Policy',
        link: '/delivery-policy'
    },
];

const usefulLinks2 = [
    {
        title: 'Reviews & Client Diaries',
        link: '/'
    },
    {
        title: 'Refund & Exchange Policy',
        link: '/refund-and-exchange-policy'
    },
    {
        title: 'Privacy Policy',
        link: '/privacy-policies'
    }
];

export {
    usefulLinks,
    usefulLinks2
}