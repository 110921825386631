import React, { useEffect, useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation } from "react-router-dom";
import { Badge } from "@mui/material";
import { useCartStore, useUserStore } from "../app/store";

export default function BottomNavBar() {
  const location = useLocation();
  const [value, setValue] = useState();
  console.log("llll ;:: ", location.pathname);
  const { user } = useUserStore();
  const localCart = JSON.parse(localStorage.getItem("cart"));
  const { storeCart } = useCartStore();

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    console.log("valll :: ", newValue);
    setValue(newValue);
  };

  return (
    <BottomNavigation
      sx={{ width: "100%" }}
      value={value}
      onChange={handleChange}
      className="fixed bottom-0 "
    >
      <div className="flex justify-between w-full ">
        <Link
          to="/"
          className={`flex ${value === "/" && "m-2 bg-[#E8E8E8] rounded-xl"}`}
        >
          <BottomNavigationAction
            value="recents"
            icon={
              <HomeIcon
                style={{ fill: `${value === "/" ? "#333333" : "#AAAAAA"}` }}
              />
            }
          />
        </Link>
        <Link
          to="/wish-list"
          className={`flex ${
            value === "favorites" && "m-2 bg-[#E8E8E8] rounded-xl"
          }`}
        >
          <BottomNavigationAction
            value="/wishlish"
            icon={
              <Badge badgeContent={user?.wishlist?.length} color="primary">
                <FavoriteIcon
                  style={{
                    fill: `${value === "/wish-list" ? "#333333" : "#AAAAAA"}`,
                  }}
                />
              </Badge>
            }
          />
        </Link>

        <Link
          to="/cart"
          className={`flex ${
            value === "/cart" && "m-2 bg-[#E8E8E8] rounded-xl"
          }`}
        >
          <BottomNavigationAction
            value="nearby"
            icon={
              <Badge
                badgeContent={
                  user && user?.id ? user?.cart?.length : storeCart?.length
                }
                color="primary"
              >
                <ShoppingCartIcon
                  style={{
                    fill: `${value === "/cart" ? "#333333" : "#AAAAAA"}`,
                  }}
                />
              </Badge>
            }
          />
        </Link>
        <Link
          to="/profile-settings"
          className={`flex ${
            value === "/profile-settings" && "m-2 bg-[#E8E8E8] rounded-xl"
          }`}
        >
          <BottomNavigationAction
            value="folder"
            icon={
              <PersonIcon
                style={{
                  fill: `${
                    value === "/profile-settings" ? "#333333" : "#AAAAAA"
                  }`,
                }}
              />
            }
          />
        </Link>
      </div>
    </BottomNavigation>
  );
}
