import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebase } from '../firebase'


const TermsAndConditions = () => {
  const navigate = useNavigate()
  const [termsAndConditions, setTermsAndConditions] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    firebase
      .firestore()
      .collection("termsConditions")
      .doc("terms")
      .get()
      .then((doc) => {
        setTermsAndConditions(doc?.data()?.terms)
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
  return (
    <div className="terms-and-conditions text-center">
      <div className="position-fixed">
        <i
          className="fa fa-chevron-left cursor-pointer float-start"
          onClick={() => navigate(-1)}
        ></i>
        <h1>Terms & Conditions</h1>
      </div>
      <div
        className="mt-5 text-left"
        dangerouslySetInnerHTML={{ __html: termsAndConditions }}
      ></div>
    </div>
  )
}

export default TermsAndConditions
