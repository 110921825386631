import {Button, IconButton, InputAdornment, TextField} from '@mui/material';
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import LogoWhite from '../assets/imgs/side-bar-logo/logo@3x.png';
import {addDoc} from '../utils';
import {useAuth} from '../contexts/AuthContext';
import Swal from 'sweetalert2';
import {Spin} from 'antd';
import ShowPasswordIcon from '../assets/imgs/show-password-eye-icon.svg';
import HidePasswordIcon from '../assets/imgs/hide-password-eye-icon.svg';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Registration = () => {
  const navigate = useNavigate();
  const {signup, currentUser} = useAuth();
  const [isLoading, setIsLoading] = useState(null);
  const [showPassowrd, setShowPassowrd] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassowrd] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: ''
  });

  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);

  const handleChange = (e) => {
    setUserData((prev) => ({...prev, [e.target.name]: e.target?.value}));
  };

  const handleUserRegistration = async (e) => {
    try {
      e.preventDefault();
      const isValid = Object.keys(userData).every((k) => userData[k]);
      if (isValid === false) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Please fill all the fields.',
        });
        return;
      }
      if(userData?.password !== userData?.confirm_password) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Password/Confirm Password not matched.',
        });
        return;
      }
      setIsLoading(true);
      let response = await signup(userData?.email, userData?.password);
      if (response) {
        let result = await addDoc('users', {
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          email: userData?.email,
          cart: [],
        });
        navigate('/');
        setIsLoading(false);
      }
    } catch (error) {
      Swal.fire({title: 'Error!', text: error.message, icon: 'error'});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className='lg:mx-[400px]  text-center mb-[48px] mt-[8rem] ml-4 mr-4'>
        <h1 className='mt-5 mb-3 cart-header'>Create an Account</h1>

        {currentUser && currentUser?.email}
        <form className='mt-[36px]'>
          <div class='w-full'>
            <label
              class='text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2'
              for='grid-first-name'>
              First name
            </label>
            <input
              class='appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-first-name'
              type='text'
              name='first_name'
              value={userData?.first_name}
              onChange={handleChange}
              variant='outlined'
              placeholder='First name'
              required
            />
          </div>
          <div class='w-full mt-3'>
            <label
              class='text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2'
              for='grid-last-name'>
              Last name
            </label>
            <input
              class='appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-last-name'
              type='text'
              name='last_name'
              value={userData?.last_name}
              onChange={handleChange}
              variant='outlined'
              placeholder='Last name'
              required
            />
          </div>
          <div class='w-full mt-3'>
            <label
              class='text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2'
              for='grid-email'>
              Email
            </label>
            <input
              class='appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-email'
              type='email'
              name='email'
              variant='outlined'
              placeholder='Email'
              value={userData?.email}
              onChange={handleChange}
              required
            />
          </div>
          <div class='w-full mt-3 '>
            <label
              class='text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2'
              for='grid-passowrd'>
              Password
            </label>
            <div className='relative flex items-center justify-end'>
              <input
                class='appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-passowrd'
                name='password'
                placeholder='Password'
                value={userData?.password}
                onChange={handleChange}
                required
                type={showPassowrd ? 'text' : 'password'}
              />
              <div class='absolute mr-2 w-10'>
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassowrd((prev) => !prev)}
                    // onMouseDown={() => setShowPassowrd((prev) => !prev)}
                  >
                    {showPassowrd === true ? (
                      <VisibilityOffIcon
                        sx={{fill: '#333333', width: '24px', height: '24px'}}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{fill: '#333333', width: '24px', height: '24px'}}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>
            </div>
          </div>
          <div class='w-full mt-3 '>
            <label
              class='text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2'
              for='grid-confirm-passowrd'>
              Confirm Password
            </label>
            <div className='relative flex items-center justify-end'>
              <input
                class='appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-confirm-passowrd'
                name='confirm_password'
                placeholder='Password'
                value={userData?.confirm_password}
                onChange={handleChange}
                required
                type={showConfirmPassowrd ? 'text' : 'password'}
              />
              <div class='absolute mr-2 w-10'>
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowConfirmPassowrd((prev) => !prev)}
                    // onMouseDown={() => setShowPassowrd((prev) => !prev)}
                  >
                    {showConfirmPassowrd === true ? (
                      <VisibilityOffIcon
                        sx={{fill: '#333333', width: '24px', height: '24px'}}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{fill: '#333333', width: '24px', height: '24px'}}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>
            </div>
          </div>
          <div
            class='new-product-button flex-1 mr-2 bg-black text-white p-3 mt-6 cursor-pointer'
            onClick={handleUserRegistration}>
            Create Account
          </div>
          <div className='flex justify-center mt-6'>
            <p className='radio-text'>Already have an account?</p>
          </div>
          <Link
            to='/login'
            class='new-product-button flex-1 mr-2  p-3 mt-2 text-black'>
            Login
          </Link>
        </form>
      </div>
    </Spin>
  );
};

export default Registration;
