import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='px-4 mt-[8rem] ml-[20px] mr-[20px] container mb-[48px]'>
      <p className='cart-header'>Privacy Policy</p>
      <div className='mt-[48px] text-start'>
        <p className='article-text'>
          We value your privacy and are committed to protecting your personal
          information. This Privacy Policy outlines how we collect, use, and
          store the data you provide on our website for our e-commerce project.
          By accessing and using our website, you consent to the practices
          described in this policy.
        </p>
        <div className='mt-3'>
          <h1 className='cart-title'>What We Collect?</h1>
          <p className='mt-1 article-text'>
            Our mission is to bring luxury Pakistani designer clothing right to
            your doorstep. Working with only the best karigars (artisan) in
            Pakistan, we deliver high quality, beautifully stitched pieces.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>What We do with the Information?</h1>
          <p className='mt-1 article-text'>
            We use the data you provide solely for the purpose of processing
            your orders, delivering products, and communicating with you
            regarding your purchases. We do not track your online behaviour or
            activities beyond what is essential for fulfilling your orders.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Data Security:</h1>
          <p className='mt-1 article-text'>
            Your personal information is treated with utmost confidentiality and
            stored securely on our server. We employ industry-standard security
            measures to protect your data from unauthorised access, loss, or
            misuse.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Sharing of Information:</h1>
          <p className='mt-1 article-text'>
            We do not sell, rent, or share your personal information with any
            third parties for marketting purposes. However, we may share your
            information with trusted third-party service providers involved in
            order fulfilment, payment processing, and customer support, but
            strictly for the purpose of assisting us in serving you better.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Third-Party Links:</h1>
          <p className='mt-1 article-text'>
            Our website does not contain links to any third-party websites.
            Please be aware that we are not responsible for any privacy
            practices of these external sites as we are not referring to any
            other website. So we'll not be responsible if any other website
            mention our involvement.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Payment information:</h1>
          <p className='mt-1 article-text'>
            We uphold a stringent policy with regards to payment information
            security. It is important to clarify that we do not retain any
            payment-related details within our systems. The entire spectrum of
            payment processing is diligently managed through a trusted
            third-party service provider, namely "Stripe." It is essential to
            acknowledge that we shall not bear responsibility for any potential
            breaches or compromises that may occur under any circumstances. Your
            trust and data security remain of paramount importance to us.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Your Rights:</h1>
          <p className='mt-1 article-text'>
            You have the right to access, correct, or delete your personal
            information held by us. If you wish to exercise any of these rights
            or have concerns about your data, please contact us
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Data Retention:</h1>
          <p className='mt-1 article-text'>
            We retain your personal information only for as long as necessary to
            fulfill the purposes for which it was collected or as required by
            applicable laws and regulations.
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Changes to the Privacy Policy:</h1>
          <p className='mt-1 article-text'>
            We may update this Privacy Policy from time to time to reflect
            changes in our business practices or legal requirements. We will
            post the updated policy on our website and notify you of any
            significant changes. By using our website, you agree to the terms
            outlined in this Privacy
          </p>
        </div>

        <div className='mt-3'>
          <h1 className='cart-title'>Effective Date: 4-8-2023</h1>
          <p className='mt-1 article-text'>
            This Privacy Policy is designed to be compliant with UK data
            protection laws and regulations. Please note that this policy may
            need to be reviewed and tailored to your specific business practices
            and the evolving legal landscape. It is always advisable to seek
            legal counsel to ensure compliance with all relevant laws and
            regulations
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
