import React, { useEffect, useState } from "react";
import FilterIcon from "../assets/imgs/filter-icon.svg";
import CloseIcon from "../assets/imgs/close-icon.svg";
import DropdownArrowIcon from "../assets/imgs/dropdown-arrow-icon.svg";
import Footer from "../layout/Footer";
import { Drawer, InputLabel, MenuItem, Select } from "@mui/material";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";
import SearchProducts from "../components/SearchProducts";
import ProductCard from "../components/ProductCard";
import { getAllDataFromCollection, getFilteredData } from "../utils";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import NewProductCard from "../components/NewProductCard";
import { useAuth } from "../contexts/AuthContext";
import {
  useBrandsStore,
  useCategoriesStore,
  useProductsStore,
  useUserStore,
} from "../app/store";

const ProductsList = () => {
  // const { search } = useLocation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productName = searchParams.get("name");
  const { products: storeProducts } = useProductsStore();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { brands: storeBrands } = useBrandsStore();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [specialOfferDetail, setSpecialOfferDetail] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [categories, setCategories] = useState([]);
  const { categoryId } = useParams();
  const { categories: storeCategories } = useCategoriesStore();
  const [loading, setLoading] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const { currentUser } = useAuth();
  const [filterByOptions, setFilterByOptions] = useState({
    sortBy: "",
    sortOrder: "",
    filterByBrand: "",
    filterByCategory: "",
    filterBySize: "",
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (location.pathname === "/special-offer-products") {
      getAllProducts();
    } else {
      if (categoryId && location.pathname.includes("new")) {
        fetchCategoryNewProducts();
      } else if (categoryId) {
        fetchCategoryProducts();
      } else {
        getAllProducts();
      }
    }
    fetchBrands();
    fetchCategories();
  }, [categoryId]);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const [userDetails, setUserDetails] = useState({});

  const { updateUser } = useUserStore();

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        setUserDetails(userDetail);
        updateUser(userDetail);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (storeProducts?.length > 0) {
      if (productName && !isFiltered) {
        let filteredProducts = storeProducts?.filter((product) => {
          return product.name
            .toLowerCase()
            .trim()
            ?.includes(productName?.toLowerCase().trim());
        });
        let filteredBrands = storeBrands?.filter((brand) =>
          brand.name.toLowerCase().includes(productName?.toLowerCase())
        );
        let brandProducts = storeProducts?.filter((product) => {
          let isBrandProduct = filteredBrands?.findIndex(
            (brand) => brand?.id == product?.brand
          );
          return isBrandProduct != -1 ? true : false;
        });
        let filteredCategories = storeCategories?.filter((cat) =>
          cat.name.toLowerCase().includes(productName?.toLowerCase())
        );
        let categoriesProducts = storeProducts?.filter((product) => {
          let isCategoryProduct = filteredCategories?.findIndex(
            (cat) => cat?.id == product?.category
          );
          return isCategoryProduct != -1 ? true : false;
        });
        filteredProducts = [
          ...filteredProducts,
          ...brandProducts,
          ...categoriesProducts,
        ];
        if (categoryId) {
          filteredProducts = filteredProducts?.filter((product) => {
            return product.category == categoryId;
          });
        }
        if (products?.length !== filteredProducts?.length) {
          setProducts(filteredProducts);
        } else if (
          products?.length == filteredProducts?.length &&
          products[0]?.name !== filteredProducts[0]?.name
        ) {
          setProducts(filteredProducts);
        }
      } else {
        if (!isFiltered) {
          if (categoryId) {
            let filteredProducts = storeProducts?.filter((product) => {
              return product.category === categoryId;
            });
            if (location.pathname.includes("new")) {
              filteredProducts = filteredProducts.filter(
                (product) => product["is-new-product"] == true
              );
            }
            if (products?.length != filteredProducts?.length) {
              setProducts(filteredProducts);
            }
          } else {
            if (products?.length != storeProducts?.length) {
              setProducts(storeProducts);
            }
          }
        }
      }
    }
  }, [productName, products]);

  const fetchBrands = async () => {
    try {
      // let data = await getAllDataFromCollection("brands");
      let data = [...storeBrands];
      setBrands(data);
    } catch (error) {
      console.error(error);
    }
  };

  const filterProducts = async () => {
    try {
      setIsFiltered(true);
      let filteredProducts = storeProducts?.filter((product) => {
        return product.name
          ?.toLowerCase()
          ?.trim()
          ?.includes(productName?.toLowerCase()?.trim());
      });
      let filteredBrands = storeBrands?.filter((brand) =>
        brand.name.toLowerCase().includes(productName?.toLowerCase())
      );
      let brandProducts = storeProducts?.filter((product) => {
        let isBrandProduct = filteredBrands?.findIndex(
          (brand) => brand?.id == product?.brand
        );
        return isBrandProduct != -1 ? true : false;
      });
      let filteredCategories = storeCategories?.filter((cat) =>
        cat.name.toLowerCase().includes(productName?.toLowerCase())
      );
      let categoriesProducts = storeProducts?.filter((product) => {
        let isCategoryProduct = filteredCategories?.findIndex(
          (cat) => cat?.id == product?.category
        );
        return isCategoryProduct != -1 ? true : false;
      });
      filteredProducts = [
        ...filteredProducts,
        ...brandProducts,
        ...categoriesProducts,
      ];
      if (categoryId) {
        filteredProducts = filteredProducts?.filter((product) => {
          return product.category == categoryId;
        });
      }
      let tempProducts = [];
      if (!productName || products?.length == storeProducts?.length) {
        tempProducts = [...initialData];
        if (tempProducts?.length === 0) {
          setProducts(initialData);
        }
      } else {
        tempProducts = [...filteredProducts];
        if (tempProducts?.length === 0) {
          setProducts(filteredProducts);
        }
      }
      setIsDrawerOpen(false);
      setLoading(true);
      if (filterByOptions?.sortBy) {
        tempProducts = tempProducts?.map((item) => {
          let prod = { ...item };
          if (item?.price && item?.unstitchedDiscountPrice) {
            if (Number(item?.price) > Number(item?.unstitchedDiscountPrice)) {
              prod["priceval"] = item?.unstitchedDiscountPrice;
            } else {
              prod["priceval"] = item?.price;
            }
          } else if (item?.price) {
            prod["priceval"] = item?.price;
          } else {
            prod["priceval"] = item?.unstitchedDiscountPrice;
          }
          return prod;
        });
        if (filterByOptions?.sortBy === "name") {
          tempProducts = tempProducts?.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setLoading(false);
        } else if (filterByOptions?.sortBy === "price_low_to_high") {
          tempProducts.sort((a, b) => Number(a.priceval) - Number(b.priceval));
        } else if (filterByOptions?.sortBy === "price_high_to_low") {
          tempProducts.sort((a, b) => Number(a.priceval) - Number(b.priceval));
          tempProducts = tempProducts?.reverse();
        }
      }
      if (filterByOptions?.filterByCategory) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.category === filterByOptions.filterByCategory
        );
        setLoading(false);
        setProducts(data);
        tempProducts = [...data];
        setFilteredProducts(data);
      }
      if (filterByOptions?.filterByBrand) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.brand === filterByOptions.filterByBrand
        );
        tempProducts = [...data];
        setLoading(false);
        setProducts(data);
        setFilteredProducts(data);
      }
      if (filterByOptions?.filterBySize) {
        let data = [];
        if (
          !filterByOptions?.filterByCategory &&
          !filterByOptions?.filterByBrand
        ) {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        } else {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        }
        if (data) {
          tempProducts = [...data];
        }
      }
      setLoading(false);
      setProducts([...tempProducts]);
      if (filterByOptions?.sortOrder === "descending") {
        setProducts([...tempProducts.reverse()]);
      } else setProducts(tempProducts);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getCategories = async (filtereProducts = []) => {
    try {
      let response = await getAllDataFromCollection("categories");
      if (response) {
        const caregoryProducts = filtereProducts?.map((pro) => {
          const findcategory = response?.find(
            (cat) => cat?.id === pro?.category
          );
          if (findcategory) {
            pro.categoryName = findcategory?.name;
          } else {
            pro.categoryName = "N/A";
          }
          return pro;
        });
        if (productName) {
          let filteredProducts = caregoryProducts?.filter((product) => {
            return product.name
              .toLowerCase()
              .trim()
              ?.includes(productName?.toLowerCase().trim());
          });
          let filteredBrands = storeBrands?.filter((brand) =>
            brand.name.toLowerCase().includes(productName?.toLowerCase())
          );
          let brandProducts = storeProducts?.filter((product) => {
            let isBrandProduct = filteredBrands?.findIndex(
              (brand) => brand?.id == product?.brand
            );
            return isBrandProduct != -1 ? true : false;
          });
          let filteredCategories = storeCategories?.filter((cat) =>
            cat.name.toLowerCase().includes(productName?.toLowerCase())
          );
          let categoriesProducts = storeProducts?.filter((product) => {
            let isCategoryProduct = filteredCategories?.findIndex(
              (cat) => cat?.id == product?.category
            );
            return isCategoryProduct != -1 ? true : false;
          });
          filteredProducts = [
            ...filteredProducts,
            ...brandProducts,
            ...categoriesProducts,
          ];
          setProducts(filteredProducts);
        } else {
          setProducts(caregoryProducts);
          setInitialData([...caregoryProducts]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      // let data = await getAllDataFromCollection("categories");
      let data = [...storeCategories];
      setLoading(false);
      setCategories(data);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getAllProducts = async () => {
    try {
      setLoading(true);
      // const data = await getAllDataFromCollection("products");
      let data = [];
      if (categoryId) {
        data = [
          ...storeProducts?.filter(
            (product) => product.category === categoryId
          ),
        ];
      } else {
        data = [...storeProducts];
      }
      if (location.pathname === "/special-offer-products") {
        fetchSpecialOfferProducts(data);
        return;
      }
      getCategories(data);
      if (productName) {
        let filteredProducts = data?.filter((product) => {
          return product.name
            .toLowerCase()
            .trim()
            ?.includes(productName?.toLowerCase().trim());
        });
        let filteredBrands = storeBrands?.filter((brand) =>
          brand.name.toLowerCase().includes(productName?.toLowerCase())
        );
        let brandProducts = storeProducts?.filter((product) => {
          let isBrandProduct = filteredBrands?.findIndex(
            (brand) => brand?.id == product?.brand
          );
          return isBrandProduct != -1 ? true : false;
        });
        let filteredCategories = storeCategories?.filter((cat) =>
          cat.name.toLowerCase().includes(productName?.toLowerCase())
        );
        let categoriesProducts = storeProducts?.filter((product) => {
          let isCategoryProduct = filteredCategories?.findIndex(
            (cat) => cat?.id == product?.category
          );
          return isCategoryProduct != -1 ? true : false;
        });
        filteredProducts = [
          ...filteredProducts,
          ...brandProducts,
          ...categoriesProducts,
        ];
        setProducts(filteredProducts);
      } else {
        setProducts(data);
      }
      setInitialData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchSpecialOfferProducts = async (allProducts = []) => {
    try {
      setLoading(true);
      const data = await getAllDataFromCollection("specialOffer");
      if (data[0].isActive === true) {
        setSpecialOfferDetail(data[0]);
        const filteredProducts = allProducts?.filter((prod) =>
          data[0]?.products?.includes(prod?.id)
        );
        setProducts(filteredProducts);
        setInitialData(filteredProducts);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
    }
  };

  const fetchCategoryProducts = async () => {
    try {
      setLoading(true);
      let queryData = [
        ...storeProducts?.filter((product) => product.category == categoryId),
      ];
      setInitialData(queryData);
      setProducts(queryData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchCategoryNewProducts = async () => {
    try {
      setLoading(true);
      let queryData = await getFilteredData(
        "products",
        "category",
        "==",
        categoryId,
        "is-new-product",
        "==",
        true
      );
      setInitialData(queryData);
      setProducts(queryData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const likeProduct = (item, index) => {
    const cProductItems = [...products];
    cProductItems[index] = item;
    setProducts([...cProductItems]);
  };

  return (
    <Spin spinning={loading}>
      <div className="products-list position-relative footer-container container mt-[6rem]">
        <div className="px-3 mt-3 mb-2 d-flex justify-content-between align-items-center">
          <h1 className="total-products-label">
            Showing {products?.length} products
          </h1>
          <div
            className="gap-1 px-4 py-1 cursor-pointer filter d-flex"
            onClick={() => setIsDrawerOpen(true)}
          >
            <img src={FilterIcon} alt="" width={14} height={12} />
            <span>Filter</span>
          </div>
        </div>
        {loading === false && products?.length === 0 && (
          <div className="mt-4 text-center">
            <img src={NoProductsFoundPlaceHolder} />
          </div>
        )}
        <div className="px-2 m-0 row g-2">
          {products &&
            products?.map((product, index) => {
              return (
                <div className="col-6 col-lg-3 col-xxl-2" key={product?.id}>
                  <div className="text-decoration-none h-100">
                    <NewProductCard
                      product={product}
                      uid={currentUser?.uid || ""}
                      likeProduct={likeProduct}
                      index={index}
                      categories={categories}
                      userDetail={userDetails}
                      fetchProducts={getAllProducts}
                      fetchUserDetails={fetchUserDetails}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mt-3"></div>
        <div className="">
          <Drawer
            anchor={"bottom"}
            open={isDrawerOpen}
            className="mx-3 filters-wrapper md:hidden"
            onClose={() => setIsDrawerOpen(false)}
          >
            <div className="filter-drawer">
              <div className="mb-5 d-flex justify-content-between align-items-center">
                <h1 className="filters-label">Filters</h1>
                <img
                  src={CloseIcon}
                  alt=""
                  height={32}
                  className="cursor-pointer"
                  width={32}
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>
              <div className="m-0 row">
                <div className="gap-4 p-0 col-12 d-flex justify-content-between">
                  <div className="w-100">
                    <InputLabel id="select-standard-label">Sort by</InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        color: "black",
                        fontSize: "16px",
                      }}
                      inputProps={{ style: { border: "1px solid red" } }}
                      labelId="select-standard-label"
                      id="select-standard"
                      variant="standard"
                      onChange={(e) =>
                        setFilterByOptions((prev) => ({
                          ...prev,
                          sortBy: e.target.value,
                        }))
                      }
                      value={filterByOptions?.sortBy}
                      IconComponent={() => (
                        <img src={DropdownArrowIcon} alt="" />
                      )}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"name"}>Name</MenuItem>
                      <MenuItem value={"price_low_to_high"}>
                        Price Low to High
                      </MenuItem>
                      <MenuItem value={"price_high_to_low"}>
                        Price High to Low
                      </MenuItem>
                    </Select>
                  </div>
                  {filterByOptions?.sortBy !== "price_low_to_high" &&
                    filterByOptions?.sortBy !== "price_high_to_low" && (
                      <div className="w-100">
                        <InputLabel id="select-standard-label">
                          Sort Order
                        </InputLabel>
                        <Select
                          labelId="select-standard-label"
                          id="select-standard"
                          sx={{ width: "100%" }}
                          variant="standard"
                          IconComponent={() => (
                            <img src={DropdownArrowIcon} alt="" />
                          )}
                          onChange={(e) =>
                            setFilterByOptions((prev) => ({
                              ...prev,
                              sortOrder: e.target.value,
                            }))
                          }
                          value={filterByOptions?.sortOrder}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"ascending"}>Ascending</MenuItem>
                          <MenuItem value={"descending"}>Descending</MenuItem>
                        </Select>
                      </div>
                    )}
                </div>
                <div className="p-0 mt-4 col-12">
                  <InputLabel id="select-standard-label">
                    Filter by Brand
                  </InputLabel>
                  <Select
                    labelId="select-standard-label"
                    id="select-standard"
                    sx={{ width: "100%" }}
                    variant="standard"
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        filterByBrand: e.target.value,
                      }))
                    }
                    value={filterByOptions?.filterByBrand}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {brands?.map((brand) => (
                      <MenuItem
                        value={brand?.id}
                        key={brand?.id}
                        className="text-capitalize"
                      >
                        {brand?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="p-0 mt-4 col-12">
                  <InputLabel id="select-standard-label">
                    Filter by Category
                  </InputLabel>
                  <Select
                    labelId="select-standard-label"
                    id="select-standard"
                    sx={{ width: "100%" }}
                    variant="standard"
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        filterByCategory: e.target.value,
                      }))
                    }
                    value={filterByOptions?.filterByCategory}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories?.map((category) => (
                      <MenuItem value={category?.id} key={category?.id}>
                        {category?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="mt-5 mb-4 d-flex justify-center sm:justify-between align-items-center flex-wrap gap-2">
                <button
                  className="bg-gray-filled-btn"
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    setIsFiltered(false);
                    setIsDrawerOpen(false);
                    setFilterByOptions({});
                    // navigate("/products");
                    if (location.pathname === "/special-offer-products") {
                      getAllProducts();
                    } else {
                      if (categoryId && location.pathname.includes("new")) {
                        fetchCategoryNewProducts();
                      } else if (categoryId) {
                        fetchCategoryProducts();
                      } else {
                        getAllProducts();
                      }
                    }
                  }}
                >
                  CLEAR ALL
                </button>
                <button
                  className="bg-primary-filled-btn"
                  style={{ fontSize: "15px" }}
                  onClick={filterProducts}
                >
                  APPLY FILTERS
                </button>
              </div>
            </div>
          </Drawer>
        </div>
        <div className="hidden md:block">
          <Drawer
            anchor={"left"}
            open={isDrawerOpen}
            className="mx-3 filters-wrapper hidden md:block"
            onClose={() => setIsDrawerOpen(false)}
          >
            <div className="filter-drawer">
              <div className="mb-5 d-flex justify-content-between align-items-center">
                <h1 className="filters-label">Filters</h1>
                <img
                  src={CloseIcon}
                  alt=""
                  height={32}
                  className="cursor-pointer"
                  width={32}
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>
              <div className="m-0 row">
                <div className="gap-4 p-0 col-12 d-flex justify-content-between">
                  <div className="w-100">
                    <InputLabel id="select-standard-label">Sort by</InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        color: "black",
                        fontSize: "16px",
                      }}
                      inputProps={{ style: { border: "1px solid red" } }}
                      labelId="select-standard-label"
                      id="select-standard"
                      variant="standard"
                      onChange={(e) =>
                        setFilterByOptions((prev) => ({
                          ...prev,
                          sortBy: e.target.value,
                        }))
                      }
                      value={filterByOptions?.sortBy}
                      IconComponent={() => (
                        <img src={DropdownArrowIcon} alt="" />
                      )}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"name"}>Name</MenuItem>
                      <MenuItem value={"price_low_to_high"}>
                        Price Low to High
                      </MenuItem>
                      <MenuItem value={"price_high_to_low"}>
                        Price High to Low
                      </MenuItem>
                    </Select>
                  </div>
                  {filterByOptions?.sortBy !== "price_low_to_high" &&
                    filterByOptions?.sortBy !== "price_high_to_low" && (
                      <div className="w-100">
                        <InputLabel id="select-standard-label">
                          Sort Order
                        </InputLabel>
                        <Select
                          labelId="select-standard-label"
                          id="select-standard"
                          sx={{ width: "100%" }}
                          variant="standard"
                          IconComponent={() => (
                            <img src={DropdownArrowIcon} alt="" />
                          )}
                          onChange={(e) =>
                            setFilterByOptions((prev) => ({
                              ...prev,
                              sortOrder: e.target.value,
                            }))
                          }
                          value={filterByOptions?.sortOrder}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"ascending"}>Ascending</MenuItem>
                          <MenuItem value={"descending"}>Descending</MenuItem>
                        </Select>
                      </div>
                    )}
                </div>
                <div className="p-0 mt-4 col-12">
                  <InputLabel id="select-standard-label">
                    Filter by Brand
                  </InputLabel>
                  <Select
                    labelId="select-standard-label"
                    id="select-standard"
                    sx={{ width: "100%" }}
                    variant="standard"
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        filterByBrand: e.target.value,
                      }))
                    }
                    value={filterByOptions?.filterByBrand}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {brands?.map((brand) => (
                      <MenuItem
                        value={brand?.id}
                        key={brand?.id}
                        className="text-capitalize"
                      >
                        {brand?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="p-0 mt-4 col-12">
                  <InputLabel id="select-standard-label">
                    Filter by Category
                  </InputLabel>
                  <Select
                    labelId="select-standard-label"
                    id="select-standard"
                    sx={{ width: "100%" }}
                    variant="standard"
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        filterByCategory: e.target.value,
                      }))
                    }
                    value={filterByOptions?.filterByCategory}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories?.map((category) => (
                      <MenuItem value={category?.id} key={category?.id}>
                        {category?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="mt-5 mb-4 d-flex justify-center sm:justify-between align-items-center flex-wrap gap-2">
                <button
                  className="bg-gray-filled-btn"
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    setIsFiltered(false);
                    setIsDrawerOpen(false);
                    setFilterByOptions({});
                    // navigate("/products");
                    if (location.pathname === "/special-offer-products") {
                      getAllProducts();
                    } else {
                      if (categoryId && location.pathname.includes("new")) {
                        fetchCategoryNewProducts();
                      } else if (categoryId) {
                        fetchCategoryProducts();
                      } else {
                        getAllProducts();
                      }
                    }
                  }}
                >
                  CLEAR ALL
                </button>
                <button
                  className="bg-primary-filled-btn"
                  style={{ fontSize: "15px" }}
                  onClick={filterProducts}
                >
                  APPLY FILTERS
                </button>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </Spin>
  );
};

export default ProductsList;
