import React, { useEffect, useState } from "react";
import InstagramIcon from "../assets/imgs/instagram-footer-icon.png";
import WhatsappIcon from "../assets/imgs/whatsapp-footer-icon.png";
import Logo from "../assets/imgs/footer-logo-image.png";
import { Link } from "react-router-dom";
import { usefulLinks, usefulLinks2 } from "../constants/footer-links";
import TiktokIcon from "../assets/imgs/tiktok.png";
import Payment from "../assets/imgs/payments.png";
import ReviewDialog from "./ReviewDialog";
import SizeChartDialog from "./SizeChartDialog";

const Footer = () => {
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [showSizeChartDialog, setShowSizeChartDialog] = useState(false);
  const handleContactOnWhatsApp = async () => {
    const url = `https://wa.me/+447779575273`;
    window.open(url, "_blank");
  };

  const [isScrolled, setIsScrolled] = useState([]);

  const goToInstagramProfile = async () => {
    const url = `https://www.instagram.com/marizaofficial_/`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="pt-4 mt-auto text-center bg-black footer pb-[56px] sm:pb-4"
      // className={`footer bg-black text-center py-4 ${
      //   isScrolled ? 'position-sticky' : ''
      // }`}
      // style={{ left: 0, right: 0, top: '68%' }}
    >
      {/* <h1 className="text-white fs-18">Follow Us</h1>
      <div className="gap-4 mt-4 bg-black d-flex justify-content-center align-items-center">
        <img
          src={InstagramIcon}
          alt=""
          height={32}
          width={32}
          className="cursor-pointer"
          onClick={goToInstagramProfile}
        />
        <img
          src={WhatsappIcon}
          alt=""
          height={32}
          className="cursor-pointer"
          width={32}
          style={{ marginLeft: ".5rem" }}
          onClick={handleContactOnWhatsApp}
        />
      </div>
      <hr className="gradient-horizontal-line-footer" />
      <div className="m-0 mt-4 text-center bg-black row">
        <Link to={"/about-us"}>
          <p className="footer-about-mariza-text text-[12px] md:text-[1.25rem]">
            About Us
          </p>
        </Link>
        <Link to={"/privacy-policies"}>
          <p className="footer-about-mariza-text text-[12px] md:text-[1.25rem] mt-2">
            PRIVACY POLICIES
          </p>
        </Link>
        <Link to={"/refund-and-exchange-policy"}>
          <p className="footer-about-mariza-text text-[12px] md:text-[1.25rem] mt-2">
            REFUNDS AND EXCHANGE POLICY
          </p>
        </Link>
        <Link to={"/privacy-policies"}>
          <p className="footer-about-mariza-text text-[12px] md:text-[1.25rem] mt-2">
            DELIVERY INFORMATION
          </p>
        </Link>
        <Link to={"/contact-us"}>
          <p className="footer-about-mariza-text text-[12px] md:text-[1.25rem] mt-2">
            CONTACT US
          </p>
        </Link>
      </div>
      <img src={Logo} alt="" height={56} width={150} className="m-auto mt-5" /> */}
      <div className="row mt-[48px]">
        <div className=" col-12 col-lg-6">
          <div className="flex flex-col  lg:ml-[120px] max-lg:m-[20px]">
            <img src={Logo} alt="" height={56} width={150} />
            <p className="mt-2 footer-text">@2023 All Right Reserved. </p>
            {/* <p className="mt-5 footer-text text-start">Our mission is to bring luxury Pakistani designer clothing right to your doorstep. <br />
          Working with only the best karigars (artisan) in Pakistan we can deliver high quality,<br />
          beautifully stitched pieces. </p>

          <p className="mt-4 footer-text text-start">Stocking the most recent collections, this is the place to shop for your luxury Asian  <br />
          designer wear. So whether the occasion is a wedding or an informal gathering, put your <br />
         trust in Mariza Official to ensure you dress to impress!</p> */}
          </div>
        </div>
        <div className="col-12 col-lg-5 ">
          <div className="flex justify-center max-lg:m-[20px]">
            {/* <div className="flex-1"></div> */}
            <div className="flex justify-center w-full text-center ">
              <div className="flex flex-col w-full text-start">
                <h1 className="footer-useful-links">Useful Links</h1>
                <div className="flex justify-between w-full">
                  <div className="flex flex-col ">
                    {usefulLinks?.map((uLink) =>
                      uLink.title === "Size Chart" ? (
                        <div
                          className="footer-useful-links-option mt-[8px] cursor-pointer"
                          onClick={() => setShowSizeChartDialog(true)}
                        >
                          {uLink.title}
                        </div>
                      ) : (
                        <Link
                          to={uLink.link}
                          className="footer-useful-links-option mt-[8px]"
                        >
                          {uLink.title}
                        </Link>
                      )
                    )}
                  </div>
                  <div className="flex flex-col ">
                    {usefulLinks2?.map((uLink) =>
                      uLink.title === "Reviews & Client Diaries" ? (
                        <div
                          className="footer-useful-links-option mt-[8px] cursor-pointer"
                          onClick={() => setShowReviewDialog(true)}
                        >
                          {uLink.title}
                        </div>
                      ) : (
                        <Link
                          to={uLink.link}
                          className="footer-useful-links-option mt-[8px]"
                        >
                          {uLink.title}
                        </Link>
                      )
                    )}
                  </div>
                </div>
                {/* <div className="flex text-center mt-[40px] mx-3 gap-4">
                  <img alt="insta" src={InstagramIcon} width={20} height={20} />
                  <img alt="tiktok" src={TiktokIcon} width={20} height={20} />
                </div> */}
                <img
                  alt="Payment"
                  src={Payment}
                  className="mt-[40px]"
                  width={255}
                />
              </div>
            </div>
            {/* <div className="flex-1"></div> */}
          </div>
        </div>
        {/* <div className="bg-pink-400 col-3">3</div> */}
      </div>
      <ReviewDialog
        open={showReviewDialog}
        setOpen={setShowReviewDialog}
        goToInstagramProfile={goToInstagramProfile}
      />
      <SizeChartDialog
        open={showSizeChartDialog}
        setOpen={setShowSizeChartDialog}
      />
    </div>
  );
};

export default Footer;
