import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
});

const userStore = (set) => ({
  user: null,

  updateUser: (updatedUser) =>
    set((state) => {
      const newUser = { ...state.user, ...updatedUser };
      localStorage.setItem("user", JSON.stringify(newUser));
      return { user: newUser };
    }),

  removeUser: () => set((state) => ({ user: null })),
});

const productsStore = (set) => ({
  products: [],

  updateProducts: (updatedProds) =>
    set((state) => {
      return { products: updatedProds };
    }),
});

const brandsStore = (set) => ({
  brands: [],

  updateBrands: (updatedBrands) =>
    set((state) => {
      return { brands: updatedBrands };
    }),
});

const categoriesStore = (set) => ({
  categories: [],

  updateCategories: (updatedCategories) =>
    set((state) => {
      return { categories: updatedCategories };
    }),
});

const shippingPromoCodeStore = (set) => ({
  shipping_promo_code: null,

  updatePromoCode: (shipping_promo_code) =>
    set((state) => {
      return { shipping_promo_code };
    }),
});

const cartStore = (set) => ({
  storeCart: [],

  updateCart: (updateCart) =>
    set((state) => {
      return { storeCart: updateCart };
    }),

  clearCart: () => set((state) => ({ storeCart: [] })),
});

export const useUserStore = create(
  devtools(persist(userStore, { name: "user" }))
);
export const useShippingPromoCodeStore = create(
  devtools(persist(shippingPromoCodeStore, { name: "shippingPromoCode" }))
  // shippingPromoCode
);
export const useProductsStore = create(
  devtools(persist(productsStore, { name: "products" }))
);
export const useCartStore = create(
  devtools(persist(cartStore, { name: "storeCart" }))
);
export const useCategoriesStore = create(
  devtools(persist(categoriesStore, { name: "categories" }))
);
export const useBrandsStore = create(
  devtools(persist(brandsStore, { name: "brands" }))
);
