import { useEffect, useState } from "react";
import NewProductCard from "../../components/NewProductCard";
import { Link } from "react-router-dom";
import {
  getAllDataFromCollection,
  getFilteredData,
  saveData,
} from "../../utils";
import { Spin } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import { useProductsStore, useUserStore } from "../../app/store";

const WhatIsNew = () => {
  const [newProducts, setNewProducts] = useState([]);
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    getNewProducts();
    // fetchUserDetails();
  }, []);
  const { products } = useProductsStore();
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState({});

  const { updateUser, user } = useUserStore();

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        setUserDetails(userDetail);
        updateUser(userDetail);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getNewProducts = async () => {
    try {
      setLoading(true);
      // let response = await getAllDataFromCollection("products");
      let response = [...products];
      setLoading(false);
      let filtereProducts = response?.filter(
        (product) => product["is-new-product"] === true
      );
      console.log("caregoryProducts filtereProducts :: ", filtereProducts);
      setNewProducts(filtereProducts);
      getCategories(filtereProducts);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getCategories = async (filtereProducts = []) => {
    try {
      let response = await getAllDataFromCollection("categories");
      if (response) {
        console.log("caregoryProducts newProducts :: ", newProducts);
        const caregoryProducts = filtereProducts?.map((pro) => {
          const findcategory = response?.find(
            (cat) => cat?.id === pro?.category
          );
          if (findcategory) {
            pro.categoryName = findcategory?.name;
          } else {
            pro.categoryName = "N/A";
          }
          return pro;
        });
        setNewProducts([...caregoryProducts]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const likeProduct = (item, index) => {
    const cProductItems = [...newProducts];
    cProductItems[index] = item;
    setNewProducts([...cProductItems]);
  };

  return (
    <Spin spinning={loading}>
      <div className="pb-4 what-is-new">
        <h1 className="pt-[32px] pb-[16px] main-heading font-bold">
          New Collection
        </h1>
        <div className="px-2 m-0 row g-0">
          {products &&
            products
              ?.filter((pr) => pr["is-new-product"] == true)
              ?.map((product, index) => {
                if (index < 12)
                  return (
                    <div className="col-6 col-lg-3 col-xxl-2" key={product?.id}>
                      <div className="w-full cursor-pointer text-decoration-none h-100">
                        <NewProductCard
                          product={product}
                          uid={currentUser?.uid || ""}
                          likeProduct={likeProduct}
                          index={index}
                          userDetail={user}
                          fetchProducts={getNewProducts}
                          fetchUserDetails={fetchUserDetails}
                        />
                      </div>
                    </div>
                  );
              })}
        </div>
        {/* <Link to={"/new-products"}>
          <button className="mt-4 primary-btn-transparent">
            VIEW ALL NEW PRODUCTS
          </button>
        </Link> */}
      </div>
    </Spin>
  );
};

export default WhatIsNew;
