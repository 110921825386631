import firebase from "firebase/app"
import "firebase/auth"
import "firebase/storage"

// import appConfig from "./firebaseConfig"
// import appConfigProd from "./firebaseConfigProd"

const appConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
}

const appConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_PROD_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROD_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROD_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROD_CONFIG_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_PROD_CONFIG_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_PROD_CONFIG_APP_ID,
}

export default appConfigProd

let firebaseAppConfig = {}

const env = process.env.REACT_APP_ENV

if (env === "development") {
  firebaseAppConfig = appConfig
} else {
  firebaseAppConfig = appConfigProd
}
// firebase.analytics()
const app = firebase.initializeApp(appConfigProd)
export const auth = app.auth()
const storageRef = firebase.storage().ref()

const storage = firebase.storage()
// const auth = firebase.auth()
const db = firebase.firestore(app)

export { firebase, storage, db }
