import React, { useEffect, useState } from "react";
import SearchProducts from "../components/SearchProducts";
import FilterIcon from "../assets/imgs/filter-icon.svg";
import { Drawer, InputLabel, MenuItem, Select } from "@mui/material";
import Footer from "../layout/Footer";
import DropdownArrowIcon from "../assets/imgs/dropdown-arrow-icon.svg";
import CloseIcon from "../assets/imgs/close-icon.svg";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";
import ProductCard from "../components/ProductCard";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllDataFromCollection,
  getCurrentUser,
  getFilteredData,
} from "../utils";
import { Spin } from "antd";
import NewProductCard from "../components/NewProductCard";
import { useAuth } from "../contexts/AuthContext";
import {
  useBrandsStore,
  useCategoriesStore,
  useProductsStore,
  useUserStore,
} from "../app/store";

const BrandProducts = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const [brandProducts, setBrandProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const { updateUser } = useUserStore();
  const [categories, setCategories] = useState([]);
  const { products: storeProducts } = useProductsStore();
  const { categories: storeCategories } = useCategoriesStore();
  const { brands: storeBrands } = useBrandsStore();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [brandDetails, setBrandDetails] = useState(null);
  const { brandId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productName = queryParams.get("name");
  const location = useLocation();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterByOptions, setFilterByOptions] = useState({
    sortBy: "",
    sortOrder: "",
    filterByBrand: "",
    filterByCategory: "",
    filterBySize: "",
  });
  useEffect(() => {
    fetchBrandDetails();
    getBrandProducts();
    fetchBrands();
    fetchCategories();
  }, [brandId]);

  useEffect(() => {
    if (location.pathname.includes("brand-products")) {
      // window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (storeProducts?.length > 0) {
      if (productName && !filteredProducts) {
        let filteredProducts = storeProducts?.filter((product) => {
          return product.name
            .toLowerCase()
            .trim()
            ?.includes(productName?.toLowerCase().trim());
        });
        if (brandId) {
          filteredProducts = filteredProducts?.filter((product) => {
            return product.brand == brandId;
          });
        }
        if (brandProducts?.length !== filteredProducts?.length) {
          setBrandProducts(filteredProducts);
        }
      } else {
        if (!isFiltered) {
          if (brandId) {
            let filteredProducts = storeProducts?.filter((product) => {
              return product.brand === brandId;
            });
            if (brandProducts?.length != filteredProducts?.length) {
              setBrandProducts(filteredProducts);
            }
          } else {
            if (brandProducts?.length != storeProducts?.length) {
              setBrandProducts(storeProducts);
            }
          }
        }
      }
    }
  }, [productName, brandProducts]);

  const fetchBrands = async () => {
    try {
      let data = await getAllDataFromCollection("brands");
      setBrands(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      // let data = await getAllDataFromCollection("categories");
      let data = [...storeCategories];
      setLoading(false);
      setCategories(data);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchBrandDetails = async () => {
    try {
      setLoading(true);
      // let data = await getAllDataFromCollection("brands");
      let data = [...storeBrands];
      setLoading(false);
      let brand = data.find((el) => el.id === brandId);
      setBrandDetails(brand);
    } catch (error) {
      console.error(error);
    }
  };

  const getBrandProducts = async () => {
    try {
      setLoading(true);
      // let data = await getFilteredData("products", "brand", "==", brandId);
      let data = [
        ...storeProducts.filter((product) => product.brand == brandId),
      ];
      setLoading(false);
      setBrandProducts(data);
      setInitialData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const filterProducts = async () => {
    try {
      let tempProducts = [];
      let filteredProducts = storeProducts?.filter((product) => {
        return product.name
          .toLowerCase()
          .trim()
          ?.includes(productName?.toLowerCase().trim());
      });
      if (brandId) {
        filteredProducts = filteredProducts?.filter((product) => {
          return product.brand == brandId;
        });
      }
      if (!productName) {
        tempProducts = [...initialData];
        if (tempProducts?.length === 0) {
          tempProducts = [...initialData];
        }
      } else {
        let tempProducts = [...filteredProducts];
        if (tempProducts?.length === 0) {
          tempProducts = [...filteredProducts];
        }
      }
      setIsFiltered(true);
      setIsDrawerOpen(false);
      setLoading(true);
      if (filterByOptions?.sortBy) {
        if (filterByOptions?.sortBy === "name") {
          tempProducts = tempProducts?.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setLoading(false);
        } else if (filterByOptions?.sortBy === "price_low_to_high") {
          tempProducts.sort(
            (a, b) =>
              Number(a.unstitchedDiscountPrice) -
              Number(b.unstitchedDiscountPrice)
          );
        } else if (filterByOptions?.sortBy === "price_high_to_low") {
          tempProducts.sort(
            (a, b) =>
              Number(a.unstitchedDiscountPrice) -
              Number(b.unstitchedDiscountPrice)
          );
          tempProducts = tempProducts?.reverse();
        }
      }
      if (filterByOptions?.filterByCategory) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.category === filterByOptions.filterByCategory
        );
        setLoading(false);
        setBrandProducts(data);
        tempProducts = [...data];
        setFilteredProducts(data);
      }
      if (filterByOptions?.filterByBrand) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.brand === filterByOptions.filterByBrand
        );
        tempProducts = [...data];
        setLoading(false);
        setBrandProducts(data);
        setFilteredProducts(data);
      }
      if (filterByOptions?.filterBySize) {
        let data = [];
        if (
          !filterByOptions?.filterByCategory &&
          !filterByOptions?.filterByBrand
        ) {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        } else {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        }
        if (data) {
          tempProducts = [...data];
        }
      }
      setLoading(false);
      setBrandProducts([...tempProducts]);
      if (filterByOptions?.sortOrder === "descending") {
        setBrandProducts([...tempProducts.reverse()]);
      } else setBrandProducts([...tempProducts]);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [userDetails, setUserDetails] = useState({});

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        setUserDetails(userDetail);
        updateUser(userDetail);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="brand-products-list position-relative footer-container mt-[8rem] ml-[20px] mr-[20px] container mb-[48px]">
        {/* <SearchProducts /> */}
        {/* <p className='cart-header'>Brands</p> */}
        <div className="px-4">
          <div
            className="mt-2 popular-brand-card"
            style={{
              backgroundImage: `url(${brandDetails?.primary_image})`,
            }}
          >
            <div className="py-5 brand-title">
              <div>
                <h1 className="py-1 text-white fw-bold text-capitalize">
                  {brandDetails?.name}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-2 d-flex justify-content-between align-items-center">
            <h1 className="total-products-label">
              Showing {brandProducts?.length} products
            </h1>
            <div
              className="gap-1 px-4 py-1 cursor-pointer filter d-flex"
              onClick={() => setIsDrawerOpen(true)}
            >
              <img src={FilterIcon} alt="" width={14} height={12} />
              <span>Filter</span>
            </div>
          </div>
        </div>
        {loading == false && brandProducts?.length === 0 && (
          <div className="mt-4 text-center">
            <img src={NoProductsFoundPlaceHolder} />
          </div>
        )}
        <div className="px-3 m-0 mb-5 row g-3">
          {brandProducts &&
            brandProducts?.map((product, index) => (
              <div className="col-6 col-lg-3 col-xxl-2" key={product?.id}>
                <div className="text-decoration-none h-100">
                  <NewProductCard
                    product={product}
                    uid={getCurrentUser()?.uid || ""}
                    index={index}
                    categories={categories}
                    userDetail={userDetails}
                    fetchProducts={getBrandProducts}
                    fetchUserDetails={fetchUserDetails}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="mt-3"></div>
        <Drawer
          anchor={"bottom"}
          open={isDrawerOpen}
          className="mx-3 md:hidden"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="filter-drawer">
            <div className="mb-5 d-flex justify-content-between align-items-center">
              <h1 className="filters-label">Filters</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                className="cursor-pointer"
                width={32}
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="m-0 row">
              <div className="gap-4 p-0 col-12 d-flex justify-content-between">
                <div className="w-100">
                  <InputLabel id="select-standard-label">Sort by</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "black",
                      fontSize: "16px",
                    }}
                    inputProps={{ style: { border: "1px solid red" } }}
                    labelId="select-standard-label"
                    id="select-standard"
                    variant="standard"
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        sortBy: e.target.value,
                      }))
                    }
                    value={filterByOptions?.sortBy}
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"price_low_to_high"}>
                      Price Low to High
                    </MenuItem>
                    <MenuItem value={"price_high_to_low"}>
                      Price High to Low
                    </MenuItem>
                  </Select>
                </div>
                {filterByOptions?.sortBy !== "price_low_to_high" &&
                  filterByOptions?.sortBy !== "price_high_to_low" && (
                    <div className="w-100">
                      <InputLabel id="select-standard-label">
                        Sort Order
                      </InputLabel>
                      <Select
                        labelId="select-standard-label"
                        id="select-standard"
                        sx={{ width: "100%" }}
                        variant="standard"
                        IconComponent={() => (
                          <img src={DropdownArrowIcon} alt="" />
                        )}
                        onChange={(e) =>
                          setFilterByOptions((prev) => ({
                            ...prev,
                            sortOrder: e.target.value,
                          }))
                        }
                        value={filterByOptions?.sortOrder}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"ascending"}>Ascending</MenuItem>
                        <MenuItem value={"descending"}>Descending</MenuItem>
                      </Select>
                    </div>
                  )}
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Brand
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByBrand: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByBrand}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {brands?.map((brand) => (
                    <MenuItem
                      value={brand?.id}
                      key={brand?.id}
                      className="text-capitalize"
                    >
                      {brand?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByCategory: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories?.map((category) => (
                    <MenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="mt-5 mb-4 d-flex justify-center sm:justify-between align-items-center flex-wrap  gap-2">
              <button
                className="bg-gray-filled-btn"
                style={{ width: "160px", height: "40px", fontSize: "15px" }}
                onClick={() => {
                  setIsDrawerOpen(false);
                  setIsFiltered(false);
                  setFilterByOptions({});
                  getBrandProducts();
                }}
              >
                CLEAR ALL
              </button>
              <button
                className="bg-primary-filled-btn"
                style={{ height: "40px", fontSize: "15px" }}
                onClick={filterProducts}
              >
                APPLY FILTERS
              </button>
            </div>
          </div>
        </Drawer>
        <Drawer
          anchor={"left"}
          open={isDrawerOpen}
          className="mx-3 hidden md:block"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="filter-drawer">
            <div className="mb-5 d-flex justify-content-between align-items-center">
              <h1 className="filters-label">Filters</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                className="cursor-pointer"
                width={32}
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="m-0 row">
              <div className="gap-4 p-0 col-12 d-flex justify-content-between">
                <div className="w-100">
                  <InputLabel id="select-standard-label">Sort by</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "black",
                      fontSize: "16px",
                    }}
                    inputProps={{ style: { border: "1px solid red" } }}
                    labelId="select-standard-label"
                    id="select-standard"
                    variant="standard"
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        sortBy: e.target.value,
                      }))
                    }
                    value={filterByOptions?.sortBy}
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"price_low_to_high"}>
                      Price Low to High
                    </MenuItem>
                    <MenuItem value={"price_high_to_low"}>
                      Price High to Low
                    </MenuItem>
                  </Select>
                </div>
                {filterByOptions?.sortBy !== "price_low_to_high" &&
                  filterByOptions?.sortBy !== "price_high_to_low" && (
                    <div className="w-100">
                      <InputLabel id="select-standard-label">
                        Sort Order
                      </InputLabel>
                      <Select
                        labelId="select-standard-label"
                        id="select-standard"
                        sx={{ width: "100%" }}
                        variant="standard"
                        IconComponent={() => (
                          <img src={DropdownArrowIcon} alt="" />
                        )}
                        onChange={(e) =>
                          setFilterByOptions((prev) => ({
                            ...prev,
                            sortOrder: e.target.value,
                          }))
                        }
                        value={filterByOptions?.sortOrder}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"ascending"}>Ascending</MenuItem>
                        <MenuItem value={"descending"}>Descending</MenuItem>
                      </Select>
                    </div>
                  )}
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Brand
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByBrand: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByBrand}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {brands?.map((brand) => (
                    <MenuItem
                      value={brand?.id}
                      key={brand?.id}
                      className="text-capitalize"
                    >
                      {brand?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByCategory: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories?.map((category) => (
                    <MenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="mt-5 mb-4 d-flex justify-center sm:justify-between align-items-center flex-wrap  gap-2">
              <button
                className="bg-gray-filled-btn"
                style={{ width: "160px", height: "40px", fontSize: "15px" }}
                onClick={() => {
                  setIsDrawerOpen(false);
                  setIsFiltered(false);
                  setFilterByOptions({});
                  getBrandProducts();
                  // navigate("/brands");
                }}
              >
                CLEAR ALL
              </button>
              <button
                className="bg-primary-filled-btn"
                style={{ height: "40px", fontSize: "15px" }}
                onClick={filterProducts}
              >
                APPLY FILTERS
              </button>
            </div>
          </div>
        </Drawer>
      </div>
    </Spin>
  );
};

export default BrandProducts;
