import React, { useEffect, useState } from "react";
import SearchProducts from "../components/SearchProducts";
import Footer from "../layout/Footer";
import { Link, useLocation } from "react-router-dom";
import { getAllDataFromCollection } from "../utils";
import { Spin } from "antd";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";

const BrandsList = () => {
  const [brandsList, setBrandsList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const { search: locationSearch } = useLocation();
  const queryParams = new URLSearchParams(locationSearch);
  const brandName = queryParams.get("name");

  useEffect(() => {
    getBrandsList();
  }, []);

  useEffect(() => {
    if (initialData.length > 0) {
      if (brandName) {
        let filteredBrands = initialData.filter((brand) => {
          return brand.name
            .toLowerCase()
            .trim()
            ?.includes(brandName.toLowerCase().trim());
        });
        if (brandsList?.length !== filteredBrands?.length) {
          setBrandsList(filteredBrands);
        }
      } else {
        setBrandsList(initialData);
      }
    }
  }, [brandName, brandsList]);

  const getBrandsList = async () => {
    try {
      setLoading(true);
      let data = await getAllDataFromCollection("brands");
      setLoading(false);
      if (data) {
        setBrandsList(data);
        setInitialData(data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const searchProducts = async (searchText = "") => {
    if (searchText) {
      const filteredProducts = initialData?.filter((brand) =>
        brand?.name?.toLowerCase()?.includes(searchText?.toLowerCase()?.trim())
      );
      setBrandsList(filteredProducts);
    } else {
      setBrandsList([...initialData]);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <Spin spinning={loading}>
      <div className="brands-list position-relative footer-container">
        <SearchProducts
          search={search}
          setSearch={setSearch}
          searchProducts={searchProducts}
        />
        {loading === false && brandsList?.length === 0 && (
          <div className="text-center mt-4">
            <img src={NoProductsFoundPlaceHolder} />
          </div>
        )}
        <div className="row m-0 px-2 g-3 mb-5">
          {brandsList &&
            brandsList?.map((brand) => (
              <div className="col-6 col-md-3" key={brand.id}>
                <Link to={`/brand-products/${brand.id}`}>
                  <div
                    className="popular-brand-card"
                    style={{
                      backgroundImage: `url(${brand?.primary_image})`,
                    }}
                  >
                    <div className="brand-title py-5">
                      <div>
                        <h1 className="fw-bold text-white py-1 text-capitalize">
                          {brand.name}
                        </h1>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <div className="mt-3"></div>
        <Footer />
      </div>
    </Spin>
  );
};

export default BrandsList;
