import { useEffect, useState } from "react";
import "./App.css";
import "./style.css";
import "./style.scss";
import "./index.css";
import Home from "./pages/home/Home";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ProductsList from "./pages/ProductsList";
import NewProductsList from "./pages/NewProductsList";
import TermsAndConditions from "./pages/TermsAndConditions";
import BrandsList from "./pages/BrandsList";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import ForgotPassword from "./pages/ForgotPassword";
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import CardPayment from "./pages/CardPayment";
import ShippingAddress from "./pages/ShippingAddress";
import ConfirmOrder from "./pages/ConfirmOrder";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import BrandProducts from "./pages/BrandProducts";
import OrderHistory from "./pages/OrderHistory";
import PreviousOrder from "./pages/PreviousOrder";
import DiscountedProductsList from "./pages/DiscountedProducts";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import CategoriesList from "./pages/CategoriesList";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import firebase from "firebase";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import DeliveryInformation from "./pages/DeliveryInformation";
import DeliveryPolicy from "./pages/DeliveryPolicy";
import BottomNavigation from "./components/BottomNavigation";
import PlacedOrder from "./pages/PlacedOrder";
import OrderDetail from "./pages/OrderDetail";
import WishList from "./pages/WishList";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfileSetting from "./pages/ProfileSetting";
import {
  useBrandsStore,
  useCategoriesStore,
  useProductsStore,
  useUserStore,
} from "./app/store";
import EditProfile from "./layout/EditProfile";
import SaleProducts from "./pages/home/SaleProducts";
import { getFilteredData } from "./utils";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY);

function App() {
  const navigate = useNavigate();
  const { updateProducts } = useProductsStore();
  const { updateCategories } = useCategoriesStore();
  const { updateBrands } = useBrandsStore();
  const { user, updateUser } = useUserStore();
  // const { currentUser } = useAuth();
  const location = useLocation();
  const isUserLoggedIn = firebase.auth().currentUser?.uid ? true : false;

  useEffect(() => {
    fetchProductsList();
    fetchCategoriesList();
    fetchBrandsList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const fetchProductsList = () => {
    firebase
      .firestore()
      .collection("products")
      .onSnapshot((snapshot) => {
        let snapshotProducts = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("snapshot products:    ", snapshotProducts);
        snapshotProducts.sort((a, b) => {
          const dateA = new Date(
            a.createdDate.seconds * 1000 + a.createdDate.nanoseconds / 1000000
          );
          const dateB = new Date(
            b.createdDate.seconds * 1000 + b.createdDate.nanoseconds / 1000000
          );
          return dateB - dateA; // Sort from newest to oldest, for oldest to newest, change to dateA - dateB
        });

        updateProducts(snapshotProducts);
      });
  };

  const fetchCategoriesList = () => {
    firebase
      .firestore()
      .collection("categories")
      .onSnapshot((snapshot) => {
        let snapshotCategories = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("snapshot categories:    ", snapshotCategories);
        updateCategories(snapshotCategories);
      });
  };

  const fetchBrandsList = () => {
    firebase
      .firestore()
      .collection("brands")
      .onSnapshot((snapshot) => {
        let snapshotBrands = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("snapshot brands:    ", snapshotBrands);
        updateBrands(snapshotBrands);
      });
  };

  useEffect(() => {
    if (!user) {
      fetchUserDetails();
    }
  }, [location?.pathname, user]);

  const fetchUserDetails = async () => {
    try {
      let currentUser = firebase.auth().currentUser;
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        updateUser(userDetail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
    };

    initializeStripe();
  }, []);

  if (isUserLoggedIn) {
    if (location.pathname === "/register" || location.pathname === "/login") {
      navigate("/");
      return;
    }
  }

  return (
    <Elements stripe={stripe}>
      {/* <Elements stripe={stripePromise}> */}
      <AuthProvider>
        <div className="App main-container">
          <Header />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/products" exact element={<ProductsList />} />
            <Route
              path="/special-offer-products"
              exact
              element={<ProductsList />}
            />
            <Route
              path="/delivery-information"
              exact
              element={<DeliveryInformation />}
            />
            <Route
              path="/products/:categoryId"
              exact
              element={<ProductsList />}
            />
            <Route
              path="/products/new/:categoryId"
              exact
              element={<ProductsList />}
            />
            <Route
              path="/discounted-products"
              exact
              element={<DiscountedProductsList />}
            />
            <Route path="/delivery-policy" exact element={<DeliveryPolicy />} />
            <Route path="/wish-list" exact element={<WishList />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/order/success" exact element={<CheckoutSuccess />} />
            <Route path="/cart" exact element={<Cart />} />
            <Route path="/checkout" exact element={<Checkout />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route
              path="/shipping-address"
              exact
              element={<ShippingAddress />}
            />
            <Route
              path="/shipping-address/:id"
              exact
              element={<ShippingAddress />}
            />
            <Route path="/reorder/:orderId" exact element={<ConfirmOrder />} />
            <Route path="/confirm-order" exact element={<ConfirmOrder />} />
            <Route path="/card-payment" exact element={<CardPayment />} />
            <Route path="/products/sale" exact element={<SaleProducts />} />
            <Route path="/card-payment/:id" exact element={<CardPayment />} />
            <Route path="/new-products" exact element={<NewProductsList />} />
            <Route
              path="/new-products/:brandId"
              exact
              element={<NewProductsList />}
            />
            <Route
              path="/brand-products/:brandId"
              exact
              element={<BrandProducts />}
            />
            <Route path="/register" exact element={<Registration />} />
            <Route
              path="/product-details/:productId"
              exact
              element={<ProductDetails />}
            />
            <Route
              path="/order-details/:orderId"
              exact
              element={<OrderDetail />}
            />
            <Route path="/reset-password" exact element={<ForgotPassword />} />
            <Route
              path="/profile-settings"
              exact
              element={<ProfileSetting />}
            />
            <Route path="/orders-history" exact element={<OrderHistory />} />
            <Route path="/placed-orders" exact element={<PlacedOrder />} />
            <Route
              path="/previous-order/:orderId"
              exact
              element={<PreviousOrder />}
            />
            <Route
              path="/terms-and-conditions"
              exact
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policies" exact element={<PrivacyPolicy />} />
            <Route
              path="/refund-and-exchange-policy"
              exact
              element={<RefundPolicy />}
            />
            <Route path="/basic-info" exact element={<EditProfile />} />
            <Route path="/brands" exact element={<BrandsList />} />
            <Route path="/categories" exact element={<CategoriesList />} />
          </Routes>
          <Footer />
          <div className="hidden max-sm:inline">
            <BottomNavigation />
          </div>
        </div>
      </AuthProvider>
    </Elements>
  );
}

export default App;
