import { IconButton, InputAdornment } from "@mui/material";
import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFilteredData, updateDoc } from "../utils";
import { useAuth } from "../contexts/AuthContext";
import { Spin, notification } from "antd";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase";

const EditProfile = ({ handleClose, setIsProfileEdit, setOpen }) => {
  const navigate = useNavigate();
  const { signup, currentUser, login, updateEmail, updatePassword } = useAuth();
  const [isLoading, setIsLoading] = useState(null);
  const [showPassowrd, setShowPassowrd] = useState(false);
  const [showCurrentPassowrd, setShowCurrentPassowrd] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassowrd] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    current_password: "",
    confirm_password: "",
  });

  useLayoutEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCurrentUserDetails();
  }, []);

  const fetchCurrentUserDetails = async () => {
    try {
      setIsLoading(true);
      let data = await getFilteredData(
        "users",
        "email",
        "==",
        currentUser?.email
      );
      setUserDetails(data[0]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userDetails) {
      setUserData((prev) => ({
        ...prev,
        email: userDetails?.email,
        name: `${userDetails?.first_name} ${userDetails?.last_name}`,
      }));
    }
  }, [userDetails]);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);

  const handleChange = (e) => {
    setUserData((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
  };

  const handleUserRegistration = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      if (userData?.password) {
        if (!userData?.confirm_password) {
          toast.error("Please enter current password.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        if (userData?.password !== userData?.confirm_password) {
          toast.error("Password/Confirm Password not matched.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsLoading(false);
          return;
        } else {
          const response = await login(
            userData?.email,
            userData?.current_password
          );
          if (response) {
            await updatePassword(userData?.password);
            notification.success({ message: "Password changed successfully" });
          }
        }
      }
      if (userData?.email) {
        await updateEmail(userData?.email);
      }

      const nameSplit = userData?.name?.split(" ");
      const firstName = nameSplit?.length > 0 ? nameSplit[0] : "";
      const lastName = nameSplit?.length > 1 ? nameSplit[1] : "";
      console.log("userDetails?.id :: ", userDetails?.id);
      await updateDoc("users", userDetails?.id, {
        first_name: firstName,
        last_name: lastName,
        email: userData?.email,
      });
      // navigate('/');
      setIsLoading(false);
      setIsProfileEdit(false);
      setOpen(false);
      toast.success("Profile Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error(error.message || "Error!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="px-3 w-full sm:w-1/2 md:w-1/3 mx-auto mb-5">
        <div className="text-center mt-[7rem] flex items-center">
          <div
            className="mr-4 cursor-pointer new-product-button"
            onClick={() => navigate("/profile-settings")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_3214_3956)">
                <path
                  d="M15.8332 9.1666H6.5249L10.5916 5.09993C10.9166 4.77494 10.9166 4.2416 10.5916 3.9166C10.2666 3.5916 9.74157 3.5916 9.41657 3.9166L3.9249 9.40827C3.5999 9.73327 3.5999 10.2583 3.9249 10.5833L9.41657 16.0749C9.74157 16.3999 10.2666 16.3999 10.5916 16.0749C10.9166 15.7499 10.9166 15.2249 10.5916 14.8999L6.5249 10.8333H15.8332C16.2916 10.8333 16.6666 10.4583 16.6666 9.99994C16.6666 9.5416 16.2916 9.1666 15.8332 9.1666Z"
                  fill="#333333"
                />
              </g>
              <defs>
                <clipPath id="clip0_3214_3956">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <h1 className="cart-header text-start">Edit Profile</h1>
        </div>

        <form className="mt-[36px]" onSubmit={handleUserRegistration}>
          <div class="w-full mt-3">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Name
            </label>
            <input
              class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              name="name"
              value={userData?.name || ""}
              onChange={handleChange}
              variant="outlined"
              placeholder="Name"
              required
            />
          </div>
          <div class="w-full mt-3">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email
            </label>
            <input
              class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-email"
              type="email"
              disabled={true}
              name="email"
              variant="outlined"
              placeholder="Email"
              value={userData?.email || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div class="w-full mt-3 ">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-passowrd"
            >
              Current Password
            </label>
            <div className="relative flex items-center justify-end">
              <input
                class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-passowrd"
                name="current_password"
                placeholder="Current Password"
                value={userData?.current_password}
                onChange={handleChange}
                required={userData?.password ? true : false}
                type={showCurrentPassowrd ? "text" : "password"}
              />
              <div class="absolute mr-2 w-10">
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowCurrentPassowrd((prev) => !prev)}
                    // onMouseDown={() => setshowCurrentPassowrd((prev) => !prev)}
                  >
                    {showCurrentPassowrd === true ? (
                      <VisibilityOffIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>
            </div>
          </div>
          <div class="w-full mt-3 ">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-passowrd"
            >
              Password
            </label>
            <div className="relative flex items-center justify-end">
              <input
                class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-passowrd"
                name="password"
                placeholder="Password"
                value={userData?.password}
                onChange={handleChange}
                // required
                type={showPassowrd ? "text" : "password"}
              />
              <div class="absolute mr-2 w-10">
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassowrd((prev) => !prev)}
                    // onMouseDown={() => setShowPassowrd((prev) => !prev)}
                  >
                    {showPassowrd === true ? (
                      <VisibilityOffIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>
            </div>
          </div>
          <div class="w-full mt-3 ">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-confirm-passowrd"
            >
              Confirm Password
            </label>
            <div className="relative flex items-center justify-end">
              <input
                class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-confirm-passowrd"
                name="confirm_password"
                placeholder="Password"
                value={userData?.confirm_password}
                onChange={handleChange}
                // required
                type={showConfirmPassowrd ? "text" : "password"}
              />
              <div class="absolute mr-2 w-10">
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassowrd((prev) => !prev)}
                    // onMouseDown={() => setShowPassowrd((prev) => !prev)}
                  >
                    {showConfirmPassowrd === true ? (
                      <VisibilityOffIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>
            </div>
          </div>
          <div className="flex mt-[48px]">
            <div
              onClick={() => {
                navigate("/profile-settings");
              }}
              class="new-product-button-auth flex-1 mr-2  p-3 text-black cursor-pointer"
            >
              Cancel
            </div>
            <div
              class="new-product-button-auth flex-1  bg-black text-white p-3 cursor-pointer"
              // onClick={handleUserRegistration}
            >
              <button className="w-full text-white" type="submit">
                Save Changes
              </button>
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
    </Spin>
  );
};

export default EditProfile;
