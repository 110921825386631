import { InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import firebase from "firebase/app";
import "firebase/functions";
import Swal from "sweetalert2";
import { Button } from "antd";

const ContactUs = () => {
  const [contactUsFormDetail, setContactUsFormDetail] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "",
  });

  const [loading, setloading] = useState(false);

  const sendContactUsEmail = firebase
    .functions()
    .httpsCallable("sendContactUsEmail");

  const handleSendEmail = async (e) => {
    setloading(true);
    e.preventDefault();
    try {
      sendContactUsEmail({
        email: contactUsFormDetail.email,
        message: contactUsFormDetail.message,
        name: contactUsFormDetail.name,
        phone: contactUsFormDetail.phone,
        subject: contactUsFormDetail.subject,
      }).then((res) => {
        setloading(false);
        Swal.fire({
          title: "Success",
          text: "Email sent succssfully.",
          icon: "success",
          timer: 2000,
        });
        setContactUsFormDetail({
          name: "",
          email: "",
          phone: "",
          message: "",
          subject: "",
        });
      }).catch((err) => {
        setloading(false);
      });
    } catch (error) {
      setloading(false);
      console.error(error);
    }
  };

  const onChangeValue = (value) => {
    setContactUsFormDetail({ ...contactUsFormDetail, ...value });
  };

  console.log({ loading });

  return (
    <div className="mt-[8rem] mb-[48px] w-full sm:w-1/2 mx-auto">
      <h1 className="main-heading">Contact us</h1>
      <div style={{ marginTop: "48px" }} onSubmit={handleSendEmail}>
        <form className="w-full">
          <div className="flex flex-col 2xl:flex-row gap-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 input-label-text text-start"
                for="grid-first-name"
              >
                Name
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 bg-white border appearance-none rounded-2xl focus:outline-none"
                id="grid-first-name"
                type="text"
                placeholder="Enter name"
                required
                onChange={(e) => onChangeValue({ name: e.target.value })}
                value={contactUsFormDetail?.name}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 text-start input-label-text"
                for="grid-last-name"
              >
                Email
              </label>
              <input
                className="block px-4 py-3 leading-tight text-gray-700 border border-gray-200 appearance-none rounded-2xl focus:outline-none focus:bg-white focus:border-gray-500 w-full"
                id="grid-last-name"
                type="email"
                required
                placeholder="Enter email"
                onChange={(e) => onChangeValue({ email: e.target.value })}
                value={contactUsFormDetail?.email}
              />
            </div>
            <div className="w-full px-3 max-md:py-3 mb-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 text-start input-label-text"
                for="grid-last-name"
              >
                Contact Number (Optional)
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 appearance-none rounded-2xl focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="XXXXX"
                onChange={(e) => onChangeValue({ phone: e.target.value })}
                value={contactUsFormDetail?.phone}
              />
            </div>
          </div>
          <div className="w-full px-3 mb-6">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 text-start input-label-text"
              for="grid-last-name"
            >
              Subject
            </label>
            <input
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 appearance-none rounded-2xl focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              required
              type="text"
              placeholder="Write something here..."
              onChange={(e) => onChangeValue({ subject: e.target.value })}
              value={contactUsFormDetail?.subject}
            />
          </div>

          <div className="w-full px-3 mb-6">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 text-start input-label-text"
              for="grid-last-name"
            >
              Message
            </label>
            <textarea
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 appearance-none rounded-2xl focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              rows={5}
              type="text"
              required
              placeholder="Write something here..."
              onChange={(e) => onChangeValue({ message: e.target.value })}
              value={contactUsFormDetail?.message}
            />
          </div>

          <div className="flex justify-center mt-[48px]">
            <div
              className="cursor-pointer cart-order-btn"
              // onClick={handleSendEmail}
            >
              <Button
                type="submit"
                loading={loading}
                className="text-white"
                disabled={loading}
              >
                Send Message
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
