import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Drawer } from "@mui/material";
import { sizeGrid } from "../constants/size-grid";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Link } from "react-router-dom";

export default function SizeChartDialog({ open, setOpen }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { width } = useWindowDimensions();
  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ borderRadius: "10px" }}
        className="hidden max-lg:inline"
      >
        <div className="p-2 mt-[48px]">
          <h1 className="main-heading">Size Chart</h1>
          <div
            className={`mt-[48px] flex border rounded-[24px] border-[#AAA] border-solid border-1 overflow-auto`}
          >
            {sizeGrid.map((size, i) => (
              <div
                className={`${
                  i !== 8 && "border-r"
                }  border-[#AAA] border-solid border-1 flex-1`}
              >
                <div className="bg-black h-[51px] flex justify-center items-center">
                  <p
                    className={`${
                      width < 700 ? "p-1 text-[10px]" : "p-3"
                    } text-white `}
                  >
                    {size.heading}
                  </p>
                </div>

                {size.values.map((val, index) => (
                  <div
                    className={`bg-white ${
                      index !== 4 &&
                      "border-b border-[#AAA] border-solid border-1"
                    } flex justify-center items-center`}
                  >
                    <p
                      className={`${
                        width < 700 ? "p-1 py-2 text-[10px]" : "p-3"
                      } text-black `}
                    >
                      {val}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="mt-[48px]">
            <p className="text-center product-detail-bredcrum">
              Please note we stitch to our standard size chart which is in
              ‘inches’ and the measurements displayed are one sided ‘clothing’
              measurements.
            </p>
            <p className="mt-4 text-center product-detail-bredcrum">
              Customised stitching option is available and when providing
              measurements please kindly send measurements directly from
              clothing and not from ‘body’.
            </p>
            <p className="mt-4 text-center product-detail-bredcrum">
              Requests for customised stitching can be added to order notes at
              checkout, or directly messaged to us on Instagram.
            </p>
          </div>
          <div className="mt-[48px] flex flex-col gap-3 mb-4">
            <Link
              to="/"
              onClick={handleClose}
              class="new-product-button flex-1 mr-2  p-3  bg-black text-white cursor-pointer"
            >
              Return to Homepage
            </Link>
            <div
              class="new-product-button flex-1 mr-2  text-black  p-3 cursor-pointer"
              onClick={handleClose}
            >
              Close
            </div>
          </div>
        </div>
      </Drawer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="hidden lg:inline "
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
      >
        <DialogContent className="">
          <h1 className="main-heading">Size Chart</h1>
          <div
            className={`mt-[48px] flex border rounded-[24px] border-[#AAA] border-solid border-1 overflow-auto`}
          >
            {sizeGrid.map((size, i) => (
              <div
                className={`${
                  i !== 8 && "border-r"
                }  border-[#AAA] border-solid border-1 flex-1`}
              >
                <div className="bg-black h-[51px] flex justify-center items-center">
                  <p
                    className={`${
                      width < 700 ? "p-1 text-[10px]" : "p-3"
                    } text-white `}
                  >
                    {size.heading}
                  </p>
                </div>

                {size.values.map((val, index) => (
                  <div
                    className={`bg-white ${
                      index !== 4 &&
                      "border-b border-[#AAA] border-solid border-1"
                    } flex justify-center items-center`}
                  >
                    <p
                      className={`${
                        width < 700 ? "p-1 py-2 text-[10px]" : "p-3"
                      } text-black `}
                    >
                      {val}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="mt-[48px]">
            <p className="text-center product-detail-bredcrum">
              Please note we stitch to our standard size chart which is in
              ‘inches’ and the measurements displayed are one sided ‘clothing’
              measurements.
            </p>
            <p className="mt-4 text-center product-detail-bredcrum">
              Customised stitching option is available and when providing
              measurements please kindly send measurements directly from
              clothing and not from ‘body’.
            </p>
            <p className="mt-4 text-center product-detail-bredcrum">
              Requests for customised stitching can be added to order notes at
              checkout, or directly messaged to us on Instagram.
            </p>
          </div>
          <div className="mt-[48px] flex">
            <div
              class="new-product-button flex-1 mr-2  text-black  p-3 cursor-pointer"
              onClick={handleClose}
            >
              Close
            </div>

            <Link
              to="/"
              onClick={handleClose}
              class="new-product-button flex-1 mr-2  p-3  bg-black text-white cursor-pointer"
            >
              Return to Homepage
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
