import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import CartProduct from "../components/CartProduct"
import EditCartIcon from "../assets/imgs/edit-cart-icon.svg"
import { useAuth } from "../contexts/AuthContext"
import firebase from "firebase"
import { getFilteredData, fetchSingleDocument } from "../utils"
import { Spin } from "antd"
// import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useStripe, useElements } from "@stripe/react-stripe-js"

const ConfirmOrder = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [orderDetail, setOrderDetail] = useState({})
  const [cart, setCart] = useState([])
  const { orderId } = useParams()

  const createStripeCheckout = firebase
    .functions()
    .httpsCallable("createStripeCheckout")

  const cartProductsChargesDetail = JSON.parse(
    localStorage.getItem("cartProductsChargesDetail")
  )

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    fetchUserDetails()
    if (orderId) {
      fetchOrderDetails()
    }
  }, [])
  

  const fetchOrderDetails = async () => {
    try {
      setLoading(true)
      const order = await fetchSingleDocument("orders", orderId)
      setOrderDetail(order)
      setCart(order?.cart)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchUserDetails = async () => {
    try {
      setLoading(true)
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        )
        let userDetail = data[0]
        setUserDetails(userDetail)
        console.log({ userDetail })
        setCart(userDetail?.cart)
      } else {
        const userShippingAddress = JSON.parse(
          localStorage.getItem("shippingAddress")
        )
        setUserDetails((prev) => ({
          ...prev,
          shipping_details: userShippingAddress,
        }))
        const localCart = JSON.parse(localStorage.getItem("cart"))
        setCart(localCart)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }
      setLoading(true)
      const lineItems = cart?.map((product) => {
        return {
          price_data: {
            currency: "GBP",
            product_data: {
              name: product.name.toUpperCase(),
              description: product.description,
              images: product.image,
            },
            unit_amount: Number(product?.prod_price) * 100,
          },
          quantity: Number(product?.quantity),
        }
      })

      if (isNaN(userDetails?.payable_amount)) {
        userDetails.payable_amount = +cart
          ?.reduce(
            (sum, { prod_price, quantity }) =>
              sum + Number(prod_price) * quantity,
            0
          )
          ?.toFixed(2)
      }

      // Callable firebase cloud function
      createStripeCheckout({
        lineItems: lineItems,
        user_details: userDetails,
      })
        .then(async (res) => {
          setLoading(false)
          const session = res.data
          stripe
            .redirectToCheckout({ sessionId: session.id })
            .then((response) => {})
        })
        .catch((err) => console.error(err))
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  return (
    <>
      <Spin spinning={loading}>
        <div className="confirm-order px-4">
          <div
            className="position-fixed cart-header w-100 py-4 d-flex gap-4 align-items-center bg-white top-0"
            style={{ zIndex: 1 }}
          >
            <i
              className="fa fa-chevron-left cursor-pointer float-start"
              style={{ fontSize: "1.2rem" }}
              onClick={() => navigate(-1)}
            ></i>
            <h1>Confirm Order</h1>  
          </div>
          <div style={{ marginTop: "5rem" }}>
            <div className="cart-products">
              {cart?.map((product, index) => (
                <div
                  onClick={() => setIsDrawerOpen(true)}
                  className="card-product-wrapper"
                  key={product?.id}
                >
                  <CartProduct product={product} />
                </div>
              ))}
            </div>
          </div>
          <div className="address-information p-2 mt-4 text-left">
            <div className="d-flex justify-content-between align-items-center">
              <p className="title">Address Information:</p>
              <Link to={"/shipping-address"}>
                <img src={EditCartIcon} alt="" height={16} width={16} />
              </Link>
            </div>
            {userDetails?.shipping_details && (
              <p className="fs-14 mb-1 text-capitalize">
                {userDetails?.shipping_details?.address_line_1 +
                  ", " +
                  userDetails?.shipping_details?.address_line_2 +
                  ", " +
                  // userDetails?.shipping_details?.details +
                  // ", " +
                  userDetails?.shipping_details?.city +
                  ", " +
                  // userDetails?.shipping_details?.state +
                  ", " +
                  userDetails?.shipping_details?.country +
                  ", " +
                  userDetails?.shipping_details?.zip_code}
              </p>
            )}{" "}
          </div>
          <hr className="gradient-horizontal-line" />
          <div className="total-amount d-flex justify-content-between align-items-center">
            <p className="label">Total Amount:</p>
            <p>
              £
              {cart
                ?.reduce(
                  (sum, { prod_price, quantity }) =>
                    sum + Number(prod_price) * quantity,
                  0
                )
                ?.toFixed(2) || orderDetail?.totalAmount}
            </p>
          </div>
          <hr className="gradient-horizontal-line" />
          <div
            className="payable-information d-flex justify-content-between align-items-center"
            style={{ marginBottom: "6rem" }}
          >
            <p className="ff-roboto">Payable</p>
            <p className="ff-roboto text-black">
              £
              {cart
                ?.reduce(
                  (sum, { prod_price, quantity }) =>
                    sum + Number(prod_price) * quantity,
                  0
                )
                ?.toFixed(2) || orderDetail?.totalAmount}
            </p>
          </div>
          <div className="checkout-footer py-4 bg-white">
            <button
              className="bg-primary-filled-btn"
              style={{ width: "335px", height: "40px" }}
              onClick={() => handleSubmit()}
              disabled={loading === true}
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      </Spin>
    </>
  )
}

export default ConfirmOrder
