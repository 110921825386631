const DeliveryAddress = ({
  addressState,
  addressDispatch,
  address,
  setAddress,
}) => {
  const onChangeValue = (action, value) => {
    addressDispatch({
      type: action,
      payload: value,
    });
  };
  return (
    // <form class="w-full">
    <div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            class="input-label-text block tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
            for="full-name"
          >
            Full Name
          </label>
          <input
            class="appearance-none block w-full text-gray-700 border rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
            id="full-name"
            type="text"
            required
            name="full-name"
            placeholder="Enter name"
            onChange={(e) => 
              setAddress((prev) => ({ ...prev, name: e.target.value }))
            }
            value={address?.name}
          />
        </div>
        <div class="w-full md:w-1/3 px-3">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="phone-number"
          >
            Phone Number
          </label>
          <input
            class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="phone-number"
            type="tel"
            required
            name="phone-number"
            placeholder="Enter number"
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, phone: e.target.value }))
            }
            value={address?.phone}
          />
        </div>
        <div class="w-full md:w-1/3 px-3">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="email"
          >
            Email Address
          </label>
          <input
            class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="email"
            type="email"
            required
            placeholder="example@mail.com"
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, email: e.target.value }))
            }
            value={address?.email}
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            class="block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
            for="address"
          >
            Street Address
          </label>
          <input
            class="appearance-none block w-full text-gray-700 border rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="address"
            type="text"
            placeholder="Hourse Number & Street Name"
            onChange={(e) =>
              setAddress((prev) => ({
                ...prev,
                address_line_1: e.target.value,
              }))
            }
            value={address?.address_line_1}
            required
            name="address"
          />
        </div>
        <div class="w-full md:w-1/3 px-3">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="address-2"
          >
            Street Address 2 (Optional)
          </label>
          <input
            class="appearance-none block w-full text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="address-2"
            type="text"
            name="address-2"
            placeholder="Apartment, suite, unit, etc."
            onChange={(e) =>
              setAddress((prev) => ({
                ...prev,
                address_line_2: e.target.value,
              }))
            }
            value={address?.address_line_2}
          />
        </div>
        <div class="w-full md:w-1/3 px-3">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="city"
          >
            Town/City
          </label>
          <input
            class="appearance-none block w-full text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="city"
            required
            name="city"
            type="text"
            placeholder="Enter town/city"
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, city: e.target.value }))
            }
            value={address?.city}
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            class="block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
            for="state"
          >
            State
          </label>
          <input
            class="appearance-none block w-full text-gray-700 border rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="state"
            type="text"
            required
            name="state"
            placeholder="Enter state"
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, state: e.target.value }))
            }
            value={address?.state}
          />
        </div>
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="country"
          >
            Country
          </label>
          <div class="relative">
            <select
              class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="country"
              name="country"
              required
              onChange={(e) =>
                setAddress((prev) => ({ ...prev, country: e.target.value }))
              }
              value={address?.country}
            >
              <option value="" disabled selected>
                Select--
              </option>
              <option value="US">US</option>
              <option value="UK">UK</option>
              <option value="CA">Canada</option>
              <option value="AU">Australia</option>
              <option value="EU">Europe</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/3 px-3">
          <label
            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="postcode"
          >
            Postcode
          </label>
          <input
            class="appearance-none block w-full text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="postcode"
            type="text"
            name="postcode"
            placeholder="#####"
            onChange={(e) =>
              setAddress((prev) => ({ ...prev, zip_code: e.target.value }))
            }
            value={address?.zip_code}
          />
        </div>
      </div>
    </div>
    // </form>
  );
};

export default DeliveryAddress;
