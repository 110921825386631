import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CartProduct from "../components/CartProduct";
import { fetchSingleDocument } from "../utils";
import { useAuth } from "../contexts/AuthContext";
import { Spin } from "antd";

const PreviousOrder = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(null);
  const [payableAmount, setPayableAmount] = useState(0);
  // const animationContainer = React.createRef()
  const { orderId } = useParams();
  useEffect(() => {
    fetchOrderDetail();
    // var animation = lottie.loadAnimation({
    //   container: animationContainer,
    //   path: "../assets/order-confirmed-animation.json",
    //   renderer: "svg",
    //   loop: true,
    //   autoplay: true,
    //   name: "Demo Animation",
    // })
  }, []);

  const fetchOrderDetail = async () => {
    try {
      setLoading(true);
      const data = await fetchSingleDocument("orders", orderId);
      console.log("datadata order :: ", data);
      setOrderDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="confirm-order lg:ml-[120px] lg:mr-[120px] mt-[8rem] ml-[20px] mr-[20px] mb-[48px]">
        <p className="flex items-center cart-header text-start">
          Order Details
        </p>

        <div style={{ marginTop: "48px" }}>
          <div className="flex flex-wrap gap-6 max-sm:flex-col">
            <div className="grid grid-cols-2 max-lg:grid-cols-1 w-[60%] max-sm:w-full mb-5">
              {orderDetail?.cart?.map((product, index) => (
                <div
                  className="card-product-wrapper2"
                  key={product?.id}
                >
                  <CartProduct
                    product={product}
                    handleRemoveCartProduct={() => {}}
                    isCheckout
                    isShowDelete
                  />
                </div>
              ))}
            </div>

          </div>
            <div className="flex-1 ">
              <div className="payment-order-detail-container">
                <div className="w-full border-b border-[#AAA] border-solid border-1 text-start pb-2 flex justify-between">
                  <h1 className="payment-order-detail-heading">
                    Order Details
                  </h1>
                  <span className="status-text-details capitalize">
                    {orderDetail?.orderStatus || ""}
                  </span>
                </div>

                <div className="flex flex-col w-full text-start">
                  <p className="mb-1 radio-text">
                    Products:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.cart?.length || "-"}
                    </span>
                  </p>
                  <p className="mb-1 radio-text">
                    To:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.first_name || "-"}{" "}
                      {orderDetail?.last_name || ""}
                    </span>
                  </p>
                  <p className="mb-1 radio-text">
                    Phone:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.shipping_details?.phone || "-"}
                    </span>
                  </p>
                  <p className="mb-1 radio-text">
                    Email:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.email || ""}
                    </span>
                  </p>
                  <p className="mb-1 radio-text">
                    Delivery Address:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.shipping_details?.address_line_1},{" "}
                      {orderDetail?.shipping_details?.city},{" "}
                      {orderDetail?.shipping_details?.state}
                    </span>
                  </p>
                  <p className="mb-1 radio-text">
                    Postal Code:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.shipping_details?.zip_code || "-"}
                    </span>
                  </p>
                  <p className=" radio-text">
                    Order Notes:{" "}
                    <span className=" text-[#AAA]">
                      {orderDetail?.orderNotes || ""}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col w-full border-b border-t border-[#AAA] border-solid border-1 pt-2 pb-2">
                  <div className="flex justify-between">
                    <p className="radio-text">Total Amount:</p>
                    <p className="radio-text">
                      £{" "}
                      {Number(
                        orderDetail?.session?.amount_subtotal / 100 || 0
                      )?.toFixed(2)}
                    </p>
                  </div>
                  {/* <div className='flex justify-between mt-2 mb-2'>
                    <p className='radio-text'>Tax:</p>
                    <p className='radio-text'>
                      £{' '}
                      {Number(
                        orderDetail?.session?.total_details?.amount_tax / 100 ||
                          0
                      ).toFixed(2)}
                    </p>
                  </div> */}
                  <div className="flex justify-between">
                    <p className="radio-text">Delivery Fee:</p>
                    <p className="radio-text">
                      £{" "}
                      {Number(
                        orderDetail?.session?.shipping_cost?.amount_total /
                          100 || 0
                      )?.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between w-full mt-1">
                  <p className="cart-header">Subtotal</p>
                  <p className="cart-header">
                    £{" "}
                    {Number(
                      orderDetail?.session?.amount_total / 100 || 0
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          {/* <div className="cart-products">
            {orderDetail?.cart?.map((product, index) => (
              <div key={product.id}>
                <CartProduct product={product} />
              </div>
            ))}
          </div> */}
        </div>
        {/* <div className="p-2 mt-4 text-left address-information">
          <div className="d-flex justify-content-between align-items-center">
            <p className="title">Address Information:</p>
          </div>
          <p className="mt-1 address text-capitalize">
            {orderDetail?.shipping_details?.address_line_1 +
              ", " +
              orderDetail?.shipping_details?.address_line_2 +
              ", " +
              orderDetail?.shipping_details?.city +
              ", " +
              orderDetail?.shipping_details?.country +
              ", " +
              orderDetail?.shipping_details?.zip_code}
          </p>
        </div> */}
        {/* <div className="mt-4 shipping-information d-flex justify-content-between align-items-center">
          <p>Order Status:</p>
          <p className="text-capitalize">{orderDetail?.orderStatus}</p>
        </div> */}
        {/* <hr className="gradient-horizontal-line" />
        <div className="total-amount d-flex justify-content-between align-items-center">
          <p className="label">Total Amount:</p>
          <p>£{orderDetail?.session?.amount_subtotal / 100}</p>
        </div>
        <div className="shipping-information d-flex justify-content-between align-items-center">
          <p>Shipping:</p>
          <p>£{orderDetail?.session?.shipping_cost?.amount_total / 100}</p>
        </div>
        <hr className="gradient-horizontal-line" />
        <div
          className="payable-information d-flex justify-content-between align-items-center"
          style={{ marginBottom: "6rem" }}
        >
          <p className="ff-roboto">Payable</p>
          <p className="text-black ff-roboto">
            £{orderDetail?.session?.amount_total / 100}
          </p>
        </div>
        <div className="py-4 bg-white checkout-footer">
          <Link
            to={`/reorder/${orderId}`}
            className="text-black text-decoration-none"
          >
            <button
              className="bg-primary-filled-btn"
              style={{ width: "335px", height: "40px" }}
            >
              REORDER
            </button>
          </Link>
        </div> */}
      </div>
    </Spin>
  );
};

export default PreviousOrder;
