import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import NewArrivalsImage from "../assets/imgs/carousel-image-1.png";
import FilterIcon from "../assets/imgs/filter-icon.svg";
import CloseIcon from "../assets/imgs/close-icon.svg";
import DropdownArrowIcon from "../assets/imgs/dropdown-arrow-icon.svg";
import firebase from "firebase";
import SearchProducts from "../components/SearchProducts";
import { Drawer, InputLabel, MenuItem, Select } from "@mui/material";
import NewProductCard from "../components/NewProductCard";
import Footer from "../layout/Footer";
import {
  getAllDataFromCollection,
  getDataByOrder,
  getFilteredData,
} from "../utils";
import { Link, useLocation, useParams } from "react-router-dom";
import { Spin } from "antd";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";
import { useAuth } from "../contexts/AuthContext";
import { useUserStore } from "../app/store";

const NewProductsList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const { currentUser } = useAuth();
  const [newProducts, setNewProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const location = useLocation();
  const [initialData, setInitialData] = useState([]);
  const { brandName } = useParams();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  const [filterByOptions, setFilterByOptions] = useState({
    sortBy: "",
    sortOrder: "",
    filterByBrand: "",
    filterByCategory: "",
    filterBySize: "",
  });

  useEffect(() => {
    if (location.pathname.includes("new-products")) {
      // window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (brandName) {
      getBrandNewProducts();
    } else {
      getAllNewProducts();
    }
    fetchBrands();
    fetchCategories();
    fetchUserDetails();
  }, []);

  const [userDetails, setUserDetails] = useState({});
  const { updateUser } = useUserStore();

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        setUserDetails(userDetail);
        updateUser(userDetail);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchBrands = async () => {
    try {
      let data = await getAllDataFromCollection("brands");
      setBrands(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      let data = await getAllDataFromCollection("categories");
      setLoading(false);
      setCategories(data);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getAllNewProducts = async () => {
    try {
      setLoading(true);
      let data = await getFilteredData(
        "products",
        "is-new-product",
        "==",
        true
      );
      setLoading(false);
      if (data) {
        setNewProducts(data);
        setInitialData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBrandNewProducts = async () => {
    try {
      setLoading(true);
      let data = firebase.firestore().collection("brands").where();
      setLoading(false);
      if (data) {
        setNewProducts(data);
        setInitialData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchProducts = async (searchText = "") => {
    if (searchText) {
      const filteredProducts = initialData?.filter((product) =>
        product?.name
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()?.trim())
      );
      setNewProducts(filteredProducts);
    } else {
      setNewProducts([...initialData]);
    }
  };

  const likeProduct = (item, index) => {
    const cProductItems = [...newProducts];
    cProductItems[index] = item;
    setNewProducts([...cProductItems]);
  };

  const filterProducts = async () => {
    try {
      let tempProducts = [...initialData];
      if (tempProducts.length === 0) {
        setNewProducts(initialData);
      }
      setIsDrawerOpen(false);
      setLoading(true);
      if (filterByOptions?.sortBy) {
        if (filterByOptions?.sortBy === "name") {
          let data = await getDataByOrder("products", "name");
          tempProducts = [...data];
          tempProducts = tempProducts?.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setLoading(false);
        } else if (filterByOptions?.sortBy === "price_low_to_high") {
          tempProducts.sort(
            (a, b) =>
              Number(a.unstitchedDiscountPrice) -
              Number(b.unstitchedDiscountPrice)
          );
          // let data = await getDataByOrder('products', 'price')
          // tempProducts = [...data]
          // setLoading(false)
          // setNewProducts(data)
          // setFilteredProducts(data)
        } else if (filterByOptions?.sortBy === "price_high_to_low") {
          tempProducts.sort(
            (a, b) =>
              Number(a.unstitchedDiscountPrice) -
              Number(b.unstitchedDiscountPrice)
          );
          tempProducts = tempProducts?.reverse();
          // let data = await firebase
          //   .firestore()
          //   .collection('products')
          //   .orderBy('originalPrice')
          //   .limitToLast()
          //   .get()
          // let docs = data.docs.map((doc) => {
          //   return { ...doc.data(), id: doc.id }
          // })
          // let data = await getDataByOrder('products', 'price')
          // data = data?.reverse()
          // setLoading(false)
          // setNewProducts(docs)
          // setFilteredProducts(data)
        }
      }
      if (filterByOptions?.filterByCategory) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.category === filterByOptions.filterByCategory
        );
        setLoading(false);
        setNewProducts(data);
        tempProducts = [...data];
        setFilteredProducts(data);
      }
      if (filterByOptions?.filterByBrand) {
        setLoading(true);
        let data = tempProducts?.filter(
          (item) => item?.brand === filterByOptions.filterByBrand
        );
        tempProducts = [...data];
        setLoading(false);
        setNewProducts(data);
        setFilteredProducts(data);
      }
      if (filterByOptions?.filterBySize) {
        let data = [];
        if (
          !filterByOptions?.filterByCategory &&
          !filterByOptions?.filterByBrand
        ) {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        } else {
          data = tempProducts?.filter((item) =>
            item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase())
          );
        }
        // let data = tempProducts?.filter((item) =>
        //   item?.sizes?.includes(filterByOptions?.filterBySize?.toLowerCase()),
        // )
        if (data) {
          tempProducts = [...data];
        }
      }
      setLoading(false);
      // setNewProducts([...tempProducts])
      if (filterByOptions?.sortOrder === "descending") {
        setNewProducts([...tempProducts.reverse()]);
      } else setNewProducts([...tempProducts]);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="new-products-list position-relative footer-container container mt-[6rem]">
        {/* <SearchProducts searchProducts={searchProducts} /> */}
        {/* <img src={NewArrivalsImage} alt="" /> */}
        <div className="px-3 my-4 d-flex justify-content-between align-items-center">
          <h1 className="total-products-label">
            Showing {newProducts?.length} products
          </h1>
          <div
            className="gap-1 px-4 py-1 cursor-pointer filter d-flex"
            onClick={() => setIsDrawerOpen(true)}
          >
            <img src={FilterIcon} alt="" width={14} height={12} />
            <span>Filter</span>
          </div>
        </div>
        {loading == false && newProducts?.length === 0 && (
          <div className="mt-4 text-center">
            <img src={NoProductsFoundPlaceHolder} />
          </div>
        )}
        <div className="px-2 m-0 mb-5 row g-3">
          {newProducts &&
            newProducts?.map((product, index) => (
              <div className="col-6 col-lg-3" key={product?.id}>
                <Link
                  to={`/product-details/${product.id}`}
                  className="text-decoration-none h-100"
                >
                  <NewProductCard
                    product={product}
                    uid={currentUser?.uid || ""}
                    likeProduct={likeProduct}
                    index={index}
                    categories={categories}
                    userDetail={userDetails}
                    fetchProducts={
                      brandName ? getBrandNewProducts : getAllNewProducts
                    }
                    fetchUserDetails={fetchUserDetails}
                  />
                </Link>
              </div>
            ))}
        </div>
        <div className="mt-3"></div>
        <Drawer
          anchor={"bottom"}
          open={isDrawerOpen}
          className="mx-3 md:hidden"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "24px",
              borderTopRightRadius: "24px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="filter-drawer">
            <div className="mb-5 d-flex justify-content-between align-items-center">
              <h1 className="filters-label">Filters</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                className="cursor-pointer"
                width={32}
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="m-0 row">
              <div className="gap-4 p-0 col-12 d-flex justify-content-between">
                <div className="w-100">
                  <InputLabel id="select-standard-label">Sort by</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "black",
                      fontSize: "16px",
                    }}
                    inputProps={{ style: { border: "1px solid red" } }}
                    labelId="select-standard-label"
                    id="select-standard"
                    variant="standard"
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        sortBy: e.target.value,
                      }))
                    }
                    value={filterByOptions?.sortBy}
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"price_low_to_high"}>
                      Price Low to High
                    </MenuItem>
                    <MenuItem value={"price_high_to_low"}>
                      Price High to Low
                    </MenuItem>
                  </Select>
                </div>
                {filterByOptions?.sortBy !== "price_low_to_high" &&
                  filterByOptions?.sortBy !== "price_high_to_low" && (
                    <div className="w-100">
                      <InputLabel id="select-standard-label">
                        Sort Order
                      </InputLabel>
                      <Select
                        labelId="select-standard-label"
                        id="select-standard"
                        sx={{ width: "100%" }}
                        variant="standard"
                        IconComponent={() => (
                          <img src={DropdownArrowIcon} alt="" />
                        )}
                        onChange={(e) =>
                          setFilterByOptions((prev) => ({
                            ...prev,
                            sortOrder: e.target.value,
                          }))
                        }
                        value={filterByOptions?.sortOrder}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"ascending"}>Ascending</MenuItem>
                        <MenuItem value={"descending"}>Descending</MenuItem>
                      </Select>
                    </div>
                  )}
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Brand
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByBrand: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByBrand}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {brands?.map((brand) => (
                    <MenuItem
                      value={brand?.id}
                      key={brand?.id}
                      className="text-capitalize"
                    >
                      {brand?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByCategory: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories?.map((category) => (
                    <MenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="mt-5 mb-4 d-flex justify-center sm:justify-between align-items-center flex-wrap gap-2">
              <button
                className="bg-gray-filled-btn"
                style={{ width: "160px", height: "40px", fontSize: "15px" }}
                onClick={() => {
                  setIsDrawerOpen(false);
                  setFilterByOptions({});
                  if (brandName) {
                    getBrandNewProducts();
                  } else {
                    getAllNewProducts();
                  }
                }}
              >
                CLAER ALL
              </button>
              <button
                className="bg-primary-filled-btn"
                style={{ fontSize: "15px" }}
                onClick={filterProducts}
              >
                APPLY FILTERS
              </button>
            </div>
          </div>
        </Drawer>
        <Drawer
          anchor={"left"}
          open={isDrawerOpen}
          className="mx-3 hidden md:block"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "24px",
              borderTopRightRadius: "24px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="filter-drawer">
            <div className="mb-5 d-flex justify-content-between align-items-center">
              <h1 className="filters-label">Filters</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                className="cursor-pointer"
                width={32}
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="m-0 row">
              <div className="gap-4 p-0 col-12 d-flex justify-content-between">
                <div className="w-100">
                  <InputLabel id="select-standard-label">Sort by</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "black",
                      fontSize: "16px",
                    }}
                    inputProps={{ style: { border: "1px solid red" } }}
                    labelId="select-standard-label"
                    id="select-standard"
                    variant="standard"
                    onChange={(e) =>
                      setFilterByOptions((prev) => ({
                        ...prev,
                        sortBy: e.target.value,
                      }))
                    }
                    value={filterByOptions?.sortBy}
                    IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"price_low_to_high"}>
                      Price Low to High
                    </MenuItem>
                    <MenuItem value={"price_high_to_low"}>
                      Price High to Low
                    </MenuItem>
                  </Select>
                </div>
                {filterByOptions?.sortBy !== "price_low_to_high" &&
                  filterByOptions?.sortBy !== "price_high_to_low" && (
                    <div className="w-100">
                      <InputLabel id="select-standard-label">
                        Sort Order
                      </InputLabel>
                      <Select
                        labelId="select-standard-label"
                        id="select-standard"
                        sx={{ width: "100%" }}
                        variant="standard"
                        IconComponent={() => (
                          <img src={DropdownArrowIcon} alt="" />
                        )}
                        onChange={(e) =>
                          setFilterByOptions((prev) => ({
                            ...prev,
                            sortOrder: e.target.value,
                          }))
                        }
                        value={filterByOptions?.sortOrder}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"ascending"}>Ascending</MenuItem>
                        <MenuItem value={"descending"}>Descending</MenuItem>
                      </Select>
                    </div>
                  )}
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Brand
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByBrand: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByBrand}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {brands?.map((brand) => (
                    <MenuItem
                      value={brand?.id}
                      key={brand?.id}
                      className="text-capitalize"
                    >
                      {brand?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="p-0 mt-4 col-12">
                <InputLabel id="select-standard-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  sx={{ width: "100%" }}
                  variant="standard"
                  IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                  onChange={(e) =>
                    setFilterByOptions((prev) => ({
                      ...prev,
                      filterByCategory: e.target.value,
                    }))
                  }
                  value={filterByOptions?.filterByCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories?.map((category) => (
                    <MenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="mt-5 mb-4 d-flex justify-center sm:justify-between align-items-center flex-wrap gap-2">
              <button
                className="bg-gray-filled-btn"
                style={{ width: "160px", height: "40px", fontSize: "15px" }}
                onClick={() => {
                  setIsDrawerOpen(false);
                  setFilterByOptions({});
                  if (brandName) {
                    getBrandNewProducts();
                  } else {
                    getAllNewProducts();
                  }
                }}
              >
                CLAER ALL
              </button>
              <button
                className="bg-primary-filled-btn"
                style={{ fontSize: "15px" }}
                onClick={filterProducts}
              >
                APPLY FILTERS
              </button>
            </div>
          </div>
        </Drawer>
      </div>
    </Spin>
  );
};

export default NewProductsList;
