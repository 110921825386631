import React from "react";

const CategoryProductCard = ({ category }) => {
  return (
    <div
      className="popular-brand-card-categories"
      key={category?.id}
      style={{
        backgroundImage: `url(${category?.primary_image})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="py-[180px]">
        <div>
          <p className="py-1 brand-text" style={{ wordBreak: "break-all" }}>
            {category?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CategoryProductCard;
