import { useEffect, useReducer, useState, useRef } from "react";
import DeliveryAddress from "../components/DeliveryAddress";
import ShippingType from "../components/ShippingType";
import { Checkbox, Spin, message, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import firebase from "firebase";
import { getFilteredData } from "../utils";
import {
  useCartStore,
  useShippingPromoCodeStore,
  useUserStore,
} from "../app/store";

let shipping_details = JSON.parse(localStorage.getItem("shipping_details"));
let shipping_details_2 = JSON.parse(localStorage.getItem("shipping_details_2"));
const addressInitialState = {
  name: "",
  phone: "",
  email: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "UK",
  zip_code: "",
};

const address2InitialState = {
  name: "",
  phone: "",
  email: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "UK",
  zip_code: "",
};

const shippingTypeInitialState = {
  is_standard_tracked: false,
  is_next_day_delivery: false,
  is_usa_tracked: false,
  is_europe_tracked: false,
  is_canada_tracked: false,
  is_australia_tracked: false,
  amount: 0,
};

const shippingTypeReducer = (state, action) => {
  switch (action.type) {
    case "is_standard_tracked": {
      return {
        is_standard_tracked: true,
        is_next_day_delivery: false,
        is_usa_tracked: false,
        is_europe_tracked: false,
        is_canada_tracked: false,
        is_australia_tracked: false,
        amount: 5,
      };
    }
    case "is_next_day_delivery": {
      return {
        is_standard_tracked: false,
        is_next_day_delivery: true,
        is_usa_tracked: false,
        is_europe_tracked: false,
        is_canada_tracked: false,
        is_australia_tracked: false,
        amount: 12.5,
      };
    }
    case "is_usa_tracked": {
      return {
        is_standard_tracked: false,
        is_next_day_delivery: false,
        is_usa_tracked: true,
        is_europe_tracked: false,
        is_canada_tracked: false,
        is_australia_tracked: false,
        amount: 28.5,
      };
    }
    case "is_europe_tracked": {
      return {
        is_standard_tracked: false,
        is_next_day_delivery: false,
        is_usa_tracked: false,
        is_europe_tracked: true,
        is_canada_tracked: false,
        is_australia_tracked: false,
        amount: 16.5,
      };
    }
    case "is_canada_tracked": {
      return {
        is_standard_tracked: false,
        is_next_day_delivery: false,
        is_usa_tracked: false,
        is_europe_tracked: false,
        is_canada_tracked: true,
        is_australia_tracked: false,
        amount: 27.5,
      };
    }
    case "is_australia_tracked": {
      return {
        is_standard_tracked: false,
        is_next_day_delivery: false,
        is_usa_tracked: false,
        is_europe_tracked: false,
        is_canada_tracked: false,
        is_australia_tracked: true,
        amount: 32.5,
      };
    }
    default:
      return shippingTypeInitialState;
  }
};

const addressReducer = (state, action) => {
  switch (action.type) {
    case "name": {
      return { ...state, name: action.payload };
    }
    case "phone": {
      return { ...state, phone: action.payload };
    }
    case "email": {
      return { ...state, email: action.payload };
    }
    case "address_line_1": {
      return { ...state, address_line_1: action.payload };
    }
    case "address_line_2": {
      return { ...state, address_line_2: action.payload };
    }
    case "city": {
      return { ...state, city: action.payload };
    }
    case "state": {
      return { ...state, state: action.payload };
    }
    case "country": {
      return { ...state, country: action.payload };
    }
    case "zip_code": {
      return { ...state, zip_code: action.payload };
    }
    default:
      return addressInitialState;
  }
};

const address2Reducer = (state, action) => {
  switch (action.type) {
    case "name": {
      return { ...state, name: action.payload };
    }
    case "phone": {
      return { ...state, phone: action.payload };
    }
    case "email": {
      return { ...state, email: action.payload };
    }
    case "address_line_1": {
      return { ...state, address_line_1: action.payload };
    }
    case "address_line_2": {
      return { ...state, address_line_2: action.payload };
    }
    case "city": {
      return { ...state, city: action.payload };
    }
    case "state": {
      return { ...state, state: action.payload };
    }
    case "country": {
      return { ...state, country: action.payload };
    }
    case "zip_code": {
      return { ...state, zip_code: action.payload };
    }
    default:
      return addressInitialState;
  }
};

const DeliveryInformation = () => {
  const localCart = JSON.parse(localStorage.getItem("cart")) || [];
  const { storeCart } = useCartStore();
  const { shipping_promo_code, updatePromoCode } = useShippingPromoCodeStore();
  const { currentUser } = useAuth();
  let promoCodeRef = useRef(null);

  const [isAdditionalAddress, setIsAdditionalAddress] = useState(false);
  const { user } = useUserStore();
  const [userDetails, setUserDetails] = useState({});

  const [address, setAddress] = useState({
    name: "",
    phone: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "UK",
    zip_code: "",
  });
  const [address2, setAddress2] = useState({
    name: "",
    phone: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "UK",
    zip_code: "",
  });

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      if (currentUser?.uid) {
        // let data = await getFilteredData(
        //   "users",
        //   "email",
        //   "==",
        //   currentUser?.email
        // );
        let userDetail = { ...user };
        console.log("userDetail :: ", userDetail);
        setUserDetails(user);
        setAddress({
          ...userDetail.shipping_details,
          ...address,
          country: userDetail.shipping_details?.country
            ? userDetail.shipping_details?.country
            : "UK",
        });
        setAddress2({
          ...address2,
          ...userDetail.shipping_details_2,
        });
      } else {
        setAddress({
          ...address,
          ...shipping_details,
          country: shipping_details?.country ? shipping_details?.country : "UK",
        });
        setAddress2({ ...shipping_details_2, address2 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(userDetails?.cart, "cart");

  const [addressState, addressDispatch] = useReducer(
    addressReducer,
    addressInitialState
  );
  const [address2State, address2Dispatch] = useReducer(
    address2Reducer,
    address2InitialState
  );
  const [shippingTypeState, shippingTypeDispatch] = useReducer(
    shippingTypeReducer,
    shippingTypeInitialState
  );
  const [loading, setLoading] = useState(false);
  const [orderNotes, setOrderNote] = useState("");
  const navigate = useNavigate();

  const onCheckout = async (e) => {
    e.preventDefault();
    let obj = { ...address };
    delete obj.address_line_2;
    const isInputEmpty = hasEmptyValue(obj);
    if (isInputEmpty == true) {
      message.open({
        type: "error",
        content: "Please fill all required fields",
        className: "ff-roboto",
      });
      return;
    }

    setLoading(true);

    if (!addressState?.country) {
      message.open({
        type: "error",
        content: "Please select a country",
        className: "ff-roboto",
      });
      setLoading(false);
      return;
    }
    if (!shippingTypeState.amount) {
      message.open({
        type: "error",
        content: "Please select shipping type",
        className: "ff-roboto",
      });
      setLoading(false);
      return;
    }
    let data = await getFilteredData(
      "users",
      "email",
      "==",
      currentUser?.email
    );
    localStorage.setItem("orderNotes", orderNotes);
    localStorage.setItem("shipping_details", JSON.stringify(address));
    localStorage.setItem("shipping_details_2", JSON.stringify(address2));
    localStorage.setItem("shipping_cost", shippingTypeState?.amount);
    const shipping_option = {
      type: "fixed_amount",
      fixed_amount: { amount: shippingTypeState.amount * 100, currency: "GBP" },
    };
    if (userDetails?.id) {
      shipping_option.fixed_amount.amount =
        userDetails?.cart?.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.quantity;
        }, 0) <= 2
          ? shipping_option.fixed_amount.amount
          : userDetails?.cart?.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0) -
            2 +
            shipping_option.fixed_amount.amount;
    } else {
      shipping_option.fixed_amount.amount =
        storeCart?.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.quantity;
        }, 0) <= 2
          ? shipping_option.fixed_amount.amount
          : storeCart?.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0) -
            2 +
            shipping_option.fixed_amount.amount;
    }
    if (shippingTypeState.is_standard_tracked == true) {
      shipping_option.display_name = "Standard Tracked";
      shipping_option.delivery_estimate = {
        minimum: { unit: "day", value: 2 },
        maximum: { unit: "day", value: 3 },
      };
    }
    if (shippingTypeState.is_next_day_delivery) {
      shipping_option.display_name = "Guaranteed next day delivery";
      shipping_option.delivery_estimate = {
        minimum: { unit: "day", value: 1 },
        maximum: { unit: "day", value: 1 },
      };
    }
    if (shippingTypeState.is_europe_tracked) {
      shipping_option.display_name = "Europe Tracked";
    }
    if (shippingTypeState.is_usa_tracked) {
      shipping_option.display_name = "USA Tracked";
    }
    if (shippingTypeState.is_canada_tracked) {
      shipping_option.display_name = "Canada Tracked";
    }
    if (shippingTypeState.is_australia_tracked) {
      shipping_option.display_name = "Australia Tracked";
    }
    localStorage.setItem("shipping_option", JSON.stringify(shipping_option));
    if (promoCodeRef?.current?.value) {
      updatePromoCode(promoCodeRef?.current?.value);
    }
    if (data?.length) {
      const res = await firebase
        .firestore()
        .collection("users")
        .doc(data[0]?.id)
        .update({
          orderNotes: orderNotes,
          shipping_details: address,
          shipping_details_2: address2,
          shipping_cost: shippingTypeState?.amount,
          shipping_option,
        })
        .finally(() => setLoading(false));
      console.log(res);
    }
    navigate("/checkout");
  };

  function hasEmptyValue(obj) {
    return Object.values(obj).some((value) => !value);
  }

  return (
    <Spin spinning={loading}>
      <form onSubmit={onCheckout} id="delivery-info-form">
        <div className="px-4 mt-[8rem] container mb-[48px]">
          <p className="cart-header">Delivery Information</p>
          <div className="mt-[48px]">
            <DeliveryAddress
              addressState={addressState}
              addressDispatch={addressDispatch}
              address={address}
              setAddress={setAddress}
            />
            <div>
              <div className="flex flex-wrap gap-6 max-lg:flex-col-reverse">
                <div className="flex-1">
                  <p className="mb-2 shopping-type-title">Shipping Type:</p>
                  <div className="flex flex-col shipping-type-container">
                    {address.country == "UK" && (
                      <>
                        <ShippingType
                          text="Standard Tracked"
                          subText="Delivery in 2-3 working days (up to 2kg)"
                          price={
                            userDetails?.id
                              ? userDetails?.cart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) <= 2
                                ? 5
                                : userDetails?.cart?.reduce(
                                    (accumulator, currentItem) => {
                                      return accumulator + currentItem.quantity;
                                    },
                                    0
                                  ) -
                                  2 +
                                  5
                              : storeCart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) <= 2
                              ? 5
                              : storeCart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) -
                                2 +
                                5
                          }
                          isBorder
                          shippingTypeState={
                            shippingTypeState?.is_standard_tracked
                          }
                          shippingTypeDispatch={shippingTypeDispatch}
                          type="is_standard_tracked"
                        />
                        <ShippingType
                          text="Guaranteed next day delivery"
                          subText="Delivery in 1 day (up to 2kg)"
                          // price="12.50"
                          price={
                            userDetails?.id
                              ? userDetails?.cart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) <= 2
                                ? 12.5
                                : userDetails?.cart?.reduce(
                                    (accumulator, currentItem) => {
                                      return accumulator + currentItem.quantity;
                                    },
                                    0
                                  ) -
                                  2 +
                                  12.5
                              : storeCart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) <= 2
                              ? 12.5
                              : storeCart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) -
                                2 +
                                12.5
                          }
                          shippingTypeState={
                            shippingTypeState?.is_next_day_delivery
                          }
                          shippingTypeDispatch={shippingTypeDispatch}
                          type="is_next_day_delivery"
                        />
                      </>
                    )}
                    {address.country == "US" && (
                      <ShippingType
                        text="USA tracked
                      "
                        subText="Upto 2kg"
                        // price="28.50"
                        price={
                          userDetails?.id
                            ? userDetails?.cart?.reduce(
                                (accumulator, currentItem) => {
                                  return accumulator + currentItem.quantity;
                                },
                                0
                              ) <= 2
                              ? 28.5
                              : userDetails?.cart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) -
                                2 +
                                28.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) <= 2
                            ? 28.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) -
                              2 +
                              28.5
                        }
                        shippingTypeState={shippingTypeState?.is_usa_tracked}
                        shippingTypeDispatch={shippingTypeDispatch}
                        type="is_usa_tracked"
                      />
                    )}
                    {address.country == "CA" && (
                      <ShippingType
                        text="Canada tracked
                      "
                        subText="Upto 2kg"
                        // price="27.50"
                        price={
                          userDetails?.id
                            ? userDetails?.cart?.reduce(
                                (accumulator, currentItem) => {
                                  return accumulator + currentItem.quantity;
                                },
                                0
                              ) <= 2
                              ? 27.5
                              : userDetails?.cart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) -
                                2 +
                                27.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) <= 2
                            ? 27.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) -
                              2 +
                              27.5
                        }
                        shippingTypeState={shippingTypeState?.is_canada_tracked}
                        shippingTypeDispatch={shippingTypeDispatch}
                        type="is_canada_tracked"
                      />
                    )}
                    {address.country == "AU" && (
                      <ShippingType
                        text="Austrlia tracked
                      "
                        subText="Upto 2kg"
                        // price="32.50"
                        price={
                          userDetails?.id
                            ? userDetails?.cart?.reduce(
                                (accumulator, currentItem) => {
                                  return accumulator + currentItem.quantity;
                                },
                                0
                              ) <= 2
                              ? 32.5
                              : userDetails?.cart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) -
                                2 +
                                32.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) <= 2
                            ? 32.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) -
                              2 +
                              32.5
                        }
                        shippingTypeState={
                          shippingTypeState?.is_australia_tracked
                        }
                        shippingTypeDispatch={shippingTypeDispatch}
                        type="is_australia_tracked"
                      />
                    )}
                    {address.country == "EU" && (
                      <ShippingType
                        text="Europe tracked
                      "
                        subText="Upto 2kg"
                        // price="16.50"
                        // isBorder
                        price={
                          userDetails?.id
                            ? userDetails?.cart?.reduce(
                                (accumulator, currentItem) => {
                                  return accumulator + currentItem.quantity;
                                },
                                0
                              ) <= 2
                              ? 16.5
                              : userDetails?.cart?.reduce(
                                  (accumulator, currentItem) => {
                                    return accumulator + currentItem.quantity;
                                  },
                                  0
                                ) -
                                2 +
                                16.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) <= 2
                            ? 16.5
                            : storeCart?.reduce((accumulator, currentItem) => {
                                return accumulator + currentItem.quantity;
                              }, 0) -
                              2 +
                              16.5
                        }
                        shippingTypeState={shippingTypeState?.is_europe_tracked}
                        shippingTypeDispatch={shippingTypeDispatch}
                        type="is_europe_tracked"
                      />
                    )}
                  </div>
                  <p className="mt-2 radio-subtext">
                    This shipping will be applied upon checkout.
                  </p>
                </div>

                <div className="flex-1">
                  <div class="">
                    <label
                      class="block special-text-delivery tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
                      for="grid-first-name"
                    >
                      Special Notes for Delivery (Optional)
                    </label>
                    <textarea
                      rows="8"
                      class="appearance-none block w-full text-gray-700 border rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Write something here..."
                      value={orderNotes}
                      onChange={(e) => setOrderNote(e.target.value)}
                    />
                  </div>
                  <div className=" text-start">
                    <div className="additional-address-container mt-[24px] max-lg:inline hidden">
                      <Checkbox
                        onChange={() => {
                          setIsAdditionalAddress(!isAdditionalAddress);
                        }}
                      >
                        Deliver to a different address?
                      </Checkbox>
                      <div
                        className={`mt-[24px]  ${
                          isAdditionalAddress ? "block" : "hidden"
                        } `}
                      >
                        <DeliveryAddress
                          addressState={address2State}
                          addressDispatch={address2Dispatch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 mb-6 md:mb-0">
              <label class="block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2 text-start">
                Promo Code for Free Shipping
              </label>
              <input
                class="appearance-none block w-full text-gray-700 border rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="text"
                name="shipping-promotion-code"
                placeholder="Enter promo code"
                ref={promoCodeRef}
                // defaultValue={free}
                // onChange={(e) =>
                //   setAddress((prev) => ({ ...prev, state: e.target.value }))
                // }
                // value={address?.state}
              />
            </div>
            <div className="additional-address-container mt-[24px] max-lg:hidden">
              <Checkbox
                onChange={() => {
                  setIsAdditionalAddress(!isAdditionalAddress);
                }}
              >
                Deliver to a different address?
              </Checkbox>
              <div
                className={`mt-[24px]  ${
                  isAdditionalAddress ? "block" : "hidden"
                } `}
              >
                <DeliveryAddress
                  addressState={address2State}
                  addressDispatch={address2Dispatch}
                  address={address2}
                  setAddress={setAddress2}
                />
              </div>
            </div>

            <div className="flex justify-center mt-[48px]">
              <button
                type="submit"
                className="cursor-pointer cart-order-btn text-white"
                onClick={onCheckout}
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </form>
    </Spin>
  );
};

export default DeliveryInformation;
