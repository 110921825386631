import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { useBrandsStore } from "../../app/store";
import firebase from "firebase";

const PopularBrands = () => {
  const { brands, updateBrands } = useBrandsStore();
  useEffect(() => {
    getPopularBrands();
  }, []);

  const [loading, setLoading] = useState(null);
  const [popularBrands, setPopularBrands] = useState([]);

  const getPopularBrands = async () => {
    try {
      if (brands.length == 0) {
        firebase
          .firestore()
          .collection("brands")
          .onSnapshot((snapshot) => {
            let snapshotBrands = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            console.log("snapshot brands:    ", snapshotBrands);
            setPopularBrands(snapshotBrands);
            updateBrands(snapshotBrands);
          });
      } else {
        setPopularBrands(brands);
      }
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <Spin spinning={loading}>
      <div className="h-auto ml-[8px] mr-[8px]">
        <p className="pt-[32px] pb-[16px] main-heading">Brands</p>
        <div className="mt-3 row g-2">
          {popularBrands &&
            popularBrands?.map((brand, index) => {
              if (index < 4)
                return (
                  <div className="col-6 col-lg-3" key={brand.id}>
                    <Link
                      to={`/brand-products/${brand?.id}`}
                      className="text-decoration-none"
                    >
                      <div
                        className="popular-brand-card"
                        style={{
                          backgroundImage: `url(${brand?.primary_image})`,
                          backgroundRepeat: "no-repeat",
                          borderRadius: "24px",
                        }}
                      >
                        <div className="py-5 brand-title">
                          <div>
                            <p className="py-1 brand-text">{brand?.name}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
            })}
        </div>
      </div>
    </Spin>
  );
};

export default PopularBrands;
