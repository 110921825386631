import Categories from "./Categories";
import HomeCarousel from "./HomeCarousel";
import PopularBrands from "./PopularBrands";
import SaleProducts from "./SaleProducts";
import WhatIsNew from "./WhatIsNew";
import LogoWhite from "../../assets/imgs/side-bar-logo/logo@3x.png";

const Home = () => {
  return (
    <div className="position-relative footer-container">
      {/* <Header /> */}
      <div className="mt-[4rem] mb-2">
        <HomeCarousel />
      </div>

      <div className="container ">
        <PopularBrands />
        <WhatIsNew />
        <Categories />
        {/* <SpecialOffer />
     
      <SaleProducts /> */}
      </div>
    </div>
  );
};

export default Home;
