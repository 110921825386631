import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CartProduct from "../components/CartProduct";
import ProductImage from "../assets/imgs/product-details-image.png";
import PlusIcon from "../assets/imgs/plus-icon.svg";
import MinusIcon from "../assets/imgs/minus-icon.svg";
import CloseIcon from "../assets/imgs/close-icon.svg";
import { useAuth } from "../contexts/AuthContext";
import { getFilteredData, updateDoc } from "../utils";
import { Button, Spin, notification } from "antd";
import firebase from "firebase";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import axios from "redaxios";
import { toast } from "react-toastify";
import { useShippingPromoCodeStore } from "../app/store";

const Checkout = () => {
  const cartProducts = JSON.parse(localStorage.getItem("cart"));
  const orderDetails = JSON.parse(localStorage.getItem("shipping_details"));
  const shipping_option = JSON.parse(localStorage.getItem("shipping_option"));
  const locOrderDetails = localStorage.getItem("orderNotes");
  const { shipping_promo_code } = useShippingPromoCodeStore();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [cart, setCart] = useState([]);
  const [orderNotes, setOrderNotes] = useState("");
  const [shippingCharges, setShippingCharges] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isPlaceOrder, setIsPlaceOrder] = useState(false);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // const createStripeCheckout = firebase
  //   .functions()
  //   .httpsCallable("createStripeCheckout");

  const stripe = useStripe();
  const elements = useElements();

  const handleRemoveCartProduct = async (id) => {
    try {
      setIsDrawerOpen(false);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        icon: "warning",
      }).then(async (val) => {
        if (val.isConfirmed === true) {
          let prodInd = cart?.findIndex((item) => item?.id === id);
          let userCart = [];
          if (cart?.length != 0) {
            userCart = [...cart];
            userCart?.splice(prodInd, 1);
            setLoading(true);
            let response;
            if (currentUser?.uid) {
              response = await updateDoc("users", userDetails.id, {
                cart: userCart,
              });
            } else {
              localStorage.setItem("cart", JSON.stringify([...userCart]));
              response = true;
            }
            if (response) {
              Swal.fire({
                title: "Success!",
                text: "Cart product updated successfully.",
                icon: "success",
                timer: 2000,
              }).then(() => {
                fetchUserDetails();
              });
            }
          }
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        console.log("userDetail :: ", userDetail);
        setUserDetails(userDetail);
        setSelectedCountry(userDetail?.shipping_details?.country || "GB");
        setOrderNotes(userDetail.orderNotes || locOrderDetails);
        const totalAmount = userDetail?.cart?.reduce(
          (sum, { price, quantity }) => sum + Number(price) * quantity,
          0
        );
        let shipping_cost = localStorage.getItem("shipping_cost");
        console.log(shipping_cost);
        setShippingCharges(
          userDetail?.shipping_cost || localStorage.getItem("shipping_cost")
        );
        setPayableAmount(totalAmount);
        setCart(userDetail?.cart);
        console.log("Carttt :: ", userDetail?.cart);
      } else {
        // setUserDetails({ shipping_details: { ...orderDetails } });
        const cartProducts = JSON.parse(localStorage.getItem("cart"));
        setCart([...cartProducts]);
        console.log("Carttt :: ", cart);
        const totalAmount = cartProducts?.reduce(
          (sum, { price, quantity }) => sum + Number(price) * quantity,
          0
        );
        let shipping_cost = localStorage.getItem("shipping_cost");
        setShippingCharges(+shipping_cost);
        setPayableAmount(totalAmount);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckout = async () => {
    try {
      notification.info({
        message: "Taking you to checkout page, please wait...",
        style: { zIndex: 99999 },
      });
      setLoading(true);
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        setLoading(false);
        return;
      }
      if (!selectedCountry) {
        setSelectedCountry("UK");
      }
      let metadata = cart?.map((item) => item.id);
      console.log({ metadata });
      const lineItems = cart?.map((product) => {
        return {
          price_data: {
            currency: "GBP",
            product_data: {
              name: product.name.toUpperCase(),
              description: product.description,
              images: product.image,
            },
            unit_amount: Number(product?.prod_price) * 100,
          },
          quantity: Number(product?.quantity),
        };
      });

      if (isNaN(userDetails?.payable_amount) && userDetails) {
        userDetails.payable_amount = +cart
          ?.reduce(
            (sum, { prod_price, quantity }) =>
              sum + Number(prod_price) * quantity,
            0
          )
          ?.toFixed(2);
      }
      let shipping_amount = 0;
      // if(selectedCountry == 'UK') {
      //   if(lineItems?.length == 1) {
      //     shipping_amount =
      //   }
      // }
      let len = lineItems?.length;
      let common_shipping_details = {
        type: "fixed_amount",
        fixed_amount: {
          amount: 5 * 100,
          currency: "GBP",
        },
        display_name: "Standard delivery up to 2kg",
        delivery_estimate: {
          minimum: { unit: "day", value: 3 },
          maximum: { unit: "day", value: 5 },
        },
      };
      let fixed_amount = {
        amount: 5 * 100,
        currency: "GBP",
      };
      let uk_shipping_options = [
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 5 * 100 },
          },
        },
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 13 * 100 },
            display_name: "Guaranteed next day delivery",
            delivery_estimate: {
              minimum: { unit: "day", value: 1 },
              maximum: { unit: "day", value: 1 },
            },
          },
        },
      ];
      let us_shipping_options = [
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 25 * 100 },
            display_name: "USA Tracked (up to 1kg)",
          },
        },
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 29 * 100 },
            display_name: "USA Tracked (up to 2kg)",
          },
        },
      ];
      let canada_shipping_options = [
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 25 * 100 },
            display_name: "Canada Tracked (up to 1kg)",
          },
        },
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 27 * 100 },
            display_name: "Canada Tracked (up to 2kg)",
          },
        },
      ];
      let australia_shipping_options = [
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 25 * 100 },
            display_name: "Australia Tracked (up to 1kg)",
          },
        },
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 32 * 100 },
            display_name: "Australia Tracked (up to 2kg)",
          },
        },
      ];
      let europe_shipping_options = [
        {
          shipping_rate_data: {
            ...common_shipping_details,
            fixed_amount: { ...fixed_amount, amount: 25 * 100 },
            display_name: "Europe Tracked",
          },
        },
      ];
      let shipping_options = [];
      if (selectedCountry == "Delivery Information") {
        shipping_options = uk_shipping_options;
      } else if (selectedCountry == "US") {
        shipping_options = us_shipping_options;
      } else if (selectedCountry == "CA") {
        shipping_options = canada_shipping_options;
      } else if (selectedCountry == "AU") {
        shipping_options = australia_shipping_options;
      } else if (selectedCountry == "EU") {
        shipping_options = europe_shipping_options;
      }

      // Callable firebase cloud function
      // createStripeCheckout({
      const userShippingAddress = JSON.parse(
        localStorage.getItem("shipping_details")
      );
      const shipping_option = JSON.parse(
        localStorage.getItem("shipping_option")
      );
      if (shipping_promo_code == "FREE DELIVERY") {
        shipping_option.fixed_amount.amount = 0;
      }
      axios
        .post(
          "https://us-central1-mariza-9e743.cloudfunctions.net/createStripeCheckout",
          {
            lineItems: lineItems,
            shipping_option: { shipping_rate_data: shipping_option },
          }
        )
        .then(async (res) => {
          const session = res.data;
          if (userDetails && userDetails.id) {
            const orderNotesSaveRes = await firebase
              .firestore()
              .collection("users")
              .doc(userDetails.id)
              .update({ orderNotes });
            window.location.href = session.url;
          } else {
            window.location.href = session.url;
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
          console.error(err);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  console.log({ userDetails });

  return (
    <Spin spinning={loading}>
      <div className=" mt-[8rem] ml-[16px] mr-[15px] container mb-[48px]  pb-5 checkout">
        <p className="cart-header max-sm:hidden">
          {isPlaceOrder && "Payment Details"}
        </p>
        {isPlaceOrder ? (
          <div className="mt-[48px]">
            <div className="">
              <div className="payment-order-detail-container">
                <div className="w-full border-b border-[#AAA] border-solid border-1 text-start pb-2">
                  <h1 className="payment-order-detail-heading">
                    Order Details
                  </h1>
                </div>

                <div className="flex flex-col w-full text-start">
                  <p className="mb-1 radio-text flex justify-between items-center flex-wrap">
                    Products:{" "}
                    <span className=" text-[#AAA]">
                      {userDetails?.cart?.length || cartProducts?.length}
                    </span>
                  </p>
                  <p className="mb-1 radio-text flex justify-between items-center flex-wrap">
                    To:{" "}
                    <span className="text-[#AAA] text-end">
                      {userDetails
                        ? userDetails?.first_name + " " + userDetails?.last_name
                        : orderDetails?.name}
                      {/* {userDetails?.shipping_details?.name} */}
                    </span>
                  </p>
                  <p className="mb-1 radio-text flex justify-between items-center flex-wrap">
                    Phone:{" "}
                    <span className=" text-[#AAA]">
                      {userDetails?.shipping_details?.phone ||
                        orderDetails?.phone}
                    </span>
                  </p>
                  <p className="mb-1 radio-text flex justify-between items-center flex-wrap">
                    Email:{" "}
                    <span className=" text-[#AAA]">
                      {userDetails?.shipping_details?.email ||
                        orderDetails?.email}
                    </span>
                  </p>
                  <p className="mb-1 radio-text flex justify-between items-center flex-wrap">
                    Delivery Address:{" "}
                    <span className=" text-[#AAA]">
                      {userDetails?.shipping_details?.address_line_1 ||
                        orderDetails?.address_line_1}
                      ,
                      {userDetails?.shipping_details?.city ||
                        orderDetails?.city}
                      ,
                      {userDetails?.shipping_details?.state ||
                        orderDetails?.state}
                    </span>
                  </p>
                  <p className="mb-1 radio-text flex justify-between items-center flex-wrap">
                    Postal Code:{" "}
                    <span className=" text-[#AAA]">
                      {userDetails?.shipping_details?.zip_code ||
                        orderDetails?.zip_code}
                    </span>
                  </p>
                  <p className=" radio-text flex justify-between items-center flex-wrap">
                    Order Notes:{" "}
                    <span className=" text-[#AAA]">
                      {userDetails?.orderNotes ||
                        localStorage.getItem("orderNotes")}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col w-full border-b border-t border-[#AAA] border-solid border-1 pt-2 pb-2">
                  <div className="flex justify-between">
                    <p className="radio-text">Total Amount:</p>
                    <p className="radio-text">
                      £{Number(payableAmount)?.toFixed(2)}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="radio-text">Delivery Fee:</p>
                    <p className="radio-text">
                      £
                      {Number(shipping_option?.fixed_amount?.amount).toFixed(
                        2
                      ) / 100}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between w-full mt-1">
                  <p className="cart-header">Subtotal</p>
                  <p className="cart-header">
                    £
                    {Number(
                      Number(payableAmount || 0) +
                        Number(shipping_option?.fixed_amount?.amount) / 100
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="text-center">
                <Spin spinning={loading}>
                  <div
                    className="cursor-pointer cart-order-btn mt-[48px] checkout-order-btn mx-auto max-w-[398px] w-auto"
                    onClick={handleCheckout}
                  >
                    <button
                      className="text-white disabled:opacity-50"
                      disabled={loading}
                    >
                      Place Order
                    </button>
                  </div>
                </Spin>
              </div>
              {/* <div className="flex flex-col">
                <p className="hidden cart-header max-sm:inline">
                  Payment Details
                </p>
                <form class="w-full max-sm:mt-[20px]">
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full  px-3 mb-6 md:mb-0">
                      <label
                        class="input-label-text block tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
                        for="grid-first-name"
                      >
                        Card Name
                      </label>
                      <input
                        class="appearance-none block w-full text-gray-700 border rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none bg-white"
                        id="grid-first-name"
                        type="text"
                        placeholder="John"
                        onChange={(e) => setCardName(e.target.value)}
                        value={cardName}
                      />
                    </div>
                    <div class="w-full px-3 mb-6">
                      <label
                        class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-last-name"
                      >
                        Card Number
                      </label>
                      <input
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name"
                        type="text"
                        placeholder="XXXX XXXX XXXX XXXX"
                        onChange={(e) => setCardNumber(e.target.value)}
                        value={cardNumber}
                      />
                    </div>
                    <div class=" w-1/2 px-3 mb-6">
                      <label
                        class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-last-name"
                      >
                        Expiry
                      </label>
                      <input
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name"
                        type="text"
                        placeholder="MM/YY"
                        onChange={(e) => setExpiry(e.target.value)}
                        value={expiry}
                      />
                    </div>
                    <div class="w-1/2 px-3 mb-6">
                      <label
                        class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-last-name"
                      >
                        CVC
                      </label>
                      <input
                        class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name"
                        type="text"
                        placeholder="XXX"
                        onChange={(e) => setCvc(e.target.value)}
                        value={cvc}
                      />
                    </div>
                  </div>
                </form>
                <div className="flex justify-center ">
                  <div
                    className="cursor-pointer cart-order-btn"
                    onClick={handleCheckout}
                    disabled={loading}
                  >
                    <p>Place Order</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="mt-[48px]">
            <p className="cart-header ">Checkout</p>
            <div className="grid grid-cols-3 max-lg:grid-cols-1 mt-[48px] ">
              {cart?.map((product, index) => (
                <div
                  // onClick={() => setIsDrawerOpen(true)}
                  className="card-product-wrapper2"
                  key={product?.id}
                >
                  <CartProduct
                    product={product}
                    handleRemoveCartProduct={handleRemoveCartProduct}
                    isCheckout
                  />
                </div>
              ))}
            </div>

            <div className="flex flex-wrap">
              <div className="flex-1 max-lg:hidden"></div>
              <div className="flex flex-col max-lg:flex-1">
                <div className="checkout-amount-container max-lg:w-full">
                  <div className="flex justify-between">
                    <p className="radio-text">Total Amount:</p>
                    <p className="radio-text">
                      £{Number(payableAmount)?.toFixed(2)}
                    </p>
                  </div>
                  {/* <div className="flex justify-between mt-2 mb-2">
                    <p className="radio-text">Tax:</p>
                    <p className="radio-text">£ 0.00</p>
                  </div> */}
                  <div className="flex justify-between">
                    <p className="radio-text">Delivery Fee:</p>
                    <p className="radio-text">
                      £
                      {Number(shipping_option?.fixed_amount?.amount).toFixed(
                        2
                      ) / 100}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center cart-items-amount mt-[24px] ml-4 mr-4 ">
                  <p className="cart-header">Subtotal</p>
                  <p className="cart-header">
                    £
                    {Number(
                      Number(payableAmount || 0) +
                        Number(shipping_option?.fixed_amount?.amount) / 100
                    ).toFixed(2)}
                  </p>
                </div>
                <div
                  className="cursor-pointer cart-order-btn mt-[48px] checkout-order-btn"
                  onClick={() => setIsPlaceOrder(true)}
                >
                  <p>Proceed for Payment</p>
                </div>
              </div>
            </div>
            {/* <hr className="gradient-horizontal-line" /> */}
          </div>
        )}
        {/* <div className="py-4 bg-white checkout-footer">
          <button
            className="bg-primary-filled-btn"
            style={{ width: "335px", height: "40px" }}
            onClick={handleCheckout}
            disabled={loading}
          >
            Proceed to Payment
          </button>
        </div> */}
        <Drawer
          anchor={"bottom"}
          open={isDrawerOpen}
          className="mx-3"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "24px",
              borderTopRightRadius: "24px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="fs-24 ff-playfair fw-bold">Edit Product</h1>
            <img
              src={CloseIcon}
              alt=""
              height={32}
              width={32}
              className="cursor-pointer"
              onClick={() => setIsDrawerOpen(false)}
            />
          </div>
          <div className="gap-2 p-2 mt-4 product-card gray-border-12-5px d-flex align-items-center">
            <img
              src={ProductImage}
              alt=""
              height={50}
              width={50}
              className="br-12"
            />
            <div>
              <p className="fs-20 ff-roboto">Summer Silk Dress</p>
              <p className="ff-roboto fw-bold fs-20">£35.00</p>
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <div className="gap-1 product-size-labels d-flex product-details">
              <div>
                <span>S</span>
              </div>
              <div>
                <span>M</span>
              </div>
              <div>
                <span>L</span>
              </div>
            </div>
            <div className="gap-1 product-colors d-flex align-items-center">
              <div
                style={{
                  backgroundColor: "red",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "white",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "black",
                }}
              ></div>
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <div className="gap-2 d-flex align-items-center">
              <p className="fs-20 ff-roboto">Quantity: </p>
              <div
                className="gap-1 gray-border-12-5px fs-21 d-flex justify-content-between align-items-center br-50"
                style={{ borderRadius: "20px" }}
              >
                <div className="br-50 bg-gray add-quantity-icon">
                  <img src={PlusIcon} alt="" height={16} width={16} />
                </div>
                <span className="fs-20 ff-roboto">01</span>
                <div className="br-50 bg-gray remove-quantity-icon">
                  <img src={MinusIcon} alt="" height={16} width={16} />
                </div>
              </div>
            </div>
            <p className="fw-bold fs-35 ff-roboto color-primary">£35.00</p>
          </div>
          <div className="gap-3 mt-3 d-flex justify-content-between align-items-center">
            <button className="bg-gray-filled-btn">Remove</button>
            <button
              className="bg-primary-filled-btn"
              style={{ width: "200px", height: "40px" }}
            >
              Update
            </button>
          </div>
        </Drawer>
      </div>
    </Spin>
  );
};

export default Checkout;
