import { Button, InputAdornment, IconButton, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import LogoWhite from "../assets/imgs/side-bar-logo/logo@3x.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Swal from "sweetalert2";
import { Spin } from "antd";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useUserStore } from "../app/store";
import { getFilteredData } from "../utils";

const Login = () => {
  const navigate = useNavigate();
  const emailRef = useRef();
  const [isLoading, setIsLoading] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { login, currentUser } = useAuth();
  const [inputs, setInputs] = useState({ email: "", password: "" });
  const { updateUser } = useUserStore();
  const handleUserLogin = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const response = await login(inputs?.email, inputs?.password);
      if (response) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          response?.user?.email
        );
        let userDetail = data[0];
        updateUser(userDetail);
        localStorage.setItem("isUserLoggedIn", "true");
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error!",
        text: error?.message,
        icon: "error",
        // timer: 2000,
      });
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <Spin spinning={isLoading}>
      <div className="text-center login mt-[8rem] mb-[48px] w-full sm:w-1/3 mx-auto px-3">
        <h1 className="mt-5 mb-3 cart-header">Login</h1>
        <form className="mt-[36px]" onSubmit={handleUserLogin}>
          <div class="w-full mt-3">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email
            </label>
            <input
              class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-email"
              type="email"
              name="email"
              variant="outlined"
              placeholder="Email"
              value={inputs?.email}
              required
              onChange={handleChange}
            />
          </div>
          <div class="w-full mt-3 ">
            <label
              class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-passowrd"
            >
              Password
            </label>
            <div className="relative flex items-center justify-end">
              <input
                class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-2xl py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-passowrd"
                name="password"
                placeholder="Password"
                value={inputs?.password}
                onChange={handleChange}
                required
                type={showPassword ? "text" : "password"}
              />
              <div class="absolute mr-2 w-10">
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev) => !prev)}
                    // onMouseDown={() => setShowPassowrd((prev) => !prev)}
                  >
                    {showPassword === true ? (
                      <VisibilityOffIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    ) : (
                      <VisibilityIcon
                        sx={{ fill: "#333333", width: "24px", height: "24px" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-3">
            <Link to={"/reset-password"}>
              <p className="radio-text">Forgot Password?</p>
            </Link>
          </div>
          <div class="new-product-button flex-1 mr-2 bg-black text-white p-3 mt-6 cursor-pointer">
            <button type="submit" className="text-white w-full">
              Login
            </button>
          </div>
          <div className="flex justify-center mt-6">
            <p className="radio-text">Already have an account?</p>
          </div>
          <Link
            to="/register"
            class="new-product-button flex-1 mr-2  p-3 mt-2 text-black"
          >
            Create an Account
          </Link>
        </form>
      </div>
    </Spin>
  );
};

export default Login;
