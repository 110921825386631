import { InputLabel, MenuItem, Select, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import DropdownArrowIcon from "../assets/imgs/dropdown-arrow-icon.svg"
import { Country, State, City } from "country-state-city"
import { getFilteredData, updateDoc } from "../utils"
import Swal from "sweetalert2"
import { Spin } from "antd"
import { useAuth } from "../contexts/AuthContext"
import firebase from "firebase"

const ShippingAddress = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const location = useLocation()
  const { id } = useParams()
  const [userDetails, setUserDetails] = useState({})
  const [shippingAddress, setShippingAddress] = useState({
    address_line_1: "",
    address_line_2: "",
    // details: "",
    city: "",
    email: "",
    // state: "",
    country: "",
    // zipCode: "",
    // countyCode: "",
    // stateCode: "",
    // cityCode: "",
    zip_code: "",
    phoneNumber: "",
  })
  useEffect(() => {
    fetchUserDetails()
  }, [])

  const fetchUserDetails = async () => {
    try {
      setLoading(true)
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        )
        setLoading(false)
        let user = data[0]
        setUserDetails(user)
        setShippingAddress((prev) => ({
          ...prev,
          address_line_1: user?.shipping_details?.address_line_1,
          address_line_2: user?.shipping_details?.address_line_2,
          // details: user?.shipping_details?.details,
          email: user?.shipping_details?.email,
          city: user?.shipping_details?.city,
          country: user?.shipping_details?.country,
          // state: user?.shipping_details?.state,
          countyCode: user?.shipping_details?.countyCode,
          // stateCode: user?.shipping_details?.stateCode,
          zip_code: user?.shipping_details?.zip_code,
          phoneNumber: user?.shipping_details?.phone,
          first_name: user?.shipping_details?.first_name,
          last_name: user?.shipping_details?.last_name,
        }))
        setStates(State.getStatesOfCountry(user?.countyCode))
        setCities(City.getCitiesOfState(user?.countyCode, user?.stateCode))
      } else {
        const locShippingAddress = JSON.parse(
          localStorage.getItem("shippingAddress")
        )
        setStates(State.getStatesOfCountry(locShippingAddress.countyCode))
        setCities(
          City.getCitiesOfState(
            locShippingAddress.countyCode,
            locShippingAddress.stateCode
          )
        )
        setShippingAddress({ ...locShippingAddress })
        setUserDetails((prev) => ({
          ...prev,
          first_name: locShippingAddress.first_name,
          last_name: locShippingAddress.last_name,
        }))
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const [countries, setCountries] = useState(Country.getAllCountries())
  const [loading, setLoading] = useState(null)

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const handleChange = (e) => {
    if (e.target.name === "zip_code" && e.target.value?.length < 6) {
      setShippingAddress((prev) => ({
        ...prev,
        zip_code: e.target.value,
      }))
    } else
      setShippingAddress((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
  }

  const handleShippingAddressUpdate = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      if (currentUser?.uid) {
        // let user = await getFilteredData(
        //   "users",
        //   "email",
        //   "==",
        //   currentUser?.email
        // )
        // setUserDetails(user[0])
        const shipping_address = {
          address_line_1: shippingAddress?.address_line_1,
          address_line_2: shippingAddress?.address_line_2,
          email: shippingAddress?.email,
          city: shippingAddress?.city,
          country: shippingAddress?.country ?? "",
          zip_code: shippingAddress?.zip_code,
          phone: shippingAddress?.phoneNumber,
          first_name: shippingAddress?.first_name,
          last_name: shippingAddress?.last_name,
          countryCode: shippingAddress?.countyCode ?? "",
        }
        await firebase
          .firestore()
          .collection("users")
          .doc(userDetails?.id)
          .set({ shipping_details: shipping_address }, { merge: true })
      } else {
        let locShippingAddress = {
          first_name: userDetails?.first_name,
          last_name: userDetails?.last_name,
          address_line_1: shippingAddress?.address_line_1,
          address_line_2: shippingAddress?.address_line_2,
          // details: shippingAddress?.details,
          city: shippingAddress?.city,
          country: shippingAddress?.country,
          // state: shippingAddress?.state,
          countyCode: shippingAddress?.countyCode,
          email: shippingAddress?.email,
          zip_code: shippingAddress?.zip_code,
          phoneNumber: shippingAddress?.phoneNumber,
        }
        localStorage.setItem(
          "shippingAddress",
          JSON.stringify(locShippingAddress)
        )
      }
      Swal.fire({
        title: "Success",
        text: "Shipping Address has been updated.",
        icon: "success",
        timer: 2000,
      }).then(() => {
        if (id) {
          navigate("/profile-settings")
        } else navigate("/confirm-order")
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <div className={`${id ? "px-4" : "shipping-address px-4"}`}>
        <div className="position-fixed cart-header w-100 py-4 d-flex gap-4 align-items-center bg-white top-0">
          {id && (
            <Link to={"/"}>
              <i
                className="fa fa-chevron-left cursor-pointer float-start"
                style={{ fontSize: "1.2rem" }}
                // onClick={() => navigate("/")}
              ></i>
            </Link>
          )}
          {!id && (
            <Link to={"/checkout"}>
              <i
                className="fa fa-chevron-left cursor-pointer float-start"
                style={{ fontSize: "1.2rem" }}
                // onClick={() => navigate("/")}
              ></i>
            </Link>
          )}
          <h1>{id ? "Edit Shipping Address" : "Shipping Address"}</h1>
        </div>
        <form onSubmit={handleShippingAddressUpdate}>
          <div style={{ height: "calc(100vh - 100px)", overflow: "auto" }}>
            <div
              className="d-flex justify-content-between align-items-center gap-3"
              style={{ marginTop: "4rem" }}
            >
              {!id && (
                <div className="text-left mt-3">
                  <InputLabel id="label">First Name</InputLabel>
                  <TextField
                    fullWidth
                    name="first_name"
                    type={"text"}
                    variant="standard"
                    placeholder="Jane"
                    value={shippingAddress?.first_name}
                    onChange={(e) =>
                      setShippingAddress((prev) => ({
                        ...prev,
                        first_name: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
              )}
              {!id && (
                <div className="text-left mt-3">
                  <InputLabel id="label">Last Name</InputLabel>
                  <TextField
                    fullWidth
                    name="last_name"
                    type={"text"}
                    variant="standard"
                    placeholder="Doe"
                    value={shippingAddress?.last_name}
                    required
                    onChange={(e) =>
                      setShippingAddress((prev) => ({
                        ...prev,
                        last_name: e.target.value,
                      }))
                    }
                  />
                </div>
              )}
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Street address</InputLabel>
              <TextField
                fullWidth
                name="address_line_1"
                type={"text"}
                variant="standard"
                placeholder="House number and street number"
                value={shippingAddress?.address_line_1}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                name="address_line_2"
                type={"text"}
                className="mt-3"
                variant="standard"
                placeholder="Apartment, suite, unit, etc."
                value={shippingAddress?.address_line_2}
                onChange={handleChange}
              />
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Town/City</InputLabel>
              <TextField
                fullWidth
                name="city"
                type={"text"}
                variant="standard"
                placeholder=""
                required
                value={shippingAddress?.city}
                onChange={handleChange}
              />
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Country</InputLabel>
              <Select
                sx={{
                  width: "100%",
                  color: "black",
                  fontSize: "16px",
                }}
                inputProps={{ style: { border: "1px solid red" } }}
                value={shippingAddress?.countyCode || ""}
                labelId="select-standard-label"
                id="select-standard"
                variant="standard"
                IconComponent={() => <img src={DropdownArrowIcon} alt="" />}
                onChange={(e) => {
                  let countryCode = e.target.value
                  setShippingAddress((prev) => ({
                    ...prev,
                    country: countries.find((con) => con.isoCode == countryCode)
                      ?.name,
                    countyCode: countryCode,
                  }))
                  setStates(State.getStatesOfCountry(countryCode))
                }}
              >
                {countries?.map((country) => (
                  <MenuItem value={country?.isoCode} key={country?.isoCode}>
                    <p className="d-flex gap-1">
                      <span>{country?.flag}</span>
                      {country?.name}
                    </p>
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="text-left mt-3">
              <InputLabel id="label">Postcode</InputLabel>
              <TextField
                fullWidth
                name="zip_code"
                type={"text"}
                variant="standard"
                placeholder=""
                required
                value={shippingAddress?.zip_code}
                onChange={handleChange}
              />
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Email address</InputLabel>
              <TextField
                fullWidth
                name="email"
                type={"email"}
                variant="standard"
                placeholder=""
                required
                value={shippingAddress?.email}
                onChange={handleChange}
              />
            </div>
            <div className="text-left mt-3">
              <InputLabel id="label">Phone</InputLabel>
              <TextField
                fullWidth
                name="phoneNumber"
                type={"text"}
                variant="standard"
                placeholder="Enter phone number"
                value={shippingAddress?.phoneNumber}
                onChange={(e) =>
                  setShippingAddress((prev) => ({
                    ...prev,
                    phoneNumber: e.target.value,
                  }))
                }
                required
              />
            </div>
            {id && (
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Link to={"/profile-settings"}>
                  <button
                    className="bg-gray-filled-btn"
                    style={{ width: "120px", border: "1px solid #005288" }}
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  className="bg-primary-filled-btn"
                  style={{ width: "180px" }}
                  type={"submit"}
                >
                  Update
                </button>
              </div>
            )}
            {!id && (
              <div className="checkout-footer py-4 bg-white">
                {/* <Link
                to={'/confirm-order'}
                className="text-black text-decoration-none"
              > */}
                <button
                  className="bg-primary-filled-btn"
                  style={{ width: "335px", height: "40px" }}
                  htmltype="submit"
                >
                  Next (2/2)
                </button>
                {/* </Link> */}
              </div>
            )}
          </div>
        </form>
      </div>
    </Spin>
  )
}

export default ShippingAddress
