import { useEffect, useState } from "react";
import firebase from "firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoWhite from "../assets/imgs/side-bar-logo/logo@3x.png";
import { useAuth } from "../contexts/AuthContext";
import { getAllDataFromCollection, getFilteredData, updateDoc } from "../utils";
import { Button } from "@mui/material";
import {
  useCartStore,
  useShippingPromoCodeStore,
  useUserStore,
} from "../app/store";
import Stripe from "stripe";

const CheckoutSuccess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access the query parameters
  const session_id = queryParams.get("session_id");
  const { shipping_promo_code, updatePromoCode } = useShippingPromoCodeStore();
  const { currentUser } = useAuth();
  const { user, updateUser } = useUserStore();
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const navigate = useNavigate();
  const [isFunctionCalled, setIsFunctionCalled] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [session, setSession] = useState(null);
  const { updateCart, storeCart } = useCartStore();

  useEffect(() => {
    if (isFunctionCalled == false) {
      getSessionDetails();
    }
    if (storeCart) {
      updateCart([]);
    }
  }, []);

  console.log({ user });

  const sendOrderConfirmEmail = firebase
    .functions()
    .httpsCallable("sendOrderConfirmEmail");

  const fetchUserDetails = async (locSession) => {
    try {
      if (currentUser?.uid) {
        let wishlist = [...(user.wishlist || [])];
        let cartIds = user?.cart?.map((item) => item?.id);
        wishlist = wishlist?.filter((itemId) => !cartIds?.includes(itemId));
        let obj = {
          ...user,
          cart: [],
          payable_amount: 0,
          shipping_charges: 0,
          wishlist,
        };
        updateUser(obj);
        const userUpdateResponse = await updateDoc("users", user.id, {
          cart: [],
          payable_amount: 0,
          shipping_charges: 0,
          wishlist,
          orderNotes: "",
        });
        createOrder(locSession, user);
      } else {
        const userShippingAddress = JSON.parse(
          localStorage.getItem("shippingAddress")
        );
        const locCart = JSON.parse(localStorage.getItem("cart"));
        createOrder(locSession, { ...userShippingAddress, cart: locCart });
        updateCart([]);
        localStorage.setItem("cart", JSON.stringify([]));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionDetails = () => {
    const retrieveSessionDetail = firebase
      .functions()
      .httpsCallable("retrieveSessionDetail");
    if (shipping_promo_code) {
      updatePromoCode(null);
    }
    retrieveSessionDetail({ session_id: session_id })
      .then(async (res) => {
        console.log(res.data, "session", res);
        const userShippingAddress = JSON.parse(
          localStorage.getItem("shipping_details")
        );
        const shipping_cost = JSON.parse(localStorage.getItem("shipping_cost"));
        const cart = JSON.parse(localStorage.getItem("cart"));
        sendOrderConfirmEmail({
          cart,
          customer_details: {
            address: {
              city: userShippingAddress.city,
              country: userShippingAddress.country,
              line1: userShippingAddress.address_line_1,
              line2: userShippingAddress.address_line_2,
              state: userShippingAddress.state,
              postal_code: userShippingAddress.zip_code,
            },
            email: userShippingAddress.email,
            phone: userShippingAddress.phone,
          },
          shipping_cost: {
            amount_subtotal: shipping_cost,
            amount_total: shipping_cost,
          },
          session: res.data,
        });
        const locSession = res.data;
        fetchUserDetails(locSession);
        setSession(locSession);
      })
      .catch((err) => console.error(err));
  };

  const createOrder = async (locSession, user) => {
    try {
      let orderDetail = {
        ...user,
        products: locSession?.line_items?.data,
        session: locSession,
        orderStatus: "pending",
        orderDate: new Date().getTime(),
      };
      // if (!currentUser) {
      orderDetail["shipping_details"] = JSON.parse(
        localStorage.getItem("shipping_details")
      );
      orderDetail["shipping_details_2"] = JSON.parse(
        localStorage.getItem("shipping_details_2")
      );
      orderDetail["shipping_option"] = JSON.parse(
        localStorage.getItem("shipping_option")
      );
      // }
      const orders = await getAllDataFromCollection("orders");
      let ordersFiltered = orders.filter(
        (item) => item.session.id == session_id
      );
      if (ordersFiltered?.length < 1) {
        firebase
          .firestore()
          .collection("orders")
          .add(orderDetail)
          .then((res) => {
            const orderId = res.id;
            setIsOrderPlaced(true);
          })
          .catch((err) => {
            console.error(err);
          });
        localStorage.setItem("cart", JSON.stringify([]));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="mx-4 gap-5"
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div>
        <img
          src={LogoWhite}
          alt="Mariza Logo"
          height={60}
          width={200}
          className="mt-[6rem] mx-auto"
        />
        <hr />
        <h1 className="mt-5">Order confirmed.</h1>
        <p className="ff-roboto mt-3">
          Thank you for placing your order with us. You will receive a
          confirmation email shortly.
        </p>
      </div>
      <Link to={"/"} className="mb-4 text-center">
        <Button
          fullWidth
          className="mt-3 w-[250px]"
          color="primary"
          variant="outlined"
          type="button"
          size="large"
          style={{
            backgroundColor: "#000",
            color: "#fff",
            border: "1px solid #000",
            width: "250px",
          }}
        >
          Continue
        </Button>
      </Link>
    </div>
  );
};

export default CheckoutSuccess;
