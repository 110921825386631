import React from "react";
import { Radio } from "antd";

const ShippingType = ({
  text,
  subText,
  price,
  isBorder,
  shippingTypeState,
  shippingTypeDispatch,
  type,
}) => {
  return (
    <div
      className={`flex justify-between w-full ${
        isBorder && "border-b border-[#AAA] border-solid border-1 pb-[16px]"
      }`}
    >
      <div className="sortOptions flex gap-1">
        <input
          checked={shippingTypeState}
          onChange={() => shippingTypeDispatch({ type })}
          type="radio"
          id={type}
          name="fav_language"
          value="HTML"
          width={24}
          height={24}
        />
         {" "}
        <label for={type}>
          {" "}
          <p className="radio-text text-start">{text}</p>
          <p className="radio-subtext">{subText}</p>
        </label>
      </div>

      <p className="radio-text ">£{Number(price)?.toFixed(2)}</p>
    </div>
  );
};

export default ShippingType;
