import React from "react";
import { useAuth } from "../contexts/AuthContext";
import firebase from "firebase";
import { Spin } from "antd";
import ProfileContainer from "../layout/ProfileContainer";
import Profile from "../layout/Profile";

const ProfileSetting = () => {
  const [authText, setAuthText] = React.useState("");
  const [loading, setLoading] = React.useState(null);
  const { logout, currentUser } = useAuth();

  const onChangeAuthText = (text) => {
    setAuthText(text);
  };

  const handleLogoutPopup = async () => {
    try {
      setLoading(true);
      firebase
        .auth()
        .signOut()
        .then((state) => {
          localStorage.setItem("isUserLoggedIn", "false");
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="mt-[8rem] mb-[48px] mx-auto w-full sm:w-1/2 md:w-1/3 px-3">
        {/* <ProfileContainer
          isLogin={currentUser?.email}
          authText={authText}
          handleClose={() => {}}
          handleLogout={handleLogoutPopup}
          onChangeAuthText={onChangeAuthText}
          isDesktop={true}
        /> */}
        <Profile
          // handleClose={handleClose}
          // handleLogout={handleLogout}
          // isLogin={isLogin}
          // onChangeAuthText={onChangeAuthText}
          isDesktop={true}
          // setOpen={setOpen}
        />
      </div>
    </Spin>
  );
};

export default ProfileSetting;
