import { useEffect, useState } from "react";
import NewProductCard from "../../components/NewProductCard";
import { Link } from "react-router-dom";
import {
  getAllDataFromCollection,
  getCurrentUser,
  getFilteredData,
} from "../../utils";
import { Spin } from "antd";
import { useProductsStore, useUserStore } from "../../app/store";

const SaleProducts = () => {
  const [saleProducts, setSaleProducts] = useState([]);
  const [loading, setLoading] = useState(null);
  const { products } = useProductsStore();
  useEffect(() => {
    getSaleProducts();
    fetchUserDetails();
  }, []);

  const [userDetails, setUserDetails] = useState({});
  const { updateUser } = useUserStore();

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (getCurrentUser()?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          getCurrentUser()?.email
        );
        let userDetail = data[0];
        setUserDetails(userDetail);
        updateUser(userDetail);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getSaleProducts = async () => {
    try {
      setLoading(true);
      let response = await getAllDataFromCollection("products");
      setLoading(false);
      console.log({ response });
      let filtereProducts = response?.filter(
        (product) => product?.is_discounted == true
      );
      setSaleProducts(filtereProducts);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="what-is-new pb-4">
        <h1 className="fw-bold pt-3" style={{ fontSize: "18px" }}>
          Sale Products
        </h1>
        <div className="row m-0 px-2 g-3">
          {saleProducts &&
            saleProducts?.map((product, index) => {
              if (index < 4)
                return (
                  <div className="col-6 col-lg-3" key={product?.id}>
                    <Link
                      to={`/product-details/${product?.id}`}
                      className="text-decoration-none"
                    >
                      <NewProductCard
                        product={product}
                        userDetail={userDetails}
                        fetchProducts={getSaleProducts}
                        fetchUserDetails={fetchUserDetails}
                      />
                    </Link>
                  </div>
                );
            })}
        </div>
        <Link to={"/discounted-products"}>
          <button className="primary-btn-transparent mt-4">
            VIEW ALL PRODUCTS
          </button>
        </Link>
      </div>
    </Spin>
  );
};

export default SaleProducts;
