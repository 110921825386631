import React from "react";
import CartProductImage from "../assets/imgs/product-details-image.png";
import EditCartIcon from "../assets/imgs/edit-cart-icon.svg";
import DeleteIcon from "../assets/imgs/delete-svg.svg";
import LeftArrow from "../assets/imgs/left-arrow.svg";
import RightArrow from "../assets/imgs/right-arrow.svg";

const CartProduct = ({
  page,
  product,
  handleRemoveCartProduct,
  plusProduct,
  minusProduct,
  isCheckout,
  isShowQuantity,
  isShowDelete,
}) => {
  
  return (
    <div className="flex" key={product?.id}>
      <img
        src={product?.image && product?.image[0]}
        alt="Cart Product Image"
        width={isCheckout ? 100 : 142}
        // height={60}
        style={{ objectFit: "cover", borderRadius: "24px" }}
      />
      <div className="flex-1 ml-[12px] flex flex-col">
        <div className="flex justify-between">
          <p className="cart-title">{product?.name || "-"}</p>
          {!isShowDelete && (
            <img
              src={DeleteIcon}
              alt=""
              height={20}
              width={20}
              className="cursor-pointer "
              onClick={() => handleRemoveCartProduct(product?.id)}
            />
          )}
        </div>
        <div className="flex flex-col mt-2 text-start">
          {product.stitched == "stitched" && (
            <p className="cart-text">
              Size:{" "}
              <span className="cart-span-text">{product?.size || "-"}</span>
            </p>
          )}
          <p className="mt-1 cart-text">
            Stitching:{" "}
            <span className="cart-span-text capitalize">
              {product?.stitched || "-"}
            </span>
          </p>
          <p className="mt-1 cart-text">
            Lining:{" "}
            <span className="cart-span-text">
              {product?.lining == "like_image"
                ? "Like Image"
                : product?.lining || "-"}
            </span>
          </p>
          <p className="mt-1 cart-text">
            Price:{" "}
            <span className="cart-span-text">£{product?.price || "-"}</span>
          </p>
          {isCheckout && (
            <p className="mt-1 cart-text">
              Quantity:{" "}
              <span className="cart-span-text">{product?.quantity || "-"}</span>
            </p>
          )}
        </div>
        {!isCheckout && (
          <div>
            <div className="flex mt-2 border-b border-t border-[#AAA] border-solid border-1 text-start pt-2 pb-2 justify-between items-center  gap-2">
              <p className="cart-text">Quantity:</p>
              <div className="cart-button">
                <img
                  alt="left arrow"
                  src={LeftArrow}
                  className="cursor-pointer"
                  onClick={(e) => minusProduct(product?.id)}
                />
                <p className="cart-text">{product?.quantity}</p>
                <img
                  alt="right arrow"
                  src={RightArrow}
                  className="cursor-pointer"
                  onClick={(e) => plusProduct(product?.id, product?.stock)}
                />
              </div>
            </div>

            <div className="flex items-center justify-between pt-2 pb-2 mt-2 text-start">
              <p className="cart-subtotal">Subtotal</p>
              <p className="cart-text">£{product.price * product.quantity}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CartProduct;
